import React from 'react';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
} from 'mdb-react-ui-kit';

import {Card, CardGroup } from 'react-bootstrap';

import { useContext } from "react"
import Modal from 'react-bootstrap/Modal'
import { AuthContext } from "./Auth/AuthContext"
import EikenLogo from "./EikenLogo"
import EikenTrademarkNote from "./EikenTrademarkNote"

import { Link } from "react-router-dom";

export default function DetailedMenu(props) {
  const { user } = useContext(AuthContext)

  const handleSelectMenu = async (e) => {
    e.preventDefault()
    const handler = props.onSelectMenu
    if (handler) {
      const selection = e.currentTarget.getAttribute('selection')
      handler(selection)
    }
  }

  return (
    <Modal
      {...props}
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <MDBContainer className="ms-3">
          <MDBRow className="text-center">
            <MDBCol>
              <EikenLogo grade={props.grade}/>
              <div className="h3">
              <span>AIで</span>
              {props.type == 'opinion' && '意見論述問題'}
              {props.type == 'email' && 'Eメール問題'}
              <span>を自動生成</span>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Modal.Header>
      <Modal.Body>
        <MDBContainer>
          <MDBRow className="mt-3 mb-4">
            <MDBCol>
            <CardGroup className="text-center">
              <Card>
                <Card.Body>
                  <Card.Title>
                  <div className="lh-sm">
                    <div>違う問題にもチャレンジ！</div>
                    <div>問題を自動生成</div>
                  </div>
                  </Card.Title>
                  <Card.Text>
                    {user == null && (
                        <small className="text-muted mt-2"><Link to='/login'>ログイン</Link>してからご利用になれます。</small>
                      )}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <MDBBtn size="lg" className='w-75 green-button' onClick={handleSelectMenu} disabled={user==null} selection="topic">Go</MDBBtn>
                </Card.Footer>
              </Card>
              <Card>
                <Card.Body>
                  <Card.Title>
                  カスタム問題を作る
                  </Card.Title>
                  <Card.Text>
                    {user == null && (
                        <small className="text-muted mt-2"><Link to='/login'>ログイン</Link>してからご利用になれます。</small>
                      )}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <MDBBtn size="lg" className='w-75 green-button' onClick={handleSelectMenu} disabled selection="custom">近日公開</MDBBtn>
                </Card.Footer>
              </Card>
              {user == null &&
              <Card>
                <Card.Body>
                  <Card.Title>
                  サンプル
                  </Card.Title>
                  <Card.Text>
                  サンプル問題で練習します
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <div>
                    <MDBBtn size="lg" className='w-75 green-button' onClick={handleSelectMenu} selection="sample">Go</MDBBtn>
                  </div>
                </Card.Footer>
              </Card>
              }
              </CardGroup>
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <EikenTrademarkNote/>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Modal.Body>
    </Modal>
  );
}
