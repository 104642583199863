import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBInputGroup,
  MDBCheckbox,
  MDBIcon
} from 'mdb-react-ui-kit';

import { useState } from "react"
import { signUp, signInWithGoogle } from "./auth"
import { Navigate, Link } from "react-router-dom"

import Alert from 'react-bootstrap/Alert';
import Stack from 'react-bootstrap/Stack';
import TermOfService from '../TermOfService';
import { checkPassword, PasswordChecker } from './PasswordChecker'
import GoogleSignInButton from './GoogleSignInButton';
import * as device from '../device';

export default function SignUp(props) {
  const inDialog = props?.inDialog
  const small = 12
  const middle = 8
  const size = inDialog ? small : middle

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const [showTerm, setShowTerm] = useState(false)
  const [isTermAgreed, setIsTermAgreed] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)

  const handleRegister = async (e) => {
    try {
      await signUp(email, password)
      setSuccess(true)
    } catch (err) {
      console.log('err.name', err.name)
      console.log('err.message', err.message)
      if (err.name == 'UsernameExistsException') {
        setError('ユーザ名がすでに存在しています。')
      }
      else if (err.name == 'InvalidPasswordException' && err.message.includes('Password did not conform with policy')) {
        setError('入力されたパスワードは条件を満たしていません。8文字以上で、少なくとも1つの数字と１つの英字を含めてください。')
      }
      else if (err.name == 'InvalidParameterException' && err.message.includes('Username')) {
        setError('入力されたメールアドレスは正しくありません。正しいメールアドレスを入力してください。')
      }
      else {
        setError(err.message)
      }
      setShowTerm(false)
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    setError("")
    setShowTerm(true)
  }

  const handleGoogleLogin = async (e) => {
    e.preventDefault()
    setError("")

    try {
      setLoggingIn(true)
      await signInWithGoogle()
      setLoggingIn(false)
    } catch (err) {
      setLoggingIn(false)
      setError(`${err.name}: ${err.message}`)
    }
  }

  function renderContent() {
    if (success) {
      return (
        <div>
          <div>ユーザ登録</div>
          <Navigate to={`/confirm-sign-up/${email}`} />
          <h4>ユーザ登録成功しました。</h4>
          <div>メールでアクセスコードをご確認いただき、<a href={`/confirm-sign-up/${email}`}><b>こちら</b></a>でアクセスコードを入力してください。</div>
        </div>
      )
    }
    else if (showTerm) {
      return (
        <MDBContainer>
          <MDBRow className="my-3">アカウント登録前に以下の利用規約を読んで、同意される場合はチェックを入れて下さい。</MDBRow>
          <MDBRow style={{ overflow: "auto", height: "15em" }}>
            <TermOfService />
          </MDBRow>
          <MDBRow className="mt-5">
            <MDBCol className="d-flex justify-content-end">
              <MDBCheckbox
                name='agree_term'
                value={isTermAgreed}
                id='agree_term'
                label='利用規約に同意します'
                onChange={(e) => setIsTermAgreed(e.target.checked)}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-3">
            <MDBCol className="d-flex align-items-center justify-content-end">
              <MDBBtn className="green-button outline me-3" onClick={(e) => { setShowTerm(false); setIsTermAgreed(false); }}>戻る</MDBBtn>
              <MDBBtn
                className="green-button"
                disabled={!isTermAgreed}
                onClick={handleRegister}
              >アカウントを登録</MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )
    }
    else {
      return (
        <MDBContainer className="mt-5">
          <MDBRow>
            <MDBCol className="col-md-10 offset-md-1 text-center mb-3">
              {error &&
                <Alert variant="danger">
                  <Alert.Heading>ユーザ登録が失敗しました</Alert.Heading>
                  <div>{error}</div>
                </Alert>
              }
              <h5>ユーザ登録</h5>
              <MDBInputGroup className="mt-3">
                <MDBInput size='lg' label='メールアドレス' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
              </MDBInputGroup>
              <MDBInputGroup className="mt-3">
                <MDBInput size='lg' label='パスワード' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
              </MDBInputGroup>
              <MDBInputGroup className="mt-3">
                <MDBInput size='lg' label='パスワード（確認）' type='password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              </MDBInputGroup>
              <div className="mt-3">
                <PasswordChecker password={password} confirmPassword={confirmPassword} />
              </div>
              <MDBBtn size="lg" className="mt-3 green-button" disabled={!checkPassword(password, confirmPassword)} onClick={handleSubmit}>アカウントを登録</MDBBtn>
              <section className="mt-3">
                <a className="text-muted" href={`/confirm-sign-up/${email}`}>アクセスコードをお持ちの方はこちら</a>
              </section>
              {!device.isIOS() && (
                <section className="text-center mt-5">
                  <h5 className="mb-3">ソーシャルアカウントで登録</h5>
                  <p className="text-xs text-muted mb-2">
                    サービスの利用にあたっては、<a href="/term-of-service" target="_blank">利用規約</a>と<a href="/privacy-policy" target="_blank">プライバシーポリシー</a>が適用されます。同意の上でログインしてください。
                  </p>
                  <GoogleSignInButton
                    onClick={handleGoogleLogin}
                    loggingIn={loggingIn}
                    label="Sign up with Google"
                  />
                </section>
              )}
              <section className="mt-5">
                <h5 className="mb-2">アカウントをお持ちですか？</h5>
                <MDBBtn className='mx-2 green-button outline' href="/login">
                  ログイン
                </MDBBtn>
              </section>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )
    }
  }

  return (
    <MDBContainer className="py-5">
      <MDBRow center>
        <MDBCol md={8}>
          <MDBContainer>
            <MDBRow className="text-center">
              <MDBCol>
                <a href="/">
                  <img src="/color-logo-nobackground.svg"
                    style={{ 'width': '75vw', 'maxWidth': '400px' }} alt="logo" />
                </a>
                <h5 className="mt-1 pb-1">
                  <div>合格への近道は練習にあり！</div>
                  <div>英語ライティング学習サービス</div>
                </h5>
              </MDBCol>
            </MDBRow>
            <MDBRow className="text-center m-3 striking strong subtitle">
              <div>ユーザ登録で</div>
              <div>英検<sup>®</sup>級にあった問題を</div>
              <div>AIが無限に出題</div>
            </MDBRow>
            <MDBRow className="m-3 justify-content-center">
              <MDBContainer style={{ width: "fit-content" }}>
                <div>
                  <MDBIcon icon="check-circle me-2" style={{ color: "#62c1af" }} size='lg' />
                  ご利用は完全無料です
                </div>
                <div>
                  <MDBIcon icon="check-circle me-2" style={{ color: "#62c1af" }} size='lg' />
                  ご登録はメールアドレスとパスワードを入力するだけ<br />
                </div>
              </MDBContainer>
            </MDBRow>
            <MDBRow>
              {renderContent()}
            </MDBRow>
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
