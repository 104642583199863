class Assignment {
  constructor(title, question, essay = null, feedback = null) {
    this.title = title
    this.question = question
    this.essay = essay
    this.feedback = feedback
  }
}

Assignment.fromJSON = function(json) {
  return new Assignment(json.title, Question.fromJSON(json.question), json.essay, json.feedback)
}

class Question {
  constructor(grade, type, question, keywords) {
    this.grade = grade
    this.type = type
    this.question = question
    this.keywords = keywords
  }

  toJSON() {
    return {
      "grade": this.grade,
      "type": this.type,
      "question_text": this.question,
      "keywords": this.keywords
    }
  }
}

Question.fromJSON = function(json) {
  switch (json.type) {
    case OpinionQuestion.type:
      return OpinionQuestion.fromJSON(json);
    case EmailQuestion.type:
      return EmailQuestion.fromJSON(json);
    default:
      return new Question(json.grade, json.type, json.question_text);
  }
};


class OpinionQuestion extends Question {
  static type = "opinion"
  constructor(grade, question, keywords = [], checklist_91, checklist_92, checklist_93) {
    super(grade, OpinionQuestion.type, question)
    this.keywords = keywords
    this.checklist_91 = checklist_91
    this.checklist_92 = checklist_92
    this.checklist_93 = checklist_93
  }
  get_max_score() {
    return 4
  }
  toJSON() {
    if (this.checklist_91 != null || this.checklist_92!= null || this.checklist_93!= null) {
      return {
        "grade": this.grade,
        "type": this.type,
        "question_text": this.question,
        "keywords": this.keywords,
        "checklist_91": this.checklist_91,
        "checklist_92": this.checklist_92,
        "checklist_93": this.checklist_93
      }
    }
    else{
      return super.toJSON();
    }
  }
}

OpinionQuestion.fromJSON = function(json) {
  return new OpinionQuestion(json.grade, json.question_text, json.keywords, json.checklist_91, json.checklist_92, json.checklist_93);
};

class EmailQuestion extends Question {
  static type = "email"
  constructor(
      grade,
      question, 
      wh_question0, 
      wh_question1,
      email,
      sender,
      greeting,
      closing,
      keywords,
      subtasks,
      yes_no_question,
      topic_phrase,
      topic_char_start_index,
      topic_char_end_index) {
    super(grade, EmailQuestion.type, question, keywords)
    this.wh_question0 = wh_question0
    this.wh_question1 = wh_question1
    this.email = email
    this.sender = sender
    this.greeting = greeting
    this.closing = closing
    this.subtasks = subtasks
    this.yes_no_question = yes_no_question
    this.topic_phrase = topic_phrase
    this.topic_char_start_index = topic_char_start_index
    this.topic_char_end_index = topic_char_end_index
  }
  get_max_score() {
    if (this.grade == 'G30'){
      return 3
    }
    else{
      return 4
    }
  }
  toJSON() {
    return Object.assign({}, super.toJSON(), {
      "wh-question_0": this.wh_question0,
      "wh-question_1": this.wh_question1,
      "e-mail": this.email,
      "sender": this.sender,
      "greeting": this.greeting,
      "closing": this.closing,
      "subtasks": this.subtasks,
      "yes-no question": this.yes_no_question,
      "topic_phrase": this.topic_phrase,
      "topic_char_start_index": this.topic_char_start_index,
      "topic_char_end_index": this.topic_char_end_index
    });
  }
}

EmailQuestion.fromJSON = function(json) {
  return new EmailQuestion(
    json.grade,
    json.question_text, 
    json['wh-question_0'], 
    json['wh-question_1'], 
    json['e-mail'], 
    json.sender, 
    json.greeting, 
    json.closing, 
    json.keywords, 
    json.subtasks,
    json['yes-no question'],
    json['topic_phrase'],
    json['topic_char_start_index'],
    json['topic_char_end_index']);
}

export {
  Question,
  Assignment,
  OpinionQuestion,
  EmailQuestion
}
