import React, { Component } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import eventBus from "./EventBus";
import {
  MDBIcon, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBRipple
}
from 'mdb-react-ui-kit';
import { SubtaskData } from "./SubTaskData";

function between(x, min, max) {
  return x >= min && x < max;
}
class ContentCoherenceFeedbackCard extends Component {
  render() {
    return (
      <MDBCard id={`feedbackcard-${this.props.tag}`} key={`feedbackcard-${this.props.tag}`} border='dark' className={`feedback text-dark ${this.props.selected ? "selected" : "unselected"} ${this.props.hovered ? "hovered" : ""} mb-3`} feedbacktag={this.props.tag} onClick={this.props.onClick} onMouseOver={this.props.onMouseOver} onMouseOut={this.props.onMouseOut}>
        <MDBRipple role='button' rippleColor='#6dd6c2'>
          <MDBCardBody>
            <Container className="feedback-card p-0 small">
              {this.props.feedback['texts'].map((text, i) => (
                <div key={`feedback-text-${this.props.tag}`} className='mb-3'>
                <MDBIcon fas icon="times-circle" className='me-2 text-danger'/>{text}</div>
              ))}
            </Container>
          </MDBCardBody>
        </MDBRipple>
      </MDBCard>
    )
  }
}

function getContentCoherenceFeedbacks(grade, type, feedback) {
  function pushTagFeedback(detailedFeedbacks, sentences, template) {
    // console.log('pushing', template, sentences)
    let show = false
    let validFeedbacks = template.feedbacks.filter(f => f[0])
    if (template.tagMustExist) {
      if (template.tag) {
        if (sentences) {
          let index = sentences.findIndex(sentence => sentence.tag == template.tag)
          if (index >= 0) {
            if (validFeedbacks.some(v => true)) {
              show = true
            }
          }
        }
      }
    }
    else {
      if (validFeedbacks.some(v => true)) {
        show = true
      }
    }

    if (show) {
      detailedFeedbacks.push({
        tag: template.tag,
        texts: validFeedbacks.map(f => f[1])
      })
    }
  }

  let detailedFeedbacks = []
  if (feedback !== null){
    let sentences = feedback.grammar_vocabulary.sentences
    let tagFeedbackTemplates = [
      {tag: 'coherence.checklist_2', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_2, '文章が短すぎます。もっと内容を書きましょう。'],
      ]},
      {tag: 'coherence.checklist_20', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_20, '文章が少し短いです。もう少し内容を書きましょう。'],
      ]},
      {tag: 'coherence.checklist_22', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_22, 'ポイントについての内容はまとめて書きましょう。同じポイントについて前で書いたり後ろで書いたりしないようにしましょう。'],
      ]},
      {tag: 'content.offtopic_score', tagMustExist: false, feedbacks: [
        [type == 'opinion' && (feedback.content.offtopic_score == 0
          || feedback.content.offtopic_score == null), '質問に関係のない内容を書いているようです。質問に答えましょう。'],
      ]},
      {tag:'Intro', tagMustExist: true, feedbacks: [
        [feedback.content.checklist_15, '適切なリード文がありません。'],
        [feedback.content.opinion_label, 'もっとお題と関係のある内容を書きましょう。'],
      ]},
      {tag:'Opinion', tagMustExist: true, feedbacks: [
        [feedback.content.checklist_15, '適切なリード文がありません。'],
        [feedback.content.opinion_label, 'もっとお題と関係のある内容を書きましょう。'],
      ]},
      {tag:'R0', tagMustExist: true, feedbacks: [
        [feedback.content.p0_checklist_results?.checklist_4, 'こちらの理由をもう少し詳しく書きましょう。'],
        [feedback.content.p0_checklist_results?.checklist_9, 'こちらの理由はトピックとあまり関係ないようです。もう少し関係のある理由を書きましょう。'],
        [feedback.content.p0_checklist_results?.checklist_10, 'こちらの理由をもう少し詳しく書きましょう。'],
        // we have all the corrections in the main text already
        // [feedback.content.p0_checklist_results.checklist_11, '英単語を使用してください。'],
        // [feedback.content.p0_checklist_results.checklist_12, '理由が不十分な文です'],
        [feedback.content.p0_checklist_results?.task_completion_status == 1, '質問に対して適切に答えていません。'],
        // TODO: address in sentence comment
        // [feedback.content.p0_checklist_results && feedback.content.p0_checklist_results.incomplete_score == 1, '不完全な文です。'],
        // [feedback.content.p0_checklist_results && feedback.content.p0_checklist_results.sentence_score == 2, '文の意味に影響する文法の誤りがあります。'],
      ]},
      {tag:'EX0', tagMustExist: true, feedbacks: [[feedback.content.p0_checklist_results?.checklist_345, 'こちらの理由に対する説明をもう少し詳しく書きましょう。']]},
      {tag:'R1', tagMustExist: true, feedbacks: [
        [feedback.content.p1_checklist_results?.checklist_4, 'こちらの理由をもう少し詳しく書きましょう。'],
        [feedback.content.p1_checklist_results?.checklist_9, 'こちらの理由はトピックとあまり関係ないようです。もう少し関係のある理由を書きましょう。'],
        [feedback.content.p1_checklist_results?.checklist_10, 'こちらの理由をもう少し詳しく書きましょう。'],
        // we have all the corrections in the main text already
        // [feedback.content.p1_checklist_results?.checklist_11, '英単語を使用してください。'],
        // [feedback.content.p1_checklist_results?.checklist_12, '理由が不十分な文です'],
        [feedback.content.p1_checklist_results?.task_completion_status == 1, '質問に対して適切に答えていません。'],
        // TODO: address in sentence comment
        // [feedback.content.p1_checklist_results?.incomplete_score == 1, '不完全な文です'],
        // [feedback.content.p1_checklist_results?.sentence_score == 2, '文の意味に影響する文法の誤りがあります'],
      ]},
      {tag:'EX1', tagMustExist: true, feedbacks: [[feedback.content.p1_checklist_results?.checklist_345, 'こちらの理由に対する説明をもう少し詳しく書きましょう。']]},
      {tag:'Conclusion', tagMustExist: true, feedbacks: [
        [feedback.content.checklist_16, '適切な結論がありません。'],
        [feedback.content.checklist_17, 'リード文と結論の関係性が明確ではありません。'],
      ]},
    ]
    if (type == 'email'){
       const subtask_templates_list = SubtaskData[grade][type]['feedback_templates']['content']
       subtask_templates_list.forEach(templates_subtask => {
         const label_name = templates_subtask[0] + '_subtask_label'
         tagFeedbackTemplates.push(
           {tag: templates_subtask[0], tagMustExist: true, feedbacks: [
             [feedback.content[label_name] >= 1, templates_subtask[1]],
             [between(feedback.content[label_name], 0.5, 1), templates_subtask[2]]
           ]}
         )
       })
     }
     tagFeedbackTemplates.forEach(template => {
      pushTagFeedback(detailedFeedbacks, sentences, template)
    })

    // console.log(detailedFeedbacks)
    return detailedFeedbacks;
  }
}

class ContentCoherenceFeedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTag: null,
      hoveredTag: null
    };
  }

  cardOnClick = (e) => {
    e.preventDefault();
    let feedbackTag = e.currentTarget.getAttribute('feedbacktag')
    eventBus.dispatch("selectCard", { type: 'tag', id: feedbackTag });
    this.setState(prevState => ({
      ...prevState,
      selectedTag: feedbackTag,
    }))
  }

  cardOnMouseOver = (e) => {
    e.preventDefault();
    let tag = e.currentTarget.getAttribute('feedbackTag')
    eventBus.dispatch("hoverCard", { type: 'tag', id: tag });
  }

  cardOnMouseOut = (e) => {
    e.preventDefault();
    let tag = e.currentTarget.getAttribute('feedbackTag')
    eventBus.dispatch("unhoverCard", { type: 'tag', id: tag });
  }

  scrollCardIntoView(id) {
    // console.log('scrollCardIntoView', id)
    const element = document.getElementById(`feedbackcard-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }

  selectCard(card) {
    if (card.type == 'tag') {
      this.setState(prevState => ({
        ...prevState,
        selectedTag: card.id,
      }))
    }
  }

  componentDidMount() {
    if (eventBus.storage.selectedCard) {
      this.selectCard(eventBus.storage.selectedCard)
    }

    eventBus.on("selectEditor", (data) =>
      {
        this.selectCard(data)
      }
    );
    eventBus.on("hoverEditor", (data) =>
      {
        if (data.type == 'tag') {
          this.setState(prevState => ({
            ...prevState,
            hoveredTag: data.id,
          }))
          this.scrollCardIntoView(data.id)
        }
      }
    );
    eventBus.on("unhoverEditor", (data) =>
      {
        // console.log('unhover')
        this.setState(prevState => ({
          ...prevState,
          hoveredTag: null,
        }))
      }
    );
  }

  componentWillUnmount() {
    eventBus.remove("selectEditor");
    eventBus.remove("hoverEditor");
    eventBus.remove("unhoverEditor");
  }

  render() {
    if (this.props.feedback == null){
      return null;
    }
    let contentCoherenceFeedbacks = getContentCoherenceFeedbacks(this.props.grade, this.props.type, this.props.feedback);
    if (!contentCoherenceFeedbacks) return null;


    return contentCoherenceFeedbacks.map(feedback => {
      return (
        <ContentCoherenceFeedbackCard
          tag={feedback.tag}
          feedback ={feedback}
          selected = {feedback.tag == this.state.selectedTag}
          hovered = {feedback.tag == this.state.hoveredTag}
          onClick={this.cardOnClick}
          onMouseOver={this.cardOnMouseOver}
          onMouseOut={this.cardOnMouseOut}/>
      )
    });
  }
}

export { getContentCoherenceFeedbacks, ContentCoherenceFeedbackCard, ContentCoherenceFeedback };
