var Statement = {
  "G15": {
    "opinion": {
      "statement": [
        "Write an essay on the given TOPIC.",
        "Use TWO of the POINTS below to support your answer.",
        "Structure: introduction, main body, and conclusion",
        "Suggested length: 120-150 words"
      ],
      "subject_title": "TOPIC"
    },
  },
  "G20": {
    "opinion": {
      "statement": [
        "以下のTOPICについて、あなたの意見とその<u>理由を2つ</u>書きましょう。",
        "POINTSは理由を書く際の参考となる観点を示したものです。ただし，これら以外の観点から理由を書いてもかまいません。",
        "語数の目安は80語～100語です。",
        "解答がTOPICに示された問いの答えになっていない場合や、TOPICからずれていると判断された場合は、<u>★1レベルと採点されることがあります。</u>TOPICの内容をよく読んでから答えましょう。",
      ],
      "subject_title": "TOPIC",
    },
  },
  "G25": {
    "opinion": {
      "statement": [
        "あなたは外国人の友達から以下のQUESTIONをされました。",
        "QUESTIONについて、あなたの意見とその<u>理由を2つ</u>英文で書きましょう。",
        "語数の目安は50語～60語です。",
        "解答がQUESTIONに対応していないと判断された場合は、<u>★1レベルと採点されることがあります。</u>QUESTIONをよく読んでから答えましょう。",
      ],
      "subject_title": "QUESTION",
    },
    "email": {
      "statement": [
        "あなたは外国人の知り合いから、Eメールで質問を受け取りました。この質問にわかりやすく答える返信メールを、解答欄に英文で書きましょう",
        "あなたが書く返信メールの中で、知り合いからのEメール文中の下線部について、あなたがより理解を深めるために、<u>下線部の特徴を問う具体的な質問を2つしなさい。</u>",
        "あなたが書く返信メールの中で解答欄に書く英文の語数の目安は、40語～50語です。",
        "解答が知り合いからのEメールに対応していないと判断された場合は、<u>★1レベルと採点されることがあります。</u>知り合いからのEメールの内容をよく読んでから答えましょう。"
      ],
      "content_start": "Thank you for your e-mail.",
    },
  },
  "G30": {
    "opinion": {
      "statement": [
        "あなたは外国人の友達から以下のQUESTIONをされました。",
        "QUESTIONについて、あなたの考えとその<u>理由を2つ</u>英文で書きましょう。",
        "語数の目安は25語～35語です。",
        "解答がQUESTIONに対応していないと判断された場合は、<u>★1レベルと採点されることがあります。</u>QUESTIONをよく読んでから答えましょう。",
      ],
      "subject_title": "QUESTION",
    },
    "email": {
      "statement": [
        "あなたは外国人の友達から以下のEメールを受け取りました。Eメールを読み、それに対する返信メールを、解答欄に英文で書いて完成させましょう。",
        "あなたが書く返信メールの中で、友達からの2つの質問に対応する内容を、あなた自身で自由に考えて答えましょう。",
        "あなたが書く返信メールの中で解答欄に書く英文の語数の目安は、15語～25語です。",
        "解答が友達のEメールに対応していないと判断された場合は、<u>★1レベルと採点されることがあります。</u>友達のEメールの内容をよく読んでから答えましょう。"
      ],
      "content_start": "Thank you for your e-mail.",
    },
  }
}

var SampleAssignments = {
  "G15": {
    "opinion": {
      "title": "Weekend delivery: keep or end?",
      "question": {
        "grade": "G15",
        "type": "opinion",
        "question_text": "Should the mail delivery service on weekends be stopped?",
        "keywords": [
          "Convenience",
          "Economic impact",
          "The Internet",
          "Work-life balance"
        ]
      },
      "essay": 'I agree that mail delivery on weekends should be stopped because mail delivery on weekends makes staff bad condition. They should take a rest on weekends.\nFirst, the mails are heavy and work load is very hard. If they do not take a rest on weekends and they have excessive work by amount of mail, then they will be exhausted. Then the work and life is not balanced. So mail delivery on weekends should be abolished.\nSecond, amount of mail on weekends is a little than weekday.\nIn conclusion, I agree that mail delivery on weekends should be stopped. It is because amount of mail delivery of weekends is a little and it can reduce stress of the staff.',
      "feedback": {
        "coherence": {
          "all_error_num": 0,
          "all_error_ratio": 0.0,
          "bf_error_pairs": [],
          "checklist_1": 0,
          "checklist_18": 0,
          "checklist_19": 0,
          "checklist_2": 0,
          "checklist_20": 0,
          "checklist_21": 0,
          "checklist_22": 0,
          "checklist_23": 1,
          "checklist_24": 0,
          "checklist_25": 0,
          "checklist_26": 0,
          "checklist_27": 0,
          "checklist_28": 0,
          "correct_sent_num": 9,
          "minimal_essay_score": 0,
          "num_wo_conj_sent": 4,
          "para_score": 0,
          "ratio_simple_conj": 0.6,
          "ratio_wo_conj_sent": 0.4444444444444444,
          "sys_coherence": 3
        },
        "content": {
          "abnormal_response_score": null,
          "checklist_15": 0,
          "checklist_16": 0,
          "checklist_17": 0,
          "len_filter_score": -1.0,
          "non_english_score": -1,
          "offtopic_score": -1,
          "opinion_label": 0,
          "p0_checklist_results": {
            "checklist_10": 0,
            "checklist_11": 0,
            "checklist_12": 0,
            "checklist_345": 0,
            "checklist_4": 0,
            "checklist_9": 0,
            "checklist_all": 0,
            "explanation_len": 39,
            "relevance_score": 0.04153401404619217,
            "sent_feats": [
              1.0,
              0.0
            ]
          },
          "p1_checklist_results": {
            "checklist_10": 0,
            "checklist_11": 0,
            "checklist_12": 1,
            "checklist_345": 1,
            "checklist_4": 0,
            "checklist_9": 1,
            "checklist_all": 1,
            "explanation_len": 0,
            "relevance_score": 0,
            "sent_feats": [
              1.0,
              10
            ]
          },
          "reason_with_ex": 1,
          "reason_without_ex": 1,
          "sys_content": 3
        },
        "grammar_vocabulary": {
          "grammar_checklist_items": {
            "grammar_checklist_30": 0,
            "grammar_checklist_31": 0,
            "grammar_checklist_32": 0,
            "grammar_checklist_33": 0,
            "grammar_checklist_34": 1,
            "grammar_checklist_35": 0,
            "grammar_checklist_36": 0,
            "grammar_checklist_37": 0,
            "grammar_checklist_38": 0
          },
          "grammar_complexity_score": 1,
          "max_sent_len": 26,
          "mean_sent_len": 14.8,
          "n_orth_error": 0,
          "n_spell_error": 0,
          "numwds": 119,
          "sentences": [
            {
              "both_err": [
                "R:VERB"
              ],
              "cor_sent": "I agree that mail delivery on weekends should be stopped because mail delivery on weekends puts the staff in bad condition.",
              "coreference_sent": "I agree mail delivery on weekends should be stopped because mail delivery on weekends makes staff bad condition.",
              "err_ids": [
                15,
                20
              ],
              "error_types": [
                "Orig: [15, 16, 'makes'], Cor: [15, 16, 'puts'], Type: 'R:VERB'",
                "Orig: [16, 16, ''], Cor: [16, 17, 'the'], Type: 'M:DET'",
                "Orig: [17, 17, ''], Cor: [18, 19, 'in'], Type: 'M:PREP'",
                "Orig: [20, 21, ' '], Cor: [22, 22, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [
                "M:PREP"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 2,
              "org_sent": "I agree that mail delivery on weekends should be stopped because mail delivery on weekends makes staff bad condition.  ",
              "r_err": 0.10526315789473684,
              "sentence_score": 0,
              "tag": "Opinion",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "They should take a rest on weekends.",
              "coreference_sent": "Staff should take a rest on weekends.",
              "err_ids": [
                8
              ],
              "error_types": [
                "Orig: [8, 9, '\\n'], Cor: [8, 8, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "They should take a rest on weekends. \n",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "Opinion",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [
                "R:NOUN"
              ],
              "cor_sent": "First, the mails are heavy and the workload is very hard.",
              "coreference_sent": "First, the mails are heavy and work load is very hard.",
              "err_ids": [
                7,
                8,
                13
              ],
              "error_types": [
                "Orig: [7, 8, 'work'], Cor: [7, 8, 'the'], Type: 'R:OTHER'",
                "Orig: [8, 9, 'load'], Cor: [8, 9, 'workload'], Type: 'R:NOUN'",
                "Orig: [13, 14, ' '], Cor: [13, 13, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 1,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 1,
              "n_err": 2,
              "org_sent": "First, the mails are heavy and work load is very hard.  ",
              "r_err": 0.18181818181818182,
              "sentence_score": 1,
              "tag": "R0",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 1
            },
            {
              "both_err": [],
              "cor_sent": "If they do not take a rest on weekends and they have excessive work due to the amount of mail, then they will be exhausted.",
              "coreference_sent": "If staff do not take a rest on weekends and staff have excessive work by amount of mail, then staff will be exhausted.",
              "err_ids": [
                14,
                25
              ],
              "error_types": [
                "Orig: [14, 15, 'by'], Cor: [14, 16, 'due to'], Type: 'R:PREP'",
                "Orig: [15, 15, ''], Cor: [16, 17, 'the'], Type: 'M:DET'",
                "Orig: [25, 26, ' '], Cor: [27, 27, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [
                "R:PREP"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "If they do not take a rest on weekends and they have excessive work by amount of mail, then they will be exhausted.  ",
              "r_err": 0.043478260869565216,
              "sentence_score": 0,
              "tag": "EX0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "Then the work and life are not balanced.",
              "coreference_sent": "Then the work and life is not balanced.",
              "err_ids": [
                5,
                9
              ],
              "error_types": [
                "Orig: [5, 6, 'is'], Cor: [5, 6, 'are'], Type: 'R:VERB:SVA'",
                "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:VERB:SVA"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "Then the work and life is not balanced.  ",
              "r_err": 0.125,
              "sentence_score": 0,
              "tag": "EX0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "So mail delivery on weekends should be abolished.",
              "coreference_sent": "So mail delivery on weekends should be abolished.",
              "err_ids": [
                9
              ],
              "error_types": [
                "Orig: [9, 10, '\\n'], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "So mail delivery on weekends should be abolished. \n",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "EX0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "Second, the amount of mail on weekends is less than on weekdays.",
              "coreference_sent": "Second, amount of mail on weekends is a little than weekday.",
              "err_ids": [
                8,
                9,
                11,
                13
              ],
              "error_types": [
                "Orig: [2, 2, ''], Cor: [2, 3, 'the'], Type: 'M:DET'",
                "Orig: [8, 10, 'a little'], Cor: [9, 10, 'less'], Type: 'R:OTHER'",
                "Orig: [11, 11, ''], Cor: [11, 12, 'on'], Type: 'M:PREP'",
                "Orig: [11, 12, 'weekday'], Cor: [12, 13, 'weekdays'], Type: 'R:NOUN:NUM'",
                "Orig: [13, 14, '\\n'], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "M:PREP",
                "R:NOUN:NUM"
              ],
              "grammar_sentence_score": 1,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 1,
              "n_err": 3,
              "org_sent": "Second, amount of mail on weekends is a little than weekday. \n",
              "r_err": 0.2727272727272727,
              "sentence_score": 1,
              "tag": "R1",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 1
            },
            {
              "both_err": [],
              "cor_sent": "In conclusion, I agree that mail delivery on weekends should be stopped.",
              "coreference_sent": "In conclusion, I agree mail delivery on weekends should be stopped.",
              "err_ids": [
                14
              ],
              "error_types": [
                "Orig: [14, 15, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "In conclusion, I agree that mail delivery on weekends should be stopped.  ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "Conclusion",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "It is because the amount of mail delivery on weekends is less and it can reduce the stress of the staff.",
              "coreference_sent": "It is because amount of mail delivery of weekends is a little and amount of mail delivery of weekends can reduce stress of the staff.",
              "err_ids": [
                7,
                10,
                11
              ],
              "error_types": [
                "Orig: [3, 3, ''], Cor: [3, 4, 'the'], Type: 'M:DET'",
                "Orig: [7, 8, 'of'], Cor: [8, 9, 'on'], Type: 'R:PREP'",
                "Orig: [10, 12, 'a little'], Cor: [11, 12, 'less'], Type: 'R:OTHER'",
                "Orig: [16, 16, ''], Cor: [16, 17, 'the'], Type: 'M:DET'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [
                "R:PREP"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 2,
              "org_sent": "It is because amount of mail delivery of weekends is a little and it can reduce stress of the staff.",
              "r_err": 0.1,
              "sentence_score": 0,
              "tag": "Conclusion",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 0
            }
          ],
          "sys_grammar": 3,
          "sys_grammar_max_sentence_score": 1,
          "sys_grammar_mean_sentence_score": 0.4,
          "sys_vocabulary": 3,
          "sys_vocabulary_max_sentence_score": 1,
          "sys_vocabulary_mean_sentence_score": 0.4,
          "vocabulary_checklist_items": {
            "vocabulary_checklist_30": 0,
            "vocabulary_checklist_31": 0,
            "vocabulary_checklist_32": 0,
            "vocabulary_checklist_33": 0,
            "vocabulary_checklist_34": 1,
            "vocabulary_checklist_35": 0,
            "vocabulary_checklist_36": 0,
            "vocabulary_checklist_37": 0,
            "vocabulary_checklist_38": 0
          },
          "vocabulary_complexity_score": 0.3888888888888889
        },
        "input": {
          "conclusion": "In conclusion, I agree that mail delivery on weekends should be stopped. It is because amount of mail delivery of weekends is a little and it can reduce stress of the staff.",
          "conclusion_position": "yes",
          "input_text": "I agree that mail delivery on weekends should be stopped because mail delivery on weekends makes staff bad condition.  They should take a rest on weekends. \nFirst, the mails are heavy and work load is very hard.  If they do not take a rest on weekends and they have excessive work by amount of mail, then they will be exhausted.  Then the work and life is not balanced.  So mail delivery on weekends should be abolished. \nSecond, amount of mail on weekends is a little than weekday. \nIn conclusion, I agree that mail delivery on weekends should be stopped.  It is because amount of mail delivery of weekends is a little and it can reduce stress of the staff. ",
          "opinion": "I agree that mail delivery on weekends should be stopped because mail delivery on weekends makes staff bad condition. They should take a rest on weekends.",
          "opinion_position": "yes",
          "p0": {
            "EX": "If they do not take a rest on weekends and they have excessive work by amount of mail, then they will be exhausted. Then the work and life is not balanced. So mail delivery on weekends should be abolished.",
            "EX_cor_sent": "If they do not take a rest on weekends and they have excessive work due to the amount of mail, then they will be exhausted. Then the work and life are not balanced. So mail delivery on weekends should be abolished.",
            "EX_sent": [
              {
                "both_err": [],
                "cor_sent": "If they do not take a rest on weekends and they have excessive work due to the amount of mail, then they will be exhausted.",
                "cor_tokens": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 123,
                      "start": 0
                    }
                  ],
                  "text": "If they do not take a rest on weekends and they have excessive work due to the amount of mail, then they will be exhausted.",
                  "tokens": [
                    {
                      "dep": "mark",
                      "end": 2,
                      "head": 4,
                      "id": 0,
                      "pos": "SCONJ",
                      "start": 0,
                      "tag": "IN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 7,
                      "head": 4,
                      "id": 1,
                      "pos": "PRON",
                      "start": 3,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 10,
                      "head": 4,
                      "id": 2,
                      "pos": "AUX",
                      "start": 8,
                      "tag": "VBP"
                    },
                    {
                      "dep": "neg",
                      "end": 14,
                      "head": 4,
                      "id": 3,
                      "pos": "PART",
                      "start": 11,
                      "tag": "RB"
                    },
                    {
                      "dep": "advcl",
                      "end": 19,
                      "head": 25,
                      "id": 4,
                      "pos": "VERB",
                      "start": 15,
                      "tag": "VB"
                    },
                    {
                      "dep": "det",
                      "end": 21,
                      "head": 6,
                      "id": 5,
                      "pos": "DET",
                      "start": 20,
                      "tag": "DT"
                    },
                    {
                      "dep": "dobj",
                      "end": 26,
                      "head": 4,
                      "id": 6,
                      "pos": "NOUN",
                      "start": 22,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 29,
                      "head": 6,
                      "id": 7,
                      "pos": "ADP",
                      "start": 27,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 38,
                      "head": 7,
                      "id": 8,
                      "pos": "NOUN",
                      "start": 30,
                      "tag": "NNS"
                    },
                    {
                      "dep": "cc",
                      "end": 42,
                      "head": 4,
                      "id": 9,
                      "pos": "CCONJ",
                      "start": 39,
                      "tag": "CC"
                    },
                    {
                      "dep": "nsubj",
                      "end": 47,
                      "head": 11,
                      "id": 10,
                      "pos": "PRON",
                      "start": 43,
                      "tag": "PRP"
                    },
                    {
                      "dep": "conj",
                      "end": 52,
                      "head": 4,
                      "id": 11,
                      "pos": "AUX",
                      "start": 48,
                      "tag": "VBP"
                    },
                    {
                      "dep": "amod",
                      "end": 62,
                      "head": 13,
                      "id": 12,
                      "pos": "ADJ",
                      "start": 53,
                      "tag": "JJ"
                    },
                    {
                      "dep": "dobj",
                      "end": 67,
                      "head": 11,
                      "id": 13,
                      "pos": "NOUN",
                      "start": 63,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 71,
                      "head": 11,
                      "id": 14,
                      "pos": "ADP",
                      "start": 68,
                      "tag": "IN"
                    },
                    {
                      "dep": "pcomp",
                      "end": 74,
                      "head": 14,
                      "id": 15,
                      "pos": "ADP",
                      "start": 72,
                      "tag": "IN"
                    },
                    {
                      "dep": "det",
                      "end": 78,
                      "head": 17,
                      "id": 16,
                      "pos": "DET",
                      "start": 75,
                      "tag": "DT"
                    },
                    {
                      "dep": "pobj",
                      "end": 85,
                      "head": 14,
                      "id": 17,
                      "pos": "NOUN",
                      "start": 79,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 88,
                      "head": 17,
                      "id": 18,
                      "pos": "ADP",
                      "start": 86,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 93,
                      "head": 18,
                      "id": 19,
                      "pos": "NOUN",
                      "start": 89,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 94,
                      "head": 25,
                      "id": 20,
                      "pos": "PUNCT",
                      "start": 93,
                      "tag": ","
                    },
                    {
                      "dep": "advmod",
                      "end": 99,
                      "head": 25,
                      "id": 21,
                      "pos": "ADV",
                      "start": 95,
                      "tag": "RB"
                    },
                    {
                      "dep": "nsubjpass",
                      "end": 104,
                      "head": 25,
                      "id": 22,
                      "pos": "PRON",
                      "start": 100,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 109,
                      "head": 25,
                      "id": 23,
                      "pos": "VERB",
                      "start": 105,
                      "tag": "MD"
                    },
                    {
                      "dep": "auxpass",
                      "end": 112,
                      "head": 25,
                      "id": 24,
                      "pos": "AUX",
                      "start": 110,
                      "tag": "VB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 122,
                      "head": 25,
                      "id": 25,
                      "pos": "VERB",
                      "start": 113,
                      "tag": "VBN"
                    },
                    {
                      "dep": "punct",
                      "end": 123,
                      "head": 25,
                      "id": 26,
                      "pos": "PUNCT",
                      "start": 122,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "If staff do not take a rest on weekends and staff have excessive work by amount of mail, then staff will be exhausted.",
                "edits": [
                  "Orig: [14, 15, 'by'], Cor: [14, 16, 'due to'], Type: 'R:PREP'",
                  "Orig: [15, 15, ''], Cor: [16, 17, 'the'], Type: 'M:DET'",
                  "Orig: [25, 26, ' '], Cor: [27, 27, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  14,
                  25
                ],
                "error_span": 1,
                "error_types": [
                  "Orig: [14, 15, 'by'], Cor: [14, 16, 'due to'], Type: 'R:PREP'",
                  "Orig: [15, 15, ''], Cor: [16, 17, 'the'], Type: 'M:DET'",
                  "Orig: [25, 26, ' '], Cor: [27, 27, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 0,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 1,
                "grammar_err": [
                  "R:PREP"
                ],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 1,
                "org_sent": "If they do not take a rest on weekends and they have excessive work by amount of mail, then they will be exhausted.  ",
                "org_tokens": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 117,
                      "start": 0
                    }
                  ],
                  "text": "If they do not take a rest on weekends and they have excessive work by amount of mail, then they will be exhausted.  ",
                  "tokens": [
                    {
                      "dep": "mark",
                      "end": 2,
                      "head": 4,
                      "id": 0,
                      "pos": "SCONJ",
                      "start": 0,
                      "tag": "IN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 7,
                      "head": 4,
                      "id": 1,
                      "pos": "PRON",
                      "start": 3,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 10,
                      "head": 4,
                      "id": 2,
                      "pos": "AUX",
                      "start": 8,
                      "tag": "VBP"
                    },
                    {
                      "dep": "neg",
                      "end": 14,
                      "head": 4,
                      "id": 3,
                      "pos": "PART",
                      "start": 11,
                      "tag": "RB"
                    },
                    {
                      "dep": "advcl",
                      "end": 19,
                      "head": 23,
                      "id": 4,
                      "pos": "VERB",
                      "start": 15,
                      "tag": "VB"
                    },
                    {
                      "dep": "det",
                      "end": 21,
                      "head": 6,
                      "id": 5,
                      "pos": "DET",
                      "start": 20,
                      "tag": "DT"
                    },
                    {
                      "dep": "dobj",
                      "end": 26,
                      "head": 4,
                      "id": 6,
                      "pos": "NOUN",
                      "start": 22,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 29,
                      "head": 6,
                      "id": 7,
                      "pos": "ADP",
                      "start": 27,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 38,
                      "head": 7,
                      "id": 8,
                      "pos": "NOUN",
                      "start": 30,
                      "tag": "NNS"
                    },
                    {
                      "dep": "cc",
                      "end": 42,
                      "head": 4,
                      "id": 9,
                      "pos": "CCONJ",
                      "start": 39,
                      "tag": "CC"
                    },
                    {
                      "dep": "nsubj",
                      "end": 47,
                      "head": 11,
                      "id": 10,
                      "pos": "PRON",
                      "start": 43,
                      "tag": "PRP"
                    },
                    {
                      "dep": "conj",
                      "end": 52,
                      "head": 4,
                      "id": 11,
                      "pos": "AUX",
                      "start": 48,
                      "tag": "VBP"
                    },
                    {
                      "dep": "amod",
                      "end": 62,
                      "head": 13,
                      "id": 12,
                      "pos": "ADJ",
                      "start": 53,
                      "tag": "JJ"
                    },
                    {
                      "dep": "dobj",
                      "end": 67,
                      "head": 11,
                      "id": 13,
                      "pos": "NOUN",
                      "start": 63,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 70,
                      "head": 13,
                      "id": 14,
                      "pos": "ADP",
                      "start": 68,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 77,
                      "head": 14,
                      "id": 15,
                      "pos": "NOUN",
                      "start": 71,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 80,
                      "head": 15,
                      "id": 16,
                      "pos": "ADP",
                      "start": 78,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 85,
                      "head": 16,
                      "id": 17,
                      "pos": "NOUN",
                      "start": 81,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 86,
                      "head": 23,
                      "id": 18,
                      "pos": "PUNCT",
                      "start": 85,
                      "tag": ","
                    },
                    {
                      "dep": "advmod",
                      "end": 91,
                      "head": 23,
                      "id": 19,
                      "pos": "ADV",
                      "start": 87,
                      "tag": "RB"
                    },
                    {
                      "dep": "nsubjpass",
                      "end": 96,
                      "head": 23,
                      "id": 20,
                      "pos": "PRON",
                      "start": 92,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 101,
                      "head": 23,
                      "id": 21,
                      "pos": "VERB",
                      "start": 97,
                      "tag": "MD"
                    },
                    {
                      "dep": "auxpass",
                      "end": 104,
                      "head": 23,
                      "id": 22,
                      "pos": "AUX",
                      "start": 102,
                      "tag": "VB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 114,
                      "head": 23,
                      "id": 23,
                      "pos": "VERB",
                      "start": 105,
                      "tag": "VBN"
                    },
                    {
                      "dep": "punct",
                      "end": 115,
                      "head": 23,
                      "id": 24,
                      "pos": "PUNCT",
                      "start": 114,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 117,
                      "head": 24,
                      "id": 25,
                      "pos": "SPACE",
                      "start": 116,
                      "tag": "_SP"
                    }
                  ]
                },
                "r_err": 0.043478260869565216,
                "sent_for_SimilarityScorer": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 118,
                      "start": 0
                    }
                  ],
                  "text": "If staff do not take a rest on weekends and staff have excessive work by amount of mail, then staff will be exhausted.",
                  "tokens": [
                    {
                      "dep": "mark",
                      "end": 2,
                      "head": 4,
                      "id": 0,
                      "pos": "SCONJ",
                      "start": 0,
                      "tag": "IN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 8,
                      "head": 4,
                      "id": 1,
                      "pos": "NOUN",
                      "start": 3,
                      "tag": "NN"
                    },
                    {
                      "dep": "aux",
                      "end": 11,
                      "head": 4,
                      "id": 2,
                      "pos": "AUX",
                      "start": 9,
                      "tag": "VBP"
                    },
                    {
                      "dep": "neg",
                      "end": 15,
                      "head": 4,
                      "id": 3,
                      "pos": "PART",
                      "start": 12,
                      "tag": "RB"
                    },
                    {
                      "dep": "advcl",
                      "end": 20,
                      "head": 11,
                      "id": 4,
                      "pos": "VERB",
                      "start": 16,
                      "tag": "VB"
                    },
                    {
                      "dep": "det",
                      "end": 22,
                      "head": 6,
                      "id": 5,
                      "pos": "DET",
                      "start": 21,
                      "tag": "DT"
                    },
                    {
                      "dep": "dobj",
                      "end": 27,
                      "head": 4,
                      "id": 6,
                      "pos": "NOUN",
                      "start": 23,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 30,
                      "head": 6,
                      "id": 7,
                      "pos": "ADP",
                      "start": 28,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 39,
                      "head": 7,
                      "id": 8,
                      "pos": "NOUN",
                      "start": 31,
                      "tag": "NNS"
                    },
                    {
                      "dep": "cc",
                      "end": 43,
                      "head": 8,
                      "id": 9,
                      "pos": "CCONJ",
                      "start": 40,
                      "tag": "CC"
                    },
                    {
                      "dep": "conj",
                      "end": 49,
                      "head": 8,
                      "id": 10,
                      "pos": "NOUN",
                      "start": 44,
                      "tag": "NN"
                    },
                    {
                      "dep": "ccomp",
                      "end": 54,
                      "head": 23,
                      "id": 11,
                      "pos": "AUX",
                      "start": 50,
                      "tag": "VBP"
                    },
                    {
                      "dep": "amod",
                      "end": 64,
                      "head": 13,
                      "id": 12,
                      "pos": "ADJ",
                      "start": 55,
                      "tag": "JJ"
                    },
                    {
                      "dep": "dobj",
                      "end": 69,
                      "head": 11,
                      "id": 13,
                      "pos": "NOUN",
                      "start": 65,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 72,
                      "head": 13,
                      "id": 14,
                      "pos": "ADP",
                      "start": 70,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 79,
                      "head": 14,
                      "id": 15,
                      "pos": "NOUN",
                      "start": 73,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 82,
                      "head": 15,
                      "id": 16,
                      "pos": "ADP",
                      "start": 80,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 87,
                      "head": 16,
                      "id": 17,
                      "pos": "NOUN",
                      "start": 83,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 88,
                      "head": 23,
                      "id": 18,
                      "pos": "PUNCT",
                      "start": 87,
                      "tag": ","
                    },
                    {
                      "dep": "advmod",
                      "end": 93,
                      "head": 23,
                      "id": 19,
                      "pos": "ADV",
                      "start": 89,
                      "tag": "RB"
                    },
                    {
                      "dep": "nsubjpass",
                      "end": 99,
                      "head": 23,
                      "id": 20,
                      "pos": "NOUN",
                      "start": 94,
                      "tag": "NN"
                    },
                    {
                      "dep": "aux",
                      "end": 104,
                      "head": 23,
                      "id": 21,
                      "pos": "VERB",
                      "start": 100,
                      "tag": "MD"
                    },
                    {
                      "dep": "auxpass",
                      "end": 107,
                      "head": 23,
                      "id": 22,
                      "pos": "AUX",
                      "start": 105,
                      "tag": "VB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 117,
                      "head": 23,
                      "id": 23,
                      "pos": "VERB",
                      "start": 108,
                      "tag": "VBN"
                    },
                    {
                      "dep": "punct",
                      "end": 118,
                      "head": 23,
                      "id": 24,
                      "pos": "PUNCT",
                      "start": 117,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 0,
                "sentence_w_verr": 0,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "EX0",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              },
              {
                "both_err": [],
                "cor_sent": "Then the work and life are not balanced.",
                "cor_tokens": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 40,
                      "start": 0
                    }
                  ],
                  "text": "Then the work and life are not balanced.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 4,
                      "head": 7,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "det",
                      "end": 8,
                      "head": 2,
                      "id": 1,
                      "pos": "DET",
                      "start": 5,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 13,
                      "head": 5,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 9,
                      "tag": "NN"
                    },
                    {
                      "dep": "cc",
                      "end": 17,
                      "head": 2,
                      "id": 3,
                      "pos": "CCONJ",
                      "start": 14,
                      "tag": "CC"
                    },
                    {
                      "dep": "conj",
                      "end": 22,
                      "head": 2,
                      "id": 4,
                      "pos": "NOUN",
                      "start": 18,
                      "tag": "NN"
                    },
                    {
                      "dep": "auxpass",
                      "end": 26,
                      "head": 7,
                      "id": 5,
                      "pos": "AUX",
                      "start": 23,
                      "tag": "VBP"
                    },
                    {
                      "dep": "neg",
                      "end": 30,
                      "head": 7,
                      "id": 6,
                      "pos": "PART",
                      "start": 27,
                      "tag": "RB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 39,
                      "head": 7,
                      "id": 7,
                      "pos": "VERB",
                      "start": 31,
                      "tag": "VBN"
                    },
                    {
                      "dep": "punct",
                      "end": 40,
                      "head": 7,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 39,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "Then the work and life is not balanced.",
                "edits": [
                  "Orig: [5, 6, 'is'], Cor: [5, 6, 'are'], Type: 'R:VERB:SVA'",
                  "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  5,
                  9
                ],
                "error_span": 1,
                "error_types": [
                  "Orig: [5, 6, 'is'], Cor: [5, 6, 'are'], Type: 'R:VERB:SVA'",
                  "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 0,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [
                  "R:VERB:SVA"
                ],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 1,
                "org_sent": "Then the work and life is not balanced.  ",
                "org_tokens": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 41,
                      "start": 0
                    }
                  ],
                  "text": "Then the work and life is not balanced.  ",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 4,
                      "head": 7,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "det",
                      "end": 8,
                      "head": 2,
                      "id": 1,
                      "pos": "DET",
                      "start": 5,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 13,
                      "head": 5,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 9,
                      "tag": "NN"
                    },
                    {
                      "dep": "cc",
                      "end": 17,
                      "head": 2,
                      "id": 3,
                      "pos": "CCONJ",
                      "start": 14,
                      "tag": "CC"
                    },
                    {
                      "dep": "conj",
                      "end": 22,
                      "head": 2,
                      "id": 4,
                      "pos": "NOUN",
                      "start": 18,
                      "tag": "NN"
                    },
                    {
                      "dep": "auxpass",
                      "end": 25,
                      "head": 7,
                      "id": 5,
                      "pos": "AUX",
                      "start": 23,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "neg",
                      "end": 29,
                      "head": 7,
                      "id": 6,
                      "pos": "PART",
                      "start": 26,
                      "tag": "RB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 38,
                      "head": 7,
                      "id": 7,
                      "pos": "VERB",
                      "start": 30,
                      "tag": "VBN"
                    },
                    {
                      "dep": "punct",
                      "end": 39,
                      "head": 7,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 38,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 41,
                      "head": 8,
                      "id": 9,
                      "pos": "SPACE",
                      "start": 40,
                      "tag": "_SP"
                    }
                  ]
                },
                "r_err": 0.125,
                "sent_for_SimilarityScorer": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 39,
                      "start": 0
                    }
                  ],
                  "text": "Then the work and life is not balanced.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 4,
                      "head": 7,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "det",
                      "end": 8,
                      "head": 2,
                      "id": 1,
                      "pos": "DET",
                      "start": 5,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 13,
                      "head": 5,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 9,
                      "tag": "NN"
                    },
                    {
                      "dep": "cc",
                      "end": 17,
                      "head": 2,
                      "id": 3,
                      "pos": "CCONJ",
                      "start": 14,
                      "tag": "CC"
                    },
                    {
                      "dep": "conj",
                      "end": 22,
                      "head": 2,
                      "id": 4,
                      "pos": "NOUN",
                      "start": 18,
                      "tag": "NN"
                    },
                    {
                      "dep": "auxpass",
                      "end": 25,
                      "head": 7,
                      "id": 5,
                      "pos": "AUX",
                      "start": 23,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "neg",
                      "end": 29,
                      "head": 7,
                      "id": 6,
                      "pos": "PART",
                      "start": 26,
                      "tag": "RB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 38,
                      "head": 7,
                      "id": 7,
                      "pos": "VERB",
                      "start": 30,
                      "tag": "VBN"
                    },
                    {
                      "dep": "punct",
                      "end": 39,
                      "head": 7,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 38,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 0,
                "sentence_w_verr": 1,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "EX0",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              },
              {
                "both_err": [],
                "cor_sent": "So mail delivery on weekends should be abolished.",
                "cor_tokens": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 49,
                      "start": 0
                    }
                  ],
                  "text": "So mail delivery on weekends should be abolished.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 2,
                      "head": 7,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "compound",
                      "end": 7,
                      "head": 2,
                      "id": 1,
                      "pos": "NOUN",
                      "start": 3,
                      "tag": "NN"
                    },
                    {
                      "dep": "nsubjpass",
                      "end": 16,
                      "head": 7,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 8,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 19,
                      "head": 2,
                      "id": 3,
                      "pos": "ADP",
                      "start": 17,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 28,
                      "head": 3,
                      "id": 4,
                      "pos": "NOUN",
                      "start": 20,
                      "tag": "NNS"
                    },
                    {
                      "dep": "aux",
                      "end": 35,
                      "head": 7,
                      "id": 5,
                      "pos": "VERB",
                      "start": 29,
                      "tag": "MD"
                    },
                    {
                      "dep": "auxpass",
                      "end": 38,
                      "head": 7,
                      "id": 6,
                      "pos": "AUX",
                      "start": 36,
                      "tag": "VB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 48,
                      "head": 7,
                      "id": 7,
                      "pos": "VERB",
                      "start": 39,
                      "tag": "VBN"
                    },
                    {
                      "dep": "punct",
                      "end": 49,
                      "head": 7,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 48,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "So mail delivery on weekends should be abolished.",
                "edits": [
                  "Orig: [9, 10, '\\n'], Cor: [9, 9, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  9
                ],
                "error_span": 0,
                "error_types": [
                  "Orig: [9, 10, '\\n'], Cor: [9, 9, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 0,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 0,
                "org_sent": "So mail delivery on weekends should be abolished. \n",
                "org_tokens": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 51,
                      "start": 0
                    }
                  ],
                  "text": "So mail delivery on weekends should be abolished. \n",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 2,
                      "head": 7,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "compound",
                      "end": 7,
                      "head": 2,
                      "id": 1,
                      "pos": "NOUN",
                      "start": 3,
                      "tag": "NN"
                    },
                    {
                      "dep": "nsubjpass",
                      "end": 16,
                      "head": 7,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 8,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 19,
                      "head": 2,
                      "id": 3,
                      "pos": "ADP",
                      "start": 17,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 28,
                      "head": 3,
                      "id": 4,
                      "pos": "NOUN",
                      "start": 20,
                      "tag": "NNS"
                    },
                    {
                      "dep": "aux",
                      "end": 35,
                      "head": 7,
                      "id": 5,
                      "pos": "VERB",
                      "start": 29,
                      "tag": "MD"
                    },
                    {
                      "dep": "auxpass",
                      "end": 38,
                      "head": 7,
                      "id": 6,
                      "pos": "AUX",
                      "start": 36,
                      "tag": "VB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 48,
                      "head": 7,
                      "id": 7,
                      "pos": "VERB",
                      "start": 39,
                      "tag": "VBN"
                    },
                    {
                      "dep": "punct",
                      "end": 49,
                      "head": 7,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 48,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 51,
                      "head": 8,
                      "id": 9,
                      "pos": "SPACE",
                      "start": 50,
                      "tag": "_SP"
                    }
                  ]
                },
                "r_err": 0.0,
                "sent_for_SimilarityScorer": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 49,
                      "start": 0
                    }
                  ],
                  "text": "So mail delivery on weekends should be abolished.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 2,
                      "head": 7,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "compound",
                      "end": 7,
                      "head": 2,
                      "id": 1,
                      "pos": "NOUN",
                      "start": 3,
                      "tag": "NN"
                    },
                    {
                      "dep": "nsubjpass",
                      "end": 16,
                      "head": 7,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 8,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 19,
                      "head": 2,
                      "id": 3,
                      "pos": "ADP",
                      "start": 17,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 28,
                      "head": 3,
                      "id": 4,
                      "pos": "NOUN",
                      "start": 20,
                      "tag": "NNS"
                    },
                    {
                      "dep": "aux",
                      "end": 35,
                      "head": 7,
                      "id": 5,
                      "pos": "VERB",
                      "start": 29,
                      "tag": "MD"
                    },
                    {
                      "dep": "auxpass",
                      "end": 38,
                      "head": 7,
                      "id": 6,
                      "pos": "AUX",
                      "start": 36,
                      "tag": "VB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 48,
                      "head": 7,
                      "id": 7,
                      "pos": "VERB",
                      "start": 39,
                      "tag": "VBN"
                    },
                    {
                      "dep": "punct",
                      "end": 49,
                      "head": 7,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 48,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 0,
                "sentence_w_verr": 0,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "EX0",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              }
            ],
            "R": "First, the mails are heavy and work load is very hard.",
            "R_cor_sent": "First, the mails are heavy and the workload is very hard.",
            "R_sent": [
              {
                "both_err": [
                  "R:NOUN"
                ],
                "cor_sent": "First, the mails are heavy and the workload is very hard.",
                "cor_tokens": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 57,
                      "start": 0
                    }
                  ],
                  "text": "First, the mails are heavy and the workload is very hard.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 4,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "det",
                      "end": 10,
                      "head": 3,
                      "id": 2,
                      "pos": "DET",
                      "start": 7,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 16,
                      "head": 4,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 11,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ROOT",
                      "end": 20,
                      "head": 4,
                      "id": 4,
                      "pos": "AUX",
                      "start": 17,
                      "tag": "VBP"
                    },
                    {
                      "dep": "acomp",
                      "end": 26,
                      "head": 4,
                      "id": 5,
                      "pos": "ADJ",
                      "start": 21,
                      "tag": "JJ"
                    },
                    {
                      "dep": "cc",
                      "end": 30,
                      "head": 4,
                      "id": 6,
                      "pos": "CCONJ",
                      "start": 27,
                      "tag": "CC"
                    },
                    {
                      "dep": "det",
                      "end": 34,
                      "head": 8,
                      "id": 7,
                      "pos": "DET",
                      "start": 31,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 43,
                      "head": 9,
                      "id": 8,
                      "pos": "NOUN",
                      "start": 35,
                      "tag": "NN"
                    },
                    {
                      "dep": "conj",
                      "end": 46,
                      "head": 4,
                      "id": 9,
                      "pos": "AUX",
                      "start": 44,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "advmod",
                      "end": 51,
                      "head": 11,
                      "id": 10,
                      "pos": "ADV",
                      "start": 47,
                      "tag": "RB"
                    },
                    {
                      "dep": "acomp",
                      "end": 56,
                      "head": 9,
                      "id": 11,
                      "pos": "ADJ",
                      "start": 52,
                      "tag": "JJ"
                    },
                    {
                      "dep": "punct",
                      "end": 57,
                      "head": 9,
                      "id": 12,
                      "pos": "PUNCT",
                      "start": 56,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "First, the mails are heavy and work load is very hard.",
                "edits": [
                  "Orig: [7, 8, 'work'], Cor: [7, 8, 'the'], Type: 'R:OTHER'",
                  "Orig: [8, 9, 'load'], Cor: [8, 9, 'workload'], Type: 'R:NOUN'",
                  "Orig: [13, 14, ' '], Cor: [13, 13, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  7,
                  8,
                  13
                ],
                "error_span": 2,
                "error_types": [
                  "Orig: [7, 8, 'work'], Cor: [7, 8, 'the'], Type: 'R:OTHER'",
                  "Orig: [8, 9, 'load'], Cor: [8, 9, 'workload'], Type: 'R:NOUN'",
                  "Orig: [13, 14, ' '], Cor: [13, 13, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 1,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [],
                "grammar_sentence_score": 1,
                "is_grammar_error_critical": 1,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 1,
                "n_err": 2,
                "org_sent": "First, the mails are heavy and work load is very hard.  ",
                "org_tokens": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 56,
                      "start": 0
                    }
                  ],
                  "text": "First, the mails are heavy and work load is very hard.  ",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 4,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "det",
                      "end": 10,
                      "head": 3,
                      "id": 2,
                      "pos": "DET",
                      "start": 7,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 16,
                      "head": 4,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 11,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ccomp",
                      "end": 20,
                      "head": 9,
                      "id": 4,
                      "pos": "AUX",
                      "start": 17,
                      "tag": "VBP"
                    },
                    {
                      "dep": "amod",
                      "end": 26,
                      "head": 8,
                      "id": 5,
                      "pos": "ADJ",
                      "start": 21,
                      "tag": "JJ"
                    },
                    {
                      "dep": "cc",
                      "end": 30,
                      "head": 5,
                      "id": 6,
                      "pos": "CCONJ",
                      "start": 27,
                      "tag": "CC"
                    },
                    {
                      "dep": "conj",
                      "end": 35,
                      "head": 5,
                      "id": 7,
                      "pos": "NOUN",
                      "start": 31,
                      "tag": "NN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 40,
                      "head": 9,
                      "id": 8,
                      "pos": "NOUN",
                      "start": 36,
                      "tag": "NN"
                    },
                    {
                      "dep": "ROOT",
                      "end": 43,
                      "head": 9,
                      "id": 9,
                      "pos": "AUX",
                      "start": 41,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "advmod",
                      "end": 48,
                      "head": 11,
                      "id": 10,
                      "pos": "ADV",
                      "start": 44,
                      "tag": "RB"
                    },
                    {
                      "dep": "acomp",
                      "end": 53,
                      "head": 9,
                      "id": 11,
                      "pos": "ADJ",
                      "start": 49,
                      "tag": "JJ"
                    },
                    {
                      "dep": "punct",
                      "end": 54,
                      "head": 9,
                      "id": 12,
                      "pos": "PUNCT",
                      "start": 53,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 56,
                      "head": 12,
                      "id": 13,
                      "pos": "SPACE",
                      "start": 55,
                      "tag": "_SP"
                    }
                  ]
                },
                "r_err": 0.18181818181818182,
                "sent_for_SimilarityScorer": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 54,
                      "start": 0
                    }
                  ],
                  "text": "First, the mails are heavy and work load is very hard.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 4,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "det",
                      "end": 10,
                      "head": 3,
                      "id": 2,
                      "pos": "DET",
                      "start": 7,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 16,
                      "head": 4,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 11,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ccomp",
                      "end": 20,
                      "head": 9,
                      "id": 4,
                      "pos": "AUX",
                      "start": 17,
                      "tag": "VBP"
                    },
                    {
                      "dep": "amod",
                      "end": 26,
                      "head": 8,
                      "id": 5,
                      "pos": "ADJ",
                      "start": 21,
                      "tag": "JJ"
                    },
                    {
                      "dep": "cc",
                      "end": 30,
                      "head": 5,
                      "id": 6,
                      "pos": "CCONJ",
                      "start": 27,
                      "tag": "CC"
                    },
                    {
                      "dep": "conj",
                      "end": 35,
                      "head": 5,
                      "id": 7,
                      "pos": "NOUN",
                      "start": 31,
                      "tag": "NN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 40,
                      "head": 9,
                      "id": 8,
                      "pos": "NOUN",
                      "start": 36,
                      "tag": "NN"
                    },
                    {
                      "dep": "ROOT",
                      "end": 43,
                      "head": 9,
                      "id": 9,
                      "pos": "AUX",
                      "start": 41,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "advmod",
                      "end": 48,
                      "head": 11,
                      "id": 10,
                      "pos": "ADV",
                      "start": 44,
                      "tag": "RB"
                    },
                    {
                      "dep": "acomp",
                      "end": 53,
                      "head": 9,
                      "id": 11,
                      "pos": "ADJ",
                      "start": 49,
                      "tag": "JJ"
                    },
                    {
                      "dep": "punct",
                      "end": 54,
                      "head": 9,
                      "id": 12,
                      "pos": "PUNCT",
                      "start": 53,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 1,
                "sentence_w_verr": 0,
                "simple_sent_1": 0,
                "simple_sent_2": 1,
                "tag": "R0",
                "vocabulary_err": [
                  "R:OTHER"
                ],
                "vocabulary_sentence_score": 1
              }
            ],
            "co_EX": "If staff do not take a rest on weekends and staff have excessive work by amount of mail, then staff will be exhausted. Then the work and life is not balanced. So mail delivery on weekends should be abolished.",
            "co_R": "First, the mails are heavy and work load is very hard.",
            "no_conj_EX": "If they do not take a rest on weekends and they have excessive work by amount of mail then they will be exhausted Then the work and life is not balanced So mail delivery on weekends should be abolished.",
            "no_conj_R": "The mails are heavy and work load is very hard."
          },
          "p1": {
            "EX": "",
            "EX_cor_sent": "",
            "EX_sent": [],
            "R": "Second, amount of mail on weekends is a little than weekday.",
            "R_cor_sent": "Second, the amount of mail on weekends is less than on weekdays.",
            "R_sent": [
              {
                "both_err": [],
                "cor_sent": "Second, the amount of mail on weekends is less than on weekdays.",
                "cor_tokens": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    },
                    {
                      "end": 63,
                      "label": "DATE",
                      "start": 55
                    }
                  ],
                  "sents": [
                    {
                      "end": 64,
                      "start": 0
                    }
                  ],
                  "text": "Second, the amount of mail on weekends is less than on weekdays.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 8,
                      "id": 0,
                      "pos": "ADJ",
                      "start": 0,
                      "tag": "JJ"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 8,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "det",
                      "end": 11,
                      "head": 3,
                      "id": 2,
                      "pos": "DET",
                      "start": 8,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 18,
                      "head": 8,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 12,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 21,
                      "head": 3,
                      "id": 4,
                      "pos": "ADP",
                      "start": 19,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 26,
                      "head": 4,
                      "id": 5,
                      "pos": "NOUN",
                      "start": 22,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 29,
                      "head": 3,
                      "id": 6,
                      "pos": "ADP",
                      "start": 27,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 38,
                      "head": 6,
                      "id": 7,
                      "pos": "NOUN",
                      "start": 30,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ROOT",
                      "end": 41,
                      "head": 8,
                      "id": 8,
                      "pos": "AUX",
                      "start": 39,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "acomp",
                      "end": 46,
                      "head": 8,
                      "id": 9,
                      "pos": "ADJ",
                      "start": 42,
                      "tag": "JJR"
                    },
                    {
                      "dep": "prep",
                      "end": 51,
                      "head": 9,
                      "id": 10,
                      "pos": "SCONJ",
                      "start": 47,
                      "tag": "IN"
                    },
                    {
                      "dep": "prep",
                      "end": 54,
                      "head": 10,
                      "id": 11,
                      "pos": "ADP",
                      "start": 52,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 63,
                      "head": 11,
                      "id": 12,
                      "pos": "NOUN",
                      "start": 55,
                      "tag": "NNS"
                    },
                    {
                      "dep": "punct",
                      "end": 64,
                      "head": 8,
                      "id": 13,
                      "pos": "PUNCT",
                      "start": 63,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "Second, amount of mail on weekends is a little than weekday.",
                "edits": [
                  "Orig: [2, 2, ''], Cor: [2, 3, 'the'], Type: 'M:DET'",
                  "Orig: [8, 10, 'a little'], Cor: [9, 10, 'less'], Type: 'R:OTHER'",
                  "Orig: [11, 11, ''], Cor: [11, 12, 'on'], Type: 'M:PREP'",
                  "Orig: [11, 12, 'weekday'], Cor: [12, 13, 'weekdays'], Type: 'R:NOUN:NUM'",
                  "Orig: [13, 14, '\\n'], Cor: [14, 14, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  8,
                  9,
                  11,
                  13
                ],
                "error_span": 3,
                "error_types": [
                  "Orig: [2, 2, ''], Cor: [2, 3, 'the'], Type: 'M:DET'",
                  "Orig: [8, 10, 'a little'], Cor: [9, 10, 'less'], Type: 'R:OTHER'",
                  "Orig: [11, 11, ''], Cor: [11, 12, 'on'], Type: 'M:PREP'",
                  "Orig: [11, 12, 'weekday'], Cor: [12, 13, 'weekdays'], Type: 'R:NOUN:NUM'",
                  "Orig: [13, 14, '\\n'], Cor: [14, 14, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 1,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [
                  "M:PREP",
                  "R:NOUN:NUM"
                ],
                "grammar_sentence_score": 1,
                "is_grammar_error_critical": 1,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 1,
                "n_err": 3,
                "org_sent": "Second, amount of mail on weekends is a little than weekday. \n",
                "org_tokens": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    },
                    {
                      "end": 59,
                      "label": "DATE",
                      "start": 38
                    }
                  ],
                  "sents": [
                    {
                      "end": 62,
                      "start": 0
                    }
                  ],
                  "text": "Second, amount of mail on weekends is a little than weekday. \n",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 7,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 7,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 14,
                      "head": 7,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 8,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 17,
                      "head": 2,
                      "id": 3,
                      "pos": "ADP",
                      "start": 15,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 22,
                      "head": 3,
                      "id": 4,
                      "pos": "NOUN",
                      "start": 18,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 25,
                      "head": 2,
                      "id": 5,
                      "pos": "ADP",
                      "start": 23,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 34,
                      "head": 5,
                      "id": 6,
                      "pos": "NOUN",
                      "start": 26,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ROOT",
                      "end": 37,
                      "head": 7,
                      "id": 7,
                      "pos": "AUX",
                      "start": 35,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "det",
                      "end": 39,
                      "head": 9,
                      "id": 8,
                      "pos": "DET",
                      "start": 38,
                      "tag": "DT"
                    },
                    {
                      "dep": "attr",
                      "end": 46,
                      "head": 7,
                      "id": 9,
                      "pos": "ADJ",
                      "start": 40,
                      "tag": "JJ"
                    },
                    {
                      "dep": "prep",
                      "end": 51,
                      "head": 9,
                      "id": 10,
                      "pos": "SCONJ",
                      "start": 47,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 59,
                      "head": 10,
                      "id": 11,
                      "pos": "NOUN",
                      "start": 52,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 60,
                      "head": 7,
                      "id": 12,
                      "pos": "PUNCT",
                      "start": 59,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 62,
                      "head": 12,
                      "id": 13,
                      "pos": "SPACE",
                      "start": 61,
                      "tag": "_SP"
                    }
                  ]
                },
                "r_err": 0.2727272727272727,
                "sent_for_SimilarityScorer": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    },
                    {
                      "end": 59,
                      "label": "DATE",
                      "start": 38
                    }
                  ],
                  "sents": [
                    {
                      "end": 60,
                      "start": 0
                    }
                  ],
                  "text": "Second, amount of mail on weekends is a little than weekday.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 7,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 7,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 14,
                      "head": 7,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 8,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 17,
                      "head": 2,
                      "id": 3,
                      "pos": "ADP",
                      "start": 15,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 22,
                      "head": 3,
                      "id": 4,
                      "pos": "NOUN",
                      "start": 18,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 25,
                      "head": 2,
                      "id": 5,
                      "pos": "ADP",
                      "start": 23,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 34,
                      "head": 5,
                      "id": 6,
                      "pos": "NOUN",
                      "start": 26,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ROOT",
                      "end": 37,
                      "head": 7,
                      "id": 7,
                      "pos": "AUX",
                      "start": 35,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "det",
                      "end": 39,
                      "head": 9,
                      "id": 8,
                      "pos": "DET",
                      "start": 38,
                      "tag": "DT"
                    },
                    {
                      "dep": "attr",
                      "end": 46,
                      "head": 7,
                      "id": 9,
                      "pos": "ADJ",
                      "start": 40,
                      "tag": "JJ"
                    },
                    {
                      "dep": "prep",
                      "end": 51,
                      "head": 9,
                      "id": 10,
                      "pos": "SCONJ",
                      "start": 47,
                      "tag": "IN"
                    },
                    {
                      "dep": "pobj",
                      "end": 59,
                      "head": 10,
                      "id": 11,
                      "pos": "NOUN",
                      "start": 52,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 60,
                      "head": 7,
                      "id": 12,
                      "pos": "PUNCT",
                      "start": 59,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 1,
                "sentence_w_verr": 0,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "R1",
                "vocabulary_err": [
                  "R:OTHER"
                ],
                "vocabulary_sentence_score": 1
              }
            ],
            "co_EX": "",
            "co_R": "Second, amount of mail on weekends is a little than weekday.",
            "no_conj_EX": "",
            "no_conj_R": "Amount of mail on weekends is a little than weekday."
          },
          "p2": {},
          "question_data": {
            "keywords": [
              "Convenience",
              "Economic impact",
              "The Internet",
              "Work-life balance"
            ],
            "question_text": "Should the mail delivery service on weekends be stopped?"
          }
        }
      }
    },
  },
  "G20": {
    "opinion": {
      "title": "Volunteer: should schools encourage?",
      "question": {
        "grade": "G20",
        "type": "opinion",
        "question_text": "Today, many Japanese schools encourage their students to participate in volunteer activities. Do you think this is a good idea?",
        "keywords": [
          "Learning responsibility",
          "Personal development",
          "Personal freedom"
        ]
      },
      "essay": "I do not think that schools should encourage their students to do volunteer activities. First, students are busy for their study. they have to do not only homework but also do after-school activities, so they do not have time. Second, students should decide whether they want volunteer or not. For these reasons, I think that volunteer activities should not be forced students.",
      "feedback": {
        "coherence": {
          "all_error_num": 0,
          "all_error_ratio": 0.0,
          "bf_error_pairs": [],
          "checklist_1": 0,
          "checklist_18": 0,
          "checklist_19": 0,
          "checklist_2": 0,
          "checklist_20": 0,
          "checklist_21": 0,
          "checklist_22": 0,
          "checklist_23": 1,
          "checklist_24": 0,
          "checklist_25": 0,
          "checklist_26": 0,
          "checklist_27": 0,
          "checklist_28": 0,
          "correct_sent_num": 5,
          "minimal_essay_score": 0,
          "num_wo_conj_sent": 3,
          "para_score": 0,
          "ratio_simple_conj": 1.0,
          "ratio_wo_conj_sent": 0.6,
          "sys_coherence": 3
        },
        "content": {
          "abnormal_response_score": null,
          "checklist_15": null,
          "checklist_16": null,
          "checklist_17": null,
          "len_filter_score": -1.0,
          "non_english_score": -1,
          "offtopic_score": -1,
          "opinion_label": 0,
          "p0_checklist_results": {
            "checklist_10": 0,
            "checklist_11": 0,
            "checklist_12": 0,
            "checklist_345": 0,
            "checklist_4": 0,
            "checklist_9": 0,
            "checklist_all": 0,
            "explanation_len": 18,
            "relevance_score": 0.0,
            "sent_feats": [
              1.0,
              0.0
            ]
          },
          "p1_checklist_results": {
            "checklist_10": 1,
            "checklist_11": 0,
            "checklist_12": 1,
            "checklist_345": 1,
            "checklist_4": 0,
            "checklist_9": 1,
            "checklist_all": 1,
            "explanation_len": 0,
            "relevance_score": 0.0,
            "sent_feats": [
              0.0,
              10
            ]
          },
          "sys_content": 3
        },
        "grammar_vocabulary": {
          "grammar_checklist_items": {
            "grammar_checklist_30": 0,
            "grammar_checklist_31": 0,
            "grammar_checklist_32": 0,
            "grammar_checklist_33": 0,
            "grammar_checklist_34": 1,
            "grammar_checklist_35": 0,
            "grammar_checklist_36": 0,
            "grammar_checklist_37": 0,
            "grammar_checklist_38": 0
          },
          "grammar_complexity_score": 2,
          "max_sent_len": 23,
          "mean_sent_len": 15.333333333333334,
          "n_orth_error": 1,
          "n_spell_error": 0,
          "numwds": 62,
          "sentences": [
            {
              "both_err": [],
              "cor_sent": "I do not think that schools should encourage their students to do volunteer activities.",
              "coreference_sent": "I do not think that schools should encourage their students to do volunteer activities.",
              "err_ids": [
                15
              ],
              "error_types": [
                "Orig: [15, 16, ' '], Cor: [15, 15, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "I do not think that schools should encourage their students to do volunteer activities.  ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "Opinion",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "First, students are busy with their studies.",
              "coreference_sent": "First, students are busy for students' study.",
              "err_ids": [
                5,
                7,
                9
              ],
              "error_types": [
                "Orig: [5, 6, 'for'], Cor: [5, 6, 'with'], Type: 'R:PREP'",
                "Orig: [7, 8, 'study'], Cor: [7, 8, 'studies'], Type: 'R:NOUN:NUM'",
                "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:PREP",
                "R:NOUN:NUM"
              ],
              "grammar_sentence_score": 1,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 2,
              "org_sent": "First, students are busy for their study.  ",
              "r_err": 0.2857142857142857,
              "sentence_score": 1,
              "tag": "R0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "They have to do not only homework but also participate in after-school activities, so they do not have time.",
              "coreference_sent": "students have to do not only homework but also do after-school activities, so students do not have time.",
              "err_ids": [
                0,
                9,
                22
              ],
              "error_types": [
                "Orig: [0, 1, 'they'], Cor: [0, 1, 'They'], Type: 'R:ORTH'",
                "Orig: [9, 10, 'do'], Cor: [9, 11, 'participate in'], Type: 'R:OTHER'",
                "Orig: [22, 23, ' '], Cor: [23, 23, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "they have to do not only homework but also do after-school activities, so they do not have time.  ",
              "r_err": 0.05555555555555555,
              "sentence_score": 0,
              "tag": "EX0",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "Second, students should decide whether they want to volunteer or not.",
              "coreference_sent": "Second, students should decide whether students want volunteer or not.",
              "err_ids": [
                12
              ],
              "error_types": [
                "Orig: [8, 8, ''], Cor: [8, 9, 'to'], Type: 'M:VERB:FORM'",
                "Orig: [12, 13, ' '], Cor: [13, 13, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [
                "M:VERB:FORM"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "Second, students should decide whether they want volunteer or not.  ",
              "r_err": 0.1,
              "sentence_score": 0,
              "tag": "R1",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "For these reasons, I think that volunteer activities should not be forced on students.",
              "coreference_sent": "For these reasons, I think that volunteer activities should not be forced students.",
              "err_ids": [],
              "error_types": [
                "Orig: [13, 13, ''], Cor: [13, 14, 'on'], Type: 'M:PREP'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [
                "M:PREP"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "For these reasons, I think that volunteer activities should not be forced students.",
              "r_err": 0.07692307692307693,
              "sentence_score": 0,
              "tag": "Conclusion",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "sys_grammar": 3,
          "sys_grammar_max_sentence_score": 1,
          "sys_grammar_mean_sentence_score": 0.3333333333333333,
          "sys_vocabulary": 3,
          "sys_vocabulary_max_sentence_score": 0,
          "sys_vocabulary_mean_sentence_score": 0.0,
          "vocabulary_checklist_items": {
            "vocabulary_checklist_30": 0,
            "vocabulary_checklist_31": 0,
            "vocabulary_checklist_32": 0,
            "vocabulary_checklist_33": 0,
            "vocabulary_checklist_34": 0,
            "vocabulary_checklist_35": 1,
            "vocabulary_checklist_36": 0,
            "vocabulary_checklist_37": 0,
            "vocabulary_checklist_38": 0
          },
          "vocabulary_complexity_score": 0.0
        },
        "input": {
          "conclusion": "For these reasons, I think that volunteer activities should not be forced students.",
          "conclusion_position": null,
          "input_text": "I do not think that schools should encourage their students to do volunteer activities.  First, students are busy for their study.  they have to do not only homework but also do after-school activities, so they do not have time.  Second, students should decide whether they want volunteer or not.  For these reasons, I think that volunteer activities should not be forced students. ",
          "opinion": "I do not think that schools should encourage their students to do volunteer activities.",
          "opinion_position": "no",
          "p0": {
            "EX": "they have to do not only homework but also do after-school activities, so they do not have time.",
            "EX_cor_sent": "They have to do not only homework but also participate in after-school activities, so they do not have time.",
            "EX_sent": [
              {
                "both_err": [],
                "cor_sent": "They have to do not only homework but also participate in after-school activities, so they do not have time.",
                "cor_tokens": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 108,
                      "start": 0
                    }
                  ],
                  "text": "They have to do not only homework but also participate in after-school activities, so they do not have time.",
                  "tokens": [
                    {
                      "dep": "nsubj",
                      "end": 4,
                      "head": 1,
                      "id": 0,
                      "pos": "PRON",
                      "start": 0,
                      "tag": "PRP"
                    },
                    {
                      "dep": "ccomp",
                      "end": 9,
                      "head": 20,
                      "id": 1,
                      "pos": "AUX",
                      "start": 5,
                      "tag": "VBP"
                    },
                    {
                      "dep": "aux",
                      "end": 12,
                      "head": 3,
                      "id": 2,
                      "pos": "PART",
                      "start": 10,
                      "tag": "TO"
                    },
                    {
                      "dep": "xcomp",
                      "end": 15,
                      "head": 1,
                      "id": 3,
                      "pos": "AUX",
                      "start": 13,
                      "tag": "VB"
                    },
                    {
                      "dep": "preconj",
                      "end": 19,
                      "head": 6,
                      "id": 4,
                      "pos": "PART",
                      "start": 16,
                      "tag": "RB"
                    },
                    {
                      "dep": "advmod",
                      "end": 24,
                      "head": 4,
                      "id": 5,
                      "pos": "ADV",
                      "start": 20,
                      "tag": "RB"
                    },
                    {
                      "dep": "dobj",
                      "end": 33,
                      "head": 3,
                      "id": 6,
                      "pos": "VERB",
                      "start": 25,
                      "tag": "VB"
                    },
                    {
                      "dep": "cc",
                      "end": 37,
                      "head": 3,
                      "id": 7,
                      "pos": "CCONJ",
                      "start": 34,
                      "tag": "CC"
                    },
                    {
                      "dep": "advmod",
                      "end": 42,
                      "head": 7,
                      "id": 8,
                      "pos": "ADV",
                      "start": 38,
                      "tag": "RB"
                    },
                    {
                      "dep": "conj",
                      "end": 54,
                      "head": 3,
                      "id": 9,
                      "pos": "VERB",
                      "start": 43,
                      "tag": "VBP"
                    },
                    {
                      "dep": "prep",
                      "end": 57,
                      "head": 9,
                      "id": 10,
                      "pos": "ADP",
                      "start": 55,
                      "tag": "IN"
                    },
                    {
                      "dep": "nmod",
                      "end": 63,
                      "head": 14,
                      "id": 11,
                      "pos": "ADP",
                      "start": 58,
                      "tag": "IN"
                    },
                    {
                      "dep": "punct",
                      "end": 64,
                      "head": 11,
                      "id": 12,
                      "pos": "PUNCT",
                      "start": 63,
                      "tag": "HYPH"
                    },
                    {
                      "dep": "pobj",
                      "end": 70,
                      "head": 11,
                      "id": 13,
                      "pos": "NOUN",
                      "start": 64,
                      "tag": "NN"
                    },
                    {
                      "dep": "pobj",
                      "end": 81,
                      "head": 10,
                      "id": 14,
                      "pos": "NOUN",
                      "start": 71,
                      "tag": "NNS"
                    },
                    {
                      "dep": "punct",
                      "end": 82,
                      "head": 20,
                      "id": 15,
                      "pos": "PUNCT",
                      "start": 81,
                      "tag": ","
                    },
                    {
                      "dep": "advmod",
                      "end": 85,
                      "head": 20,
                      "id": 16,
                      "pos": "CCONJ",
                      "start": 83,
                      "tag": "CC"
                    },
                    {
                      "dep": "nsubj",
                      "end": 90,
                      "head": 20,
                      "id": 17,
                      "pos": "PRON",
                      "start": 86,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 93,
                      "head": 20,
                      "id": 18,
                      "pos": "AUX",
                      "start": 91,
                      "tag": "VBP"
                    },
                    {
                      "dep": "neg",
                      "end": 97,
                      "head": 20,
                      "id": 19,
                      "pos": "PART",
                      "start": 94,
                      "tag": "RB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 102,
                      "head": 20,
                      "id": 20,
                      "pos": "AUX",
                      "start": 98,
                      "tag": "VB"
                    },
                    {
                      "dep": "dobj",
                      "end": 107,
                      "head": 20,
                      "id": 21,
                      "pos": "NOUN",
                      "start": 103,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 108,
                      "head": 20,
                      "id": 22,
                      "pos": "PUNCT",
                      "start": 107,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "students have to do not only homework but also do after-school activities, so students do not have time.",
                "edits": [
                  "Orig: [0, 1, 'they'], Cor: [0, 1, 'They'], Type: 'R:ORTH'",
                  "Orig: [9, 10, 'do'], Cor: [9, 11, 'participate in'], Type: 'R:OTHER'",
                  "Orig: [22, 23, ' '], Cor: [23, 23, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  0,
                  9,
                  22
                ],
                "error_span": 1,
                "error_types": [
                  "Orig: [0, 1, 'they'], Cor: [0, 1, 'They'], Type: 'R:ORTH'",
                  "Orig: [9, 10, 'do'], Cor: [9, 11, 'participate in'], Type: 'R:OTHER'",
                  "Orig: [22, 23, ' '], Cor: [23, 23, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 0,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 1,
                "grammar_err": [],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 1,
                "org_sent": "they have to do not only homework but also do after-school activities, so they do not have time.  ",
                "org_tokens": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 98,
                      "start": 0
                    }
                  ],
                  "text": "they have to do not only homework but also do after-school activities, so they do not have time.  ",
                  "tokens": [
                    {
                      "dep": "nsubj",
                      "end": 4,
                      "head": 1,
                      "id": 0,
                      "pos": "PRON",
                      "start": 0,
                      "tag": "PRP"
                    },
                    {
                      "dep": "ccomp",
                      "end": 9,
                      "head": 19,
                      "id": 1,
                      "pos": "AUX",
                      "start": 5,
                      "tag": "VBP"
                    },
                    {
                      "dep": "aux",
                      "end": 12,
                      "head": 3,
                      "id": 2,
                      "pos": "PART",
                      "start": 10,
                      "tag": "TO"
                    },
                    {
                      "dep": "xcomp",
                      "end": 15,
                      "head": 1,
                      "id": 3,
                      "pos": "AUX",
                      "start": 13,
                      "tag": "VB"
                    },
                    {
                      "dep": "preconj",
                      "end": 19,
                      "head": 6,
                      "id": 4,
                      "pos": "PART",
                      "start": 16,
                      "tag": "RB"
                    },
                    {
                      "dep": "advmod",
                      "end": 24,
                      "head": 4,
                      "id": 5,
                      "pos": "ADV",
                      "start": 20,
                      "tag": "RB"
                    },
                    {
                      "dep": "dobj",
                      "end": 33,
                      "head": 3,
                      "id": 6,
                      "pos": "VERB",
                      "start": 25,
                      "tag": "VB"
                    },
                    {
                      "dep": "cc",
                      "end": 37,
                      "head": 3,
                      "id": 7,
                      "pos": "CCONJ",
                      "start": 34,
                      "tag": "CC"
                    },
                    {
                      "dep": "advmod",
                      "end": 42,
                      "head": 7,
                      "id": 8,
                      "pos": "ADV",
                      "start": 38,
                      "tag": "RB"
                    },
                    {
                      "dep": "conj",
                      "end": 45,
                      "head": 3,
                      "id": 9,
                      "pos": "AUX",
                      "start": 43,
                      "tag": "VBP"
                    },
                    {
                      "dep": "nmod",
                      "end": 51,
                      "head": 13,
                      "id": 10,
                      "pos": "ADP",
                      "start": 46,
                      "tag": "IN"
                    },
                    {
                      "dep": "punct",
                      "end": 52,
                      "head": 10,
                      "id": 11,
                      "pos": "PUNCT",
                      "start": 51,
                      "tag": "HYPH"
                    },
                    {
                      "dep": "pobj",
                      "end": 58,
                      "head": 10,
                      "id": 12,
                      "pos": "NOUN",
                      "start": 52,
                      "tag": "NN"
                    },
                    {
                      "dep": "dobj",
                      "end": 69,
                      "head": 9,
                      "id": 13,
                      "pos": "NOUN",
                      "start": 59,
                      "tag": "NNS"
                    },
                    {
                      "dep": "punct",
                      "end": 70,
                      "head": 19,
                      "id": 14,
                      "pos": "PUNCT",
                      "start": 69,
                      "tag": ","
                    },
                    {
                      "dep": "advmod",
                      "end": 73,
                      "head": 19,
                      "id": 15,
                      "pos": "CCONJ",
                      "start": 71,
                      "tag": "CC"
                    },
                    {
                      "dep": "nsubj",
                      "end": 78,
                      "head": 19,
                      "id": 16,
                      "pos": "PRON",
                      "start": 74,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 81,
                      "head": 19,
                      "id": 17,
                      "pos": "AUX",
                      "start": 79,
                      "tag": "VBP"
                    },
                    {
                      "dep": "neg",
                      "end": 85,
                      "head": 19,
                      "id": 18,
                      "pos": "PART",
                      "start": 82,
                      "tag": "RB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 90,
                      "head": 19,
                      "id": 19,
                      "pos": "AUX",
                      "start": 86,
                      "tag": "VB"
                    },
                    {
                      "dep": "dobj",
                      "end": 95,
                      "head": 19,
                      "id": 20,
                      "pos": "NOUN",
                      "start": 91,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 96,
                      "head": 19,
                      "id": 21,
                      "pos": "PUNCT",
                      "start": 95,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 98,
                      "head": 21,
                      "id": 22,
                      "pos": "SPACE",
                      "start": 97,
                      "tag": "_SP"
                    }
                  ]
                },
                "r_err": 0.05555555555555555,
                "sent_for_SimilarityScorer": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 104,
                      "start": 0
                    }
                  ],
                  "text": "students have to do not only homework but also do after-school activities, so students do not have time.",
                  "tokens": [
                    {
                      "dep": "nsubj",
                      "end": 8,
                      "head": 1,
                      "id": 0,
                      "pos": "NOUN",
                      "start": 0,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ccomp",
                      "end": 13,
                      "head": 19,
                      "id": 1,
                      "pos": "AUX",
                      "start": 9,
                      "tag": "VBP"
                    },
                    {
                      "dep": "aux",
                      "end": 16,
                      "head": 3,
                      "id": 2,
                      "pos": "PART",
                      "start": 14,
                      "tag": "TO"
                    },
                    {
                      "dep": "xcomp",
                      "end": 19,
                      "head": 1,
                      "id": 3,
                      "pos": "AUX",
                      "start": 17,
                      "tag": "VB"
                    },
                    {
                      "dep": "preconj",
                      "end": 23,
                      "head": 6,
                      "id": 4,
                      "pos": "PART",
                      "start": 20,
                      "tag": "RB"
                    },
                    {
                      "dep": "advmod",
                      "end": 28,
                      "head": 4,
                      "id": 5,
                      "pos": "ADV",
                      "start": 24,
                      "tag": "RB"
                    },
                    {
                      "dep": "dobj",
                      "end": 37,
                      "head": 3,
                      "id": 6,
                      "pos": "VERB",
                      "start": 29,
                      "tag": "VB"
                    },
                    {
                      "dep": "cc",
                      "end": 41,
                      "head": 3,
                      "id": 7,
                      "pos": "CCONJ",
                      "start": 38,
                      "tag": "CC"
                    },
                    {
                      "dep": "advmod",
                      "end": 46,
                      "head": 7,
                      "id": 8,
                      "pos": "ADV",
                      "start": 42,
                      "tag": "RB"
                    },
                    {
                      "dep": "conj",
                      "end": 49,
                      "head": 3,
                      "id": 9,
                      "pos": "AUX",
                      "start": 47,
                      "tag": "VBP"
                    },
                    {
                      "dep": "nmod",
                      "end": 55,
                      "head": 13,
                      "id": 10,
                      "pos": "ADP",
                      "start": 50,
                      "tag": "IN"
                    },
                    {
                      "dep": "punct",
                      "end": 56,
                      "head": 10,
                      "id": 11,
                      "pos": "PUNCT",
                      "start": 55,
                      "tag": "HYPH"
                    },
                    {
                      "dep": "pobj",
                      "end": 62,
                      "head": 10,
                      "id": 12,
                      "pos": "NOUN",
                      "start": 56,
                      "tag": "NN"
                    },
                    {
                      "dep": "dobj",
                      "end": 73,
                      "head": 9,
                      "id": 13,
                      "pos": "NOUN",
                      "start": 63,
                      "tag": "NNS"
                    },
                    {
                      "dep": "punct",
                      "end": 74,
                      "head": 19,
                      "id": 14,
                      "pos": "PUNCT",
                      "start": 73,
                      "tag": ","
                    },
                    {
                      "dep": "mark",
                      "end": 77,
                      "head": 19,
                      "id": 15,
                      "pos": "SCONJ",
                      "start": 75,
                      "tag": "IN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 86,
                      "head": 19,
                      "id": 16,
                      "pos": "NOUN",
                      "start": 78,
                      "tag": "NNS"
                    },
                    {
                      "dep": "aux",
                      "end": 89,
                      "head": 19,
                      "id": 17,
                      "pos": "AUX",
                      "start": 87,
                      "tag": "VBP"
                    },
                    {
                      "dep": "neg",
                      "end": 93,
                      "head": 19,
                      "id": 18,
                      "pos": "PART",
                      "start": 90,
                      "tag": "RB"
                    },
                    {
                      "dep": "ROOT",
                      "end": 98,
                      "head": 19,
                      "id": 19,
                      "pos": "AUX",
                      "start": 94,
                      "tag": "VB"
                    },
                    {
                      "dep": "dobj",
                      "end": 103,
                      "head": 19,
                      "id": 20,
                      "pos": "NOUN",
                      "start": 99,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 104,
                      "head": 19,
                      "id": 21,
                      "pos": "PUNCT",
                      "start": 103,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 0,
                "sentence_w_verr": 0,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "EX0",
                "vocabulary_err": [
                  "R:OTHER"
                ],
                "vocabulary_sentence_score": 0
              }
            ],
            "R": "First, students are busy for their study.",
            "R_cor_sent": "First, students are busy with their studies.",
            "R_sent": [
              {
                "both_err": [],
                "cor_sent": "First, students are busy with their studies.",
                "cor_tokens": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 44,
                      "start": 0
                    }
                  ],
                  "text": "First, students are busy with their studies.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 3,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 3,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 15,
                      "head": 3,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 7,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ROOT",
                      "end": 19,
                      "head": 3,
                      "id": 3,
                      "pos": "AUX",
                      "start": 16,
                      "tag": "VBP"
                    },
                    {
                      "dep": "acomp",
                      "end": 24,
                      "head": 3,
                      "id": 4,
                      "pos": "ADJ",
                      "start": 20,
                      "tag": "JJ"
                    },
                    {
                      "dep": "prep",
                      "end": 29,
                      "head": 4,
                      "id": 5,
                      "pos": "ADP",
                      "start": 25,
                      "tag": "IN"
                    },
                    {
                      "dep": "poss",
                      "end": 35,
                      "head": 7,
                      "id": 6,
                      "pos": "DET",
                      "start": 30,
                      "tag": "PRP$"
                    },
                    {
                      "dep": "pobj",
                      "end": 43,
                      "head": 5,
                      "id": 7,
                      "pos": "NOUN",
                      "start": 36,
                      "tag": "NNS"
                    },
                    {
                      "dep": "punct",
                      "end": 44,
                      "head": 3,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 43,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "First, students are busy for students' study.",
                "edits": [
                  "Orig: [5, 6, 'for'], Cor: [5, 6, 'with'], Type: 'R:PREP'",
                  "Orig: [7, 8, 'study'], Cor: [7, 8, 'studies'], Type: 'R:NOUN:NUM'",
                  "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  5,
                  7,
                  9
                ],
                "error_span": 2,
                "error_types": [
                  "Orig: [5, 6, 'for'], Cor: [5, 6, 'with'], Type: 'R:PREP'",
                  "Orig: [7, 8, 'study'], Cor: [7, 8, 'studies'], Type: 'R:NOUN:NUM'",
                  "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 1,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [
                  "R:PREP",
                  "R:NOUN:NUM"
                ],
                "grammar_sentence_score": 1,
                "is_grammar_error_critical": 1,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 2,
                "org_sent": "First, students are busy for their study.  ",
                "org_tokens": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 43,
                      "start": 0
                    }
                  ],
                  "text": "First, students are busy for their study.  ",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 3,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 3,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 15,
                      "head": 3,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 7,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ROOT",
                      "end": 19,
                      "head": 3,
                      "id": 3,
                      "pos": "AUX",
                      "start": 16,
                      "tag": "VBP"
                    },
                    {
                      "dep": "acomp",
                      "end": 24,
                      "head": 3,
                      "id": 4,
                      "pos": "ADJ",
                      "start": 20,
                      "tag": "JJ"
                    },
                    {
                      "dep": "prep",
                      "end": 28,
                      "head": 4,
                      "id": 5,
                      "pos": "ADP",
                      "start": 25,
                      "tag": "IN"
                    },
                    {
                      "dep": "poss",
                      "end": 34,
                      "head": 7,
                      "id": 6,
                      "pos": "DET",
                      "start": 29,
                      "tag": "PRP$"
                    },
                    {
                      "dep": "pobj",
                      "end": 40,
                      "head": 5,
                      "id": 7,
                      "pos": "NOUN",
                      "start": 35,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 41,
                      "head": 3,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 40,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 43,
                      "head": 8,
                      "id": 9,
                      "pos": "SPACE",
                      "start": 42,
                      "tag": "_SP"
                    }
                  ]
                },
                "r_err": 0.2857142857142857,
                "sent_for_SimilarityScorer": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 45,
                      "start": 0
                    }
                  ],
                  "text": "First, students are busy for students' study.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 3,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 3,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 15,
                      "head": 3,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 7,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ROOT",
                      "end": 19,
                      "head": 3,
                      "id": 3,
                      "pos": "AUX",
                      "start": 16,
                      "tag": "VBP"
                    },
                    {
                      "dep": "acomp",
                      "end": 24,
                      "head": 3,
                      "id": 4,
                      "pos": "ADJ",
                      "start": 20,
                      "tag": "JJ"
                    },
                    {
                      "dep": "prep",
                      "end": 28,
                      "head": 4,
                      "id": 5,
                      "pos": "ADP",
                      "start": 25,
                      "tag": "IN"
                    },
                    {
                      "dep": "poss",
                      "end": 37,
                      "head": 8,
                      "id": 6,
                      "pos": "NOUN",
                      "start": 29,
                      "tag": "NNS"
                    },
                    {
                      "dep": "case",
                      "end": 38,
                      "head": 6,
                      "id": 7,
                      "pos": "PART",
                      "start": 37,
                      "tag": "POS"
                    },
                    {
                      "dep": "pobj",
                      "end": 44,
                      "head": 5,
                      "id": 8,
                      "pos": "NOUN",
                      "start": 39,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 45,
                      "head": 3,
                      "id": 9,
                      "pos": "PUNCT",
                      "start": 44,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 1,
                "sentence_w_verr": 0,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "R0",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              }
            ],
            "co_EX": "students have to do not only homework but also do after-school activities, so students do not have time.",
            "co_R": "First, students are busy for students' study.",
            "no_conj_EX": "They have to do not only homework but also do after school activities so they do not have time.",
            "no_conj_R": "Students are busy for their study."
          },
          "p1": {
            "EX": "",
            "EX_cor_sent": "",
            "EX_sent": [],
            "R": "Second, students should decide whether they want volunteer or not.",
            "R_cor_sent": "Second, students should decide whether they want to volunteer or not.",
            "R_sent": [
              {
                "both_err": [],
                "cor_sent": "Second, students should decide whether they want to volunteer or not.",
                "cor_tokens": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 69,
                      "start": 0
                    }
                  ],
                  "text": "Second, students should decide whether they want to volunteer or not.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 4,
                      "id": 0,
                      "pos": "ADJ",
                      "start": 0,
                      "tag": "JJ"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 16,
                      "head": 4,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 8,
                      "tag": "NNS"
                    },
                    {
                      "dep": "aux",
                      "end": 23,
                      "head": 4,
                      "id": 3,
                      "pos": "VERB",
                      "start": 17,
                      "tag": "MD"
                    },
                    {
                      "dep": "ROOT",
                      "end": 30,
                      "head": 4,
                      "id": 4,
                      "pos": "VERB",
                      "start": 24,
                      "tag": "VB"
                    },
                    {
                      "dep": "mark",
                      "end": 38,
                      "head": 7,
                      "id": 5,
                      "pos": "SCONJ",
                      "start": 31,
                      "tag": "IN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 43,
                      "head": 7,
                      "id": 6,
                      "pos": "PRON",
                      "start": 39,
                      "tag": "PRP"
                    },
                    {
                      "dep": "ccomp",
                      "end": 48,
                      "head": 4,
                      "id": 7,
                      "pos": "VERB",
                      "start": 44,
                      "tag": "VBP"
                    },
                    {
                      "dep": "aux",
                      "end": 51,
                      "head": 9,
                      "id": 8,
                      "pos": "PART",
                      "start": 49,
                      "tag": "TO"
                    },
                    {
                      "dep": "xcomp",
                      "end": 61,
                      "head": 7,
                      "id": 9,
                      "pos": "VERB",
                      "start": 52,
                      "tag": "VB"
                    },
                    {
                      "dep": "cc",
                      "end": 64,
                      "head": 9,
                      "id": 10,
                      "pos": "CCONJ",
                      "start": 62,
                      "tag": "CC"
                    },
                    {
                      "dep": "conj",
                      "end": 68,
                      "head": 7,
                      "id": 11,
                      "pos": "PART",
                      "start": 65,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 69,
                      "head": 4,
                      "id": 12,
                      "pos": "PUNCT",
                      "start": 68,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "Second, students should decide whether students want volunteer or not.",
                "edits": [
                  "Orig: [8, 8, ''], Cor: [8, 9, 'to'], Type: 'M:VERB:FORM'",
                  "Orig: [12, 13, ' '], Cor: [13, 13, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  12
                ],
                "error_span": 0,
                "error_types": [
                  "Orig: [8, 8, ''], Cor: [8, 9, 'to'], Type: 'M:VERB:FORM'",
                  "Orig: [12, 13, ' '], Cor: [13, 13, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 0,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 1,
                "grammar_err": [
                  "M:VERB:FORM"
                ],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 1,
                "org_sent": "Second, students should decide whether they want volunteer or not.  ",
                "org_tokens": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 68,
                      "start": 0
                    }
                  ],
                  "text": "Second, students should decide whether they want volunteer or not.  ",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 4,
                      "id": 0,
                      "pos": "ADJ",
                      "start": 0,
                      "tag": "JJ"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 16,
                      "head": 4,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 8,
                      "tag": "NNS"
                    },
                    {
                      "dep": "aux",
                      "end": 23,
                      "head": 4,
                      "id": 3,
                      "pos": "VERB",
                      "start": 17,
                      "tag": "MD"
                    },
                    {
                      "dep": "ROOT",
                      "end": 30,
                      "head": 4,
                      "id": 4,
                      "pos": "VERB",
                      "start": 24,
                      "tag": "VB"
                    },
                    {
                      "dep": "mark",
                      "end": 38,
                      "head": 7,
                      "id": 5,
                      "pos": "SCONJ",
                      "start": 31,
                      "tag": "IN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 43,
                      "head": 7,
                      "id": 6,
                      "pos": "PRON",
                      "start": 39,
                      "tag": "PRP"
                    },
                    {
                      "dep": "ccomp",
                      "end": 48,
                      "head": 4,
                      "id": 7,
                      "pos": "VERB",
                      "start": 44,
                      "tag": "VBP"
                    },
                    {
                      "dep": "dobj",
                      "end": 58,
                      "head": 7,
                      "id": 8,
                      "pos": "NOUN",
                      "start": 49,
                      "tag": "NN"
                    },
                    {
                      "dep": "cc",
                      "end": 61,
                      "head": 7,
                      "id": 9,
                      "pos": "CCONJ",
                      "start": 59,
                      "tag": "CC"
                    },
                    {
                      "dep": "conj",
                      "end": 65,
                      "head": 7,
                      "id": 10,
                      "pos": "PART",
                      "start": 62,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 66,
                      "head": 4,
                      "id": 11,
                      "pos": "PUNCT",
                      "start": 65,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 68,
                      "head": 11,
                      "id": 12,
                      "pos": "SPACE",
                      "start": 67,
                      "tag": "_SP"
                    }
                  ]
                },
                "r_err": 0.1,
                "sent_for_SimilarityScorer": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 70,
                      "start": 0
                    }
                  ],
                  "text": "Second, students should decide whether students want volunteer or not.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 4,
                      "id": 0,
                      "pos": "ADJ",
                      "start": 0,
                      "tag": "JJ"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 16,
                      "head": 4,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 8,
                      "tag": "NNS"
                    },
                    {
                      "dep": "aux",
                      "end": 23,
                      "head": 4,
                      "id": 3,
                      "pos": "VERB",
                      "start": 17,
                      "tag": "MD"
                    },
                    {
                      "dep": "ROOT",
                      "end": 30,
                      "head": 4,
                      "id": 4,
                      "pos": "VERB",
                      "start": 24,
                      "tag": "VB"
                    },
                    {
                      "dep": "mark",
                      "end": 38,
                      "head": 7,
                      "id": 5,
                      "pos": "SCONJ",
                      "start": 31,
                      "tag": "IN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 47,
                      "head": 7,
                      "id": 6,
                      "pos": "NOUN",
                      "start": 39,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ccomp",
                      "end": 52,
                      "head": 4,
                      "id": 7,
                      "pos": "VERB",
                      "start": 48,
                      "tag": "VBP"
                    },
                    {
                      "dep": "dobj",
                      "end": 62,
                      "head": 7,
                      "id": 8,
                      "pos": "NOUN",
                      "start": 53,
                      "tag": "NN"
                    },
                    {
                      "dep": "cc",
                      "end": 65,
                      "head": 7,
                      "id": 9,
                      "pos": "CCONJ",
                      "start": 63,
                      "tag": "CC"
                    },
                    {
                      "dep": "conj",
                      "end": 69,
                      "head": 7,
                      "id": 10,
                      "pos": "PART",
                      "start": 66,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 70,
                      "head": 4,
                      "id": 11,
                      "pos": "PUNCT",
                      "start": 69,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 0,
                "sentence_w_verr": 1,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "R1",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              }
            ],
            "co_EX": "",
            "co_R": "Second, students should decide whether students want volunteer or not.",
            "no_conj_EX": "",
            "no_conj_R": "Students should decide whether they want volunteer or not."
          },
          "p2": {},
          "question_data": {
            "keywords": [
              "Learning responsibility",
              "Personal development",
              "Personal freedom"
            ],
            "question_text": "Today, many Japanese schools encourage their students to participate in volunteer activities. Do you think this is a good idea?"
          }
        }
      }
    }
  },
  "G25": {
    "opinion": {
      "title": "Cleaning school: should students do?",
      "question": {
        "grade": "G25",
        "type": "opinion",
        "question_text": "Do you think it is a good idea to have students clean their schools?",
        "keywords": [
          ""
        ]
      },
      "essay": "I think it is a good idea to have students clean their schools. First, they can learn how to clean. This skill help them to have clean house in the future. Second, not school dirty. They no they have how to clean. Therefore, students should have to clean their own classroom and school.",
      "feedback": {
        "coherence": {
          "all_error_num": 2,
          "all_error_ratio": 0.2857142857142857,
          "bf_error_pairs": [
            [
              1,
              5
            ]
          ],
          "checklist_1": 0,
          "checklist_18": 0,
          "checklist_19": 0,
          "checklist_2": 0,
          "checklist_20": 0,
          "checklist_21": 0,
          "checklist_22": 0,
          "checklist_23": 0,
          "checklist_24": 0,
          "checklist_25": 0,
          "checklist_26": 1,
          "checklist_27": 0,
          "checklist_28": 0,
          "correct_sent_num": 5,
          "minimal_essay_score": 0,
          "num_wo_conj_sent": 4,
          "para_score": 0,
          "ratio_simple_conj": 1.0,
          "ratio_wo_conj_sent": 0.5714285714285714,
          "sys_coherence": 3
        },
        "content": {
          "abnormal_response_score": null,
          "checklist_15": null,
          "checklist_16": null,
          "checklist_17": null,
          "len_filter_score": -1.0,
          "non_english_score": -1,
          "offtopic_score": -1,
          "opinion_label": 0,
          "p0_checklist_results": {
            "checklist_10": 0,
            "checklist_11": 0,
            "checklist_12": 0,
            "checklist_345": 0,
            "checklist_4": 0,
            "checklist_9": 0,
            "checklist_all": 0,
            "explanation_len": 11,
            "relevance_score": 1.0,
            "sent_feats": [
              0.0,
              0.0
            ]
          },
          "p1_checklist_results": {
            "checklist_10": 0,
            "checklist_11": 0,
            "checklist_12": 0,
            "checklist_345": 0,
            "checklist_4": 0,
            "checklist_9": 1,
            "checklist_all": 1,
            "explanation_len": 7,
            "relevance_score": 1.0,
            "sent_feats": [
              2.0,
              1.0
            ]
          },
          "sys_content": 3
        },
        "grammar_vocabulary": {
          "grammar_checklist_items": {
            "grammar_checklist_30": 0,
            "grammar_checklist_31": 0,
            "grammar_checklist_32": 1,
            "grammar_checklist_33": 0,
            "grammar_checklist_34": 0,
            "grammar_checklist_35": 0,
            "grammar_checklist_36": 0,
            "grammar_checklist_37": 0,
            "grammar_checklist_38": 0
          },
          "grammar_complexity_score": 1,
          "max_sent_len": 13,
          "mean_sent_len": 7.8,
          "n_orth_error": 0,
          "n_spell_error": 0,
          "numwds": 53,
          "sentences": [
            {
              "both_err": [],
              "cor_sent": "I think it is a good idea to have students clean their schools.",
              "coreference_sent": "I think that a good idea to have students clean their schools is a good idea.",
              "err_ids": [
                14
              ],
              "error_types": [
                "Orig: [14, 15, '  '], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "I think it is a good idea to have students clean their schools.   ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "Opinion",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "First, they can learn how to clean.",
              "coreference_sent": "First, students can learn how to clean.",
              "err_ids": [
                24
              ],
              "error_types": [
                "Orig: [24, 25, '  '], Cor: [23, 23, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "First, they can learn how to clean.   ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "R0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "This skill helps them to have a clean house in the future.",
              "coreference_sent": "This skill help students to have clean house in the future.",
              "err_ids": [
                27,
                37
              ],
              "error_types": [
                "Orig: [27, 28, 'help'], Cor: [25, 26, 'helps'], Type: 'R:VERB:SVA'",
                "Orig: [31, 31, ''], Cor: [29, 30, 'a'], Type: 'M:DET'",
                "Orig: [37, 38, '  '], Cor: [36, 36, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [
                "R:VERB:SVA"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "This skill help them to have clean house in the future.   ",
              "r_err": 0.09090909090909091,
              "sentence_score": 0,
              "tag": "EX0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [
                "M:OTHER"
              ],
              "cor_sent": "Secondly, the school should not be dirty.",
              "coreference_sent": "Second, not school dirty.",
              "err_ids": [
                38,
                40,
                44
              ],
              "error_types": [
                "Orig: [38, 39, 'Second'], Cor: [36, 37, 'Secondly'], Type: 'R:MORPH'",
                "Orig: [40, 41, 'not'], Cor: [38, 39, 'the'], Type: 'R:OTHER'",
                "Orig: [42, 42, ''], Cor: [40, 43, 'should not be'], Type: 'M:OTHER'",
                "Orig: [44, 45, '  '], Cor: [45, 45, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:MORPH"
              ],
              "grammar_sentence_score": 2,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 1,
              "n_err": 3,
              "org_sent": "Second, not school dirty.   ",
              "r_err": 0.75,
              "sentence_score": 2,
              "tag": "R1",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 2
            },
            {
              "both_err": [],
              "cor_sent": "They",
              "coreference_sent": "Students",
              "err_ids": [],
              "error_types": [],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 1,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "They",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "R0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "should know how to clean.",
              "coreference_sent": "no students have how to clean.",
              "err_ids": [
                46,
                47,
                48,
                53
              ],
              "error_types": [
                "Orig: [46, 49, 'no they have'], Cor: [46, 48, 'should know'], Type: 'R:OTHER'",
                "Orig: [53, 54, '  '], Cor: [52, 52, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 1,
              "is_repeated_sentence": true,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "no they have how to clean.   ",
              "r_err": 0.16666666666666666,
              "sentence_score": 1,
              "tag": "EX1",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 1
            },
            {
              "both_err": [],
              "cor_sent": "Therefore, students should have to clean their own classrooms and the school.",
              "coreference_sent": "Therefore, students should have to clean students' own classroom and school.",
              "err_ids": [
                63
              ],
              "error_types": [
                "Orig: [63, 64, 'classroom'], Cor: [61, 62, 'classrooms'], Type: 'R:NOUN:NUM'",
                "Orig: [65, 65, ''], Cor: [63, 64, 'the'], Type: 'M:DET'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:NOUN:NUM"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "Therefore, students should have to clean their own classroom and school.",
              "r_err": 0.09090909090909091,
              "sentence_score": 0,
              "tag": "Conclusion",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "sys_grammar": 2,
          "sys_grammar_max_sentence_score": 2,
          "sys_grammar_mean_sentence_score": 0.4,
          "sys_vocabulary": 2,
          "sys_vocabulary_max_sentence_score": 2,
          "sys_vocabulary_mean_sentence_score": 0.6,
          "vocabulary_checklist_items": {
            "vocabulary_checklist_30": 0,
            "vocabulary_checklist_31": 0,
            "vocabulary_checklist_32": 1,
            "vocabulary_checklist_33": 0,
            "vocabulary_checklist_34": 0,
            "vocabulary_checklist_35": 0,
            "vocabulary_checklist_36": 0,
            "vocabulary_checklist_37": 0,
            "vocabulary_checklist_38": 0
          },
          "vocabulary_complexity_score": 0.0
        },
        "input": {
          "conclusion": "Therefore, students should have to clean their own classroom and school.",
          "conclusion_position": null,
          "input_text": "I think it is a good idea to have students clean their schools.  First, they can learn how to clean.  This skill help them to have clean house in the future.  Second, not school dirty.  They no they have how to clean.  Therefore, students should have to clean their own classroom and school. ",
          "opinion": "I think it is a good idea to have students clean their schools.",
          "opinion_position": "yes",
          "p0": {
            "EX": "This skill help them to have clean house in the future.",
            "EX_cor_sent": "This skill helps them to have a clean house in the future.",
            "EX_sent": [
              {
                "both_err": [],
                "cor_sent": "This skill helps them to have a clean house in the future.",
                "cor_tokens": [
                  "This",
                  "skill",
                  "helps",
                  "them",
                  "to",
                  "have",
                  "a",
                  "clean",
                  "house",
                  "in",
                  "the",
                  "future",
                  "."
                ],
                "coreference_sent": "This skill help students to have clean house in the future.",
                "edits": [
                  "Orig: [27, 28, 'help'], Cor: [25, 26, 'helps'], Type: 'R:VERB:SVA'",
                  "Orig: [31, 31, ''], Cor: [29, 30, 'a'], Type: 'M:DET'",
                  "Orig: [37, 38, '  '], Cor: [36, 36, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  27,
                  37
                ],
                "error_span": 1,
                "error_types": [
                  "Orig: [27, 28, 'help'], Cor: [25, 26, 'helps'], Type: 'R:VERB:SVA'",
                  "Orig: [31, 31, ''], Cor: [29, 30, 'a'], Type: 'M:DET'",
                  "Orig: [37, 38, '  '], Cor: [36, 36, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 0,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 1,
                "grammar_err": [
                  "R:VERB:SVA"
                ],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 1,
                "org_sent": "This skill help them to have clean house in the future.   ",
                "org_tokens": [
                  "This",
                  "skill",
                  "help",
                  "them",
                  "to",
                  "have",
                  "clean",
                  "house",
                  "in",
                  "the",
                  "future",
                  ".",
                  "  "
                ],
                "r_err": 0.09090909090909091,
                "sent_for_SimilarityScorer": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 59,
                      "start": 0
                    }
                  ],
                  "text": "This skill help students to have clean house in the future.",
                  "tokens": [
                    {
                      "dep": "det",
                      "end": 4,
                      "head": 1,
                      "id": 0,
                      "pos": "DET",
                      "start": 0,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 10,
                      "head": 2,
                      "id": 1,
                      "pos": "NOUN",
                      "start": 5,
                      "tag": "NN"
                    },
                    {
                      "dep": "ROOT",
                      "end": 15,
                      "head": 2,
                      "id": 2,
                      "pos": "VERB",
                      "start": 11,
                      "tag": "VBP"
                    },
                    {
                      "dep": "nsubj",
                      "end": 24,
                      "head": 5,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 16,
                      "tag": "NNS"
                    },
                    {
                      "dep": "aux",
                      "end": 27,
                      "head": 5,
                      "id": 4,
                      "pos": "PART",
                      "start": 25,
                      "tag": "TO"
                    },
                    {
                      "dep": "ccomp",
                      "end": 32,
                      "head": 2,
                      "id": 5,
                      "pos": "AUX",
                      "start": 28,
                      "tag": "VB"
                    },
                    {
                      "dep": "amod",
                      "end": 38,
                      "head": 7,
                      "id": 6,
                      "pos": "ADJ",
                      "start": 33,
                      "tag": "JJ"
                    },
                    {
                      "dep": "dobj",
                      "end": 44,
                      "head": 5,
                      "id": 7,
                      "pos": "NOUN",
                      "start": 39,
                      "tag": "NN"
                    },
                    {
                      "dep": "prep",
                      "end": 47,
                      "head": 5,
                      "id": 8,
                      "pos": "ADP",
                      "start": 45,
                      "tag": "IN"
                    },
                    {
                      "dep": "det",
                      "end": 51,
                      "head": 10,
                      "id": 9,
                      "pos": "DET",
                      "start": 48,
                      "tag": "DT"
                    },
                    {
                      "dep": "pobj",
                      "end": 58,
                      "head": 8,
                      "id": 10,
                      "pos": "NOUN",
                      "start": 52,
                      "tag": "NN"
                    },
                    {
                      "dep": "punct",
                      "end": 59,
                      "head": 2,
                      "id": 11,
                      "pos": "PUNCT",
                      "start": 58,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 0,
                "sentence_w_verr": 1,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "EX0",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              }
            ],
            "R": "First, they can learn how to clean.",
            "R_cor_sent": "First, they can learn how to clean. They",
            "R_sent": [
              {
                "both_err": [],
                "cor_sent": "First, they can learn how to clean.",
                "cor_tokens": [
                  "First",
                  ",",
                  "they",
                  "can",
                  "learn",
                  "how",
                  "to",
                  "clean",
                  "."
                ],
                "coreference_sent": "First, students can learn how to clean.",
                "edits": [
                  "Orig: [24, 25, '  '], Cor: [23, 23, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  24
                ],
                "error_span": 0,
                "error_types": [
                  "Orig: [24, 25, '  '], Cor: [23, 23, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 0,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 0,
                "org_sent": "First, they can learn how to clean.   ",
                "org_tokens": [
                  "First",
                  ",",
                  "they",
                  "can",
                  "learn",
                  "how",
                  "to",
                  "clean",
                  ".",
                  "  "
                ],
                "r_err": 0.0,
                "sent_for_SimilarityScorer": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 39,
                      "start": 0
                    }
                  ],
                  "text": "First, students can learn how to clean.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 4,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 15,
                      "head": 4,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 7,
                      "tag": "NNS"
                    },
                    {
                      "dep": "aux",
                      "end": 19,
                      "head": 4,
                      "id": 3,
                      "pos": "VERB",
                      "start": 16,
                      "tag": "MD"
                    },
                    {
                      "dep": "ROOT",
                      "end": 25,
                      "head": 4,
                      "id": 4,
                      "pos": "VERB",
                      "start": 20,
                      "tag": "VB"
                    },
                    {
                      "dep": "advmod",
                      "end": 29,
                      "head": 7,
                      "id": 5,
                      "pos": "ADV",
                      "start": 26,
                      "tag": "WRB"
                    },
                    {
                      "dep": "aux",
                      "end": 32,
                      "head": 7,
                      "id": 6,
                      "pos": "PART",
                      "start": 30,
                      "tag": "TO"
                    },
                    {
                      "dep": "xcomp",
                      "end": 38,
                      "head": 4,
                      "id": 7,
                      "pos": "VERB",
                      "start": 33,
                      "tag": "VB"
                    },
                    {
                      "dep": "punct",
                      "end": 39,
                      "head": 4,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 38,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 0,
                "sentence_w_verr": 0,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "R0",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              },
              {
                "both_err": [],
                "cor_sent": "They",
                "cor_tokens": [
                  "They"
                ],
                "coreference_sent": "Students",
                "edits": [],
                "err_ids": [],
                "error_span": 0,
                "error_types": [],
                "gpt_sentence_score": 0,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 1,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 0,
                "org_sent": "They",
                "org_tokens": [
                  "They"
                ],
                "r_err": 0.0,
                "sent_for_SimilarityScorer": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 8,
                      "start": 0
                    }
                  ],
                  "text": "Students",
                  "tokens": [
                    {
                      "dep": "ROOT",
                      "end": 8,
                      "head": 0,
                      "id": 0,
                      "pos": "NOUN",
                      "start": 0,
                      "tag": "NNS"
                    }
                  ]
                },
                "sentence_score": 0,
                "sentence_w_verr": 0,
                "simple_sent_1": 1,
                "simple_sent_2": 1,
                "tag": "R0",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              }
            ],
            "co_EX": "This skill help students to have clean house in the future.",
            "co_R": "First, students can learn how to clean. Students",
            "no_conj_EX": "This skill help them to have clean house in the future.",
            "no_conj_R": "They can learn how to clean."
          },
          "p1": {
            "EX": "They no they have how to clean.",
            "EX_cor_sent": "should know how to clean.",
            "EX_sent": [
              {
                "both_err": [],
                "cor_sent": "should know how to clean.",
                "cor_tokens": [
                  "should",
                  "know",
                  "how",
                  "to",
                  "clean",
                  "."
                ],
                "coreference_sent": "no students have how to clean.",
                "edits": [
                  "Orig: [46, 49, 'no they have'], Cor: [46, 48, 'should know'], Type: 'R:OTHER'",
                  "Orig: [53, 54, '  '], Cor: [52, 52, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  46,
                  47,
                  48,
                  53
                ],
                "error_span": 3,
                "error_types": [
                  "Orig: [46, 49, 'no they have'], Cor: [46, 48, 'should know'], Type: 'R:OTHER'",
                  "Orig: [53, 54, '  '], Cor: [52, 52, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 1,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 1,
                "is_repeated_sentence": true,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 1,
                "org_sent": "no they have how to clean.   ",
                "org_tokens": [
                  "no",
                  "they",
                  "have",
                  "how",
                  "to",
                  "clean",
                  ".",
                  "  "
                ],
                "r_err": 0.16666666666666666,
                "sent_for_SimilarityScorer": {
                  "ents": [],
                  "sents": [
                    {
                      "end": 30,
                      "start": 0
                    }
                  ],
                  "text": "no students have how to clean.",
                  "tokens": [
                    {
                      "dep": "det",
                      "end": 2,
                      "head": 1,
                      "id": 0,
                      "pos": "DET",
                      "start": 0,
                      "tag": "DT"
                    },
                    {
                      "dep": "nsubj",
                      "end": 11,
                      "head": 2,
                      "id": 1,
                      "pos": "NOUN",
                      "start": 3,
                      "tag": "NNS"
                    },
                    {
                      "dep": "ROOT",
                      "end": 16,
                      "head": 2,
                      "id": 2,
                      "pos": "AUX",
                      "start": 12,
                      "tag": "VBP"
                    },
                    {
                      "dep": "advmod",
                      "end": 20,
                      "head": 5,
                      "id": 3,
                      "pos": "ADV",
                      "start": 17,
                      "tag": "WRB"
                    },
                    {
                      "dep": "aux",
                      "end": 23,
                      "head": 5,
                      "id": 4,
                      "pos": "PART",
                      "start": 21,
                      "tag": "TO"
                    },
                    {
                      "dep": "xcomp",
                      "end": 29,
                      "head": 2,
                      "id": 5,
                      "pos": "VERB",
                      "start": 24,
                      "tag": "VB"
                    },
                    {
                      "dep": "punct",
                      "end": 30,
                      "head": 2,
                      "id": 6,
                      "pos": "PUNCT",
                      "start": 29,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 1,
                "sentence_w_verr": 0,
                "simple_sent_1": 0,
                "simple_sent_2": 1,
                "tag": "EX1",
                "vocabulary_err": [
                  "R:OTHER"
                ],
                "vocabulary_sentence_score": 1
              }
            ],
            "R": "Second, not school dirty.",
            "R_cor_sent": "Secondly, the school should not be dirty.",
            "R_sent": [
              {
                "both_err": [
                  "M:OTHER"
                ],
                "cor_sent": "Secondly, the school should not be dirty.",
                "cor_tokens": [
                  "Secondly",
                  ",",
                  "the",
                  "school",
                  "should",
                  "not",
                  "be",
                  "dirty",
                  "."
                ],
                "coreference_sent": "Second, not school dirty.",
                "edits": [
                  "Orig: [38, 39, 'Second'], Cor: [36, 37, 'Secondly'], Type: 'R:MORPH'",
                  "Orig: [40, 41, 'not'], Cor: [38, 39, 'the'], Type: 'R:OTHER'",
                  "Orig: [42, 42, ''], Cor: [40, 43, 'should not be'], Type: 'M:OTHER'",
                  "Orig: [44, 45, '  '], Cor: [45, 45, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  38,
                  40,
                  44
                ],
                "error_span": 2,
                "error_types": [
                  "Orig: [38, 39, 'Second'], Cor: [36, 37, 'Secondly'], Type: 'R:MORPH'",
                  "Orig: [40, 41, 'not'], Cor: [38, 39, 'the'], Type: 'R:OTHER'",
                  "Orig: [42, 42, ''], Cor: [40, 43, 'should not be'], Type: 'M:OTHER'",
                  "Orig: [44, 45, '  '], Cor: [45, 45, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 1,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [
                  "R:MORPH"
                ],
                "grammar_sentence_score": 2,
                "is_grammar_error_critical": 1,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 1,
                "n_err": 3,
                "org_sent": "Second, not school dirty.   ",
                "org_tokens": [
                  "Second",
                  ",",
                  "not",
                  "school",
                  "dirty",
                  ".",
                  "  "
                ],
                "r_err": 0.75,
                "sent_for_SimilarityScorer": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 25,
                      "start": 0
                    }
                  ],
                  "text": "Second, not school dirty.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 3,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 3,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "neg",
                      "end": 11,
                      "head": 3,
                      "id": 2,
                      "pos": "PART",
                      "start": 8,
                      "tag": "RB"
                    },
                    {
                      "dep": "compound",
                      "end": 18,
                      "head": 4,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 12,
                      "tag": "NN"
                    },
                    {
                      "dep": "ROOT",
                      "end": 24,
                      "head": 4,
                      "id": 4,
                      "pos": "ADJ",
                      "start": 19,
                      "tag": "JJ"
                    },
                    {
                      "dep": "punct",
                      "end": 25,
                      "head": 4,
                      "id": 5,
                      "pos": "PUNCT",
                      "start": 24,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 2,
                "sentence_w_verr": 0,
                "simple_sent_1": 1,
                "simple_sent_2": 1,
                "tag": "R1",
                "vocabulary_err": [
                  "R:OTHER"
                ],
                "vocabulary_sentence_score": 2
              }
            ],
            "co_EX": "no students have how to clean.",
            "co_R": "Second, not school dirty.",
            "no_conj_EX": "They no they have how to clean.",
            "no_conj_R": "Not school dirty."
          },
          "p2": {},
          "question_data": {
            "keywords": [
              ""
            ],
            "question_text": "Do you think it is a good idea to have students clean their schools?"
          }
        }
      }
    },
    'email':{
      "title": "A study trip",
      "question": {
        "grade": "G25",
        "type": "email",
        "e-mail": "I have to tell you some exciting news! This summer I'm going to go on a study abroad trip! I will be going to Madrid, Spain, for one month. I'm going with some classmates from my school, and we're going to study Spanish. I'm excited, but I'm also a bit nervous because this will be my first time visiting a foreign country. Do you think that students should have such experiences?",
        "topic_phrase": "a study abroad trip",
        "topic_char_start_index": 70,
        "topic_char_end_index": 89,
        "yes-no question": "Do you think that students should have such experiences?",
        "question_text": "a study abroad trip",
        "sender": {
            "name": "William",
            "gender": "male"
        },
        "greeting": "Hi William",
        "closing": "Cheers!"
      },
      "essay": 'I am so happy to learn about your abroad trip. And, yes, I think students should have such experiences. Because speak langues well when are talk native language speakers. I would like to know more about it. How long does it take? Where do you stay?',
      "feedback": {
        "coherence": null,
        "content": {
          "abnormal_response_score": null,
          "checklist_15": null,
          "checklist_16": null,
          "checklist_17": null,
          "len_filter_score": null,
          "non_english_score": null,
          "offtopic_score": null,
          "opinion_label": null,
          "p0_checklist_results": null,
          "p1_checklist_results": null,
          "s0": {
            "cor_sent": "And, yes, I think students should have such experiences.",
            "gpt_unintelligible_score": 0,
            "incomplete_score": 0,
            "org_sent": "And, yes, I think students should have such experiences.   \u00a0   ",
            "sent_len": 9,
            "sentence": "And, yes, I think students should have such experiences.",
            "sentence_score": 0,
            "task_completion_status": "Yes"
          },
          "s0_subtask_label": 0.0,
          "s1": {
            "cor_sent": "Because they speak languages well when they are talking to native language speakers.",
            "gpt_unintelligible_score": 1,
            "incomplete_score": 1,
            "org_sent": "Because speak langues well when are talk native language speakers.   \u00a0   ",
            "sent_len": 10,
            "sentence": "Because speak langues well when are talk native language speakers.",
            "sentence_score": 1,
            "task_completion_status": "No"
          },
          "s1_subtask_label": 1.0,
          "s2": {
            "cor_sent": "How long does it take?",
            "gpt_unintelligible_score": 0,
            "incomplete_score": 0,
            "org_sent": "How long does it take?",
            "sent_len": 5,
            "sentence": "How long does it take?",
            "sentence_score": 0,
            "task_completion_status": "Yes"
          },
          "s2_subtask_label": 0.0,
          "s3": {
            "cor_sent": "Where will you stay?",
            "gpt_unintelligible_score": 1,
            "incomplete_score": 0,
            "org_sent": "Where do you stay?",
            "sent_len": 4,
            "sentence": "Where do you stay?",
            "sentence_score": 1,
            "task_completion_status": "Yes"
          },
          "s3_subtask_label": 0.0,
          "sys_content": 3.0
        },
        "grammar_vocabulary": {
          "grammar_checklist_items": {
            "grammar_checklist_30": 0,
            "grammar_checklist_31": 0,
            "grammar_checklist_32": 1,
            "grammar_checklist_33": 0,
            "grammar_checklist_34": 0,
            "grammar_checklist_35": 0,
            "grammar_checklist_36": 0,
            "grammar_checklist_37": 0,
            "grammar_checklist_38": 0
          },
          "grammar_complexity_score": 2,
          "max_sent_len": 13,
          "mean_sent_len": 9.666666666666666,
          "n_orth_error": 0,
          "n_spell_error": 1,
          "numwds": 46,
          "sentences": [
            {
              "both_err": [],
              "cor_sent": "I am so happy to learn about your study abroad trip.",
              "err_ids": [
                11
              ],
              "error_types": [
                "Orig: [8, 8, ''], Cor: [8, 9, 'study'], Type: 'M:NOUN'",
                "Orig: [11, 12, '  \\xa0   '], Cor: [12, 12, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": 0,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "I am so happy to learn about your abroad trip.   \u00a0   ",
              "r_err": 0.1,
              "sentence_score": 0,
              "tag": "ELSE",
              "vocabulary_err": [
                "M:NOUN"
              ],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "And, yes, I think students should have such experiences.",
              "err_ids": [
                12
              ],
              "error_types": [
                "Orig: [12, 13, '  \\xa0   '], Cor: [12, 12, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": 0,
              "grammar_complexity_label": 1,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "And, yes, I think students should have such experiences.   \u00a0   ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "s0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "Because they speak languages well when they are talking to native language speakers.",
              "err_ids": [
                2,
                6,
                11
              ],
              "error_types": [
                "Orig: [1, 1, ''], Cor: [1, 2, 'they'], Type: 'M:PRON'",
                "Orig: [2, 3, 'langues'], Cor: [3, 4, 'languages'], Type: 'R:SPELL'",
                "Orig: [5, 5, ''], Cor: [6, 7, 'they'], Type: 'M:PRON'",
                "Orig: [6, 7, 'talk'], Cor: [8, 10, 'talking to'], Type: 'R:OTHER'",
                "Orig: [11, 12, '  \\xa0   '], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 2,
              "gpt_unintelligible_score": 1,
              "grammar_complexity_label": 1,
              "grammar_err": [
                "M:PRON",
                "M:PRON"
              ],
              "grammar_sentence_score": 2,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_vocabulary_error_critical": 1,
              "n_err": 3,
              "org_sent": "Because speak langues well when are talk native language speakers.   \u00a0   ",
              "r_err": 0.3,
              "sentence_score": 1,
              "tag": "s1",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 1
            },
            {
              "both_err": [],
              "cor_sent": "I would like to know more about it.",
              "err_ids": [
                9
              ],
              "error_types": [
                "Orig: [9, 10, '  \\xa0   '], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": 0,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "I would like to know more about it.   \u00a0   ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "ELSE",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "How long does it take?",
              "err_ids": [],
              "error_types": [],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": 0,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "How long does it take?",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "s2",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "Where will you stay?",
              "err_ids": [
                1
              ],
              "error_types": [
                "Orig: [1, 2, 'do'], Cor: [1, 2, 'will'], Type: 'R:VERB:TENSE'"
              ],
              "gpt_sentence_score": 2,
              "gpt_unintelligible_score": 1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:VERB:TENSE"
              ],
              "grammar_sentence_score": 2,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "Where do you stay?",
              "r_err": 0.25,
              "sentence_score": 1,
              "tag": "s3",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "sys_grammar": 2,
          "sys_grammar_max_sentence_score": 2,
          "sys_grammar_mean_sentence_score": 0.6666666666666666,
          "sys_vocabulary": 3,
          "sys_vocabulary_max_sentence_score": 1,
          "sys_vocabulary_mean_sentence_score": 0.16666666666666666,
          "vocabulary_checklist_items": {
            "vocabulary_checklist_30": 0,
            "vocabulary_checklist_31": 0,
            "vocabulary_checklist_32": 0,
            "vocabulary_checklist_33": 0,
            "vocabulary_checklist_34": 1,
            "vocabulary_checklist_35": 0,
            "vocabulary_checklist_36": 0,
            "vocabulary_checklist_37": 0,
            "vocabulary_checklist_38": 0
          },
          "vocabulary_complexity_score": 0.13636363636363635
        },
        "input": {
          "input_text": "I am so happy to learn about your abroad trip.   \u00a0   And, yes, I think students should have such experiences.   \u00a0   Because speak langues well when are talk native language speakers.   \u00a0   I would like to know more about it.   \u00a0   How long does it take? Where do you stay?",
          "question_data": {
            "closing": "Cheers!",
            "e-mail": "I have to tell you some exciting news! This summer I'm going to go on a study abroad trip! I will be going to Madrid, Spain, for one month. I'm going with some classmates from my school, and we're going to study Spanish. I'm excited, but I'm also a bit nervous because this will be my first time visiting a foreign country. Do you think that students should have such experiences?",
            "greeting": "Hi William",
            "question_text": "a study abroad trip",
            "sender": {
              "gender": "male",
              "name": "William"
            },
            "topic_char_end_index": 89,
            "topic_char_start_index": 70,
            "topic_phrase": "a study abroad trip",
            "yes-no question": "Do you think that students should have such experiences?"
          }
        }
      }
    }
  },
  "G30": {
    "opinion": {
      "title": "Best study location: personal preference reasons.",
      "question": {
        "grade": "G30",
        "type": "opinion",
        "question_text": "Where is the best place to study for you?",
        "keywords": [
          ""
        ]
      },
      "essay": 'Living room is the best place to study. I have two reasons. First, my mother teaches me study. Second, there is very quiet and I can relax.',
      "feedback": {
        "coherence": {
          "all_error_num": 0,
          "all_error_ratio": 0.0,
          "bf_error_pairs": [],
          "checklist_1": 0,
          "checklist_18": 0,
          "checklist_19": 0,
          "checklist_2": 0,
          "checklist_20": 0,
          "checklist_21": 0,
          "checklist_22": 0,
          "checklist_23": 0,
          "checklist_24": 0,
          "checklist_25": 0,
          "checklist_26": 0,
          "checklist_27": 0,
          "checklist_28": 0,
          "correct_sent_num": 4,
          "minimal_essay_score": 1,
          "num_wo_conj_sent": 2,
          "para_score": 0,
          "ratio_simple_conj": 1.0,
          "ratio_wo_conj_sent": 0.5,
          "sys_coherence": 4
        },
        "content": {
          "abnormal_response_score": null,
          "checklist_15": null,
          "checklist_16": null,
          "checklist_17": null,
          "len_filter_score": -1.0,
          "non_english_score": -1,
          "offtopic_score": -1,
          "opinion_label": 0,
          "p0_checklist_results": {
            "checklist_10": 0,
            "checklist_11": 0,
            "checklist_12": 0,
            "checklist_345": 0,
            "checklist_4": 0,
            "checklist_9": 0,
            "checklist_all": 0,
            "explanation_len": 6,
            "relevance_score": 1.0,
            "sent_feats": [
              1.0,
              1
            ]
          },
          "p1_checklist_results": {
            "checklist_10": 0,
            "checklist_11": 0,
            "checklist_12": 0,
            "checklist_345": 0,
            "checklist_4": 0,
            "checklist_9": 0,
            "checklist_all": 0,
            "explanation_len": 9,
            "relevance_score": 1.0,
            "sent_feats": [
              0.0,
              1
            ]
          },
          "sys_content": 4
        },
        "grammar_vocabulary": {
          "grammar_checklist_items": {
            "grammar_checklist_30": 0,
            "grammar_checklist_31": 0,
            "grammar_checklist_32": 0,
            "grammar_checklist_33": 0,
            "grammar_checklist_34": 1,
            "grammar_checklist_35": 0,
            "grammar_checklist_36": 0,
            "grammar_checklist_37": 0,
            "grammar_checklist_38": 0
          },
          "grammar_complexity_score": 0,
          "max_sent_len": 11,
          "mean_sent_len": 10.0,
          "n_orth_error": 0,
          "n_spell_error": 0,
          "numwds": 27,
          "sentences": [
            {
              "both_err": [],
              "cor_sent": "The living room is the best place to study.",
              "coreference_sent": "Living room is the best place to study.  ",
              "err_ids": [
                0,
                9
              ],
              "error_types": [
                "Orig: [0, 1, 'Living'], Cor: [0, 2, 'The living'], Type: 'M:DET'",
                "Orig: [9, 10, ' '], Cor: [10, 10, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "Living room is the best place to study.  ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "Opinion",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "I have two reasons.",
              "coreference_sent": "I have two reasons.  ",
              "err_ids": [
                5
              ],
              "error_types": [
                "Orig: [5, 6, ' '], Cor: [5, 5, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "I have two reasons.  ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "Template",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "First, my mother teaches me to study.",
              "coreference_sent": "First, my mother teaches me study.  ",
              "err_ids": [
                8
              ],
              "error_types": [
                "Orig: [6, 6, ''], Cor: [6, 7, 'to'], Type: 'M:VERB:FORM'",
                "Orig: [8, 9, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "M:VERB:FORM"
              ],
              "grammar_sentence_score": 1,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "First, my mother teaches me study.  ",
              "r_err": 0.16666666666666666,
              "sentence_score": 1,
              "tag": "R0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "Second, it is very quiet and I can relax.",
              "coreference_sent": "Second, living room is very quiet and I can relax.",
              "err_ids": [
                2
              ],
              "error_types": [
                "Orig: [2, 3, 'there'], Cor: [2, 3, 'it'], Type: 'R:PRON'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:PRON"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_repeated_sentence": false,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "Second, there is very quiet and I can relax.",
              "r_err": 0.1111111111111111,
              "sentence_score": 0,
              "tag": "R1",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "sys_grammar": 3,
          "sys_grammar_max_sentence_score": 1,
          "sys_grammar_mean_sentence_score": 0.3333333333333333,
          "sys_vocabulary": 4,
          "sys_vocabulary_max_sentence_score": 0,
          "sys_vocabulary_mean_sentence_score": 0.0,
          "vocabulary_checklist_items": {
            "vocabulary_checklist_30": 0,
            "vocabulary_checklist_31": 0,
            "vocabulary_checklist_32": 0,
            "vocabulary_checklist_33": 0,
            "vocabulary_checklist_34": 0,
            "vocabulary_checklist_35": 0,
            "vocabulary_checklist_36": 0,
            "vocabulary_checklist_37": 0,
            "vocabulary_checklist_38": 0
          },
          "vocabulary_complexity_score": 0.0
        },
        "input": {
          "conclusion": null,
          "conclusion_position": null,
          "input_text": "Living room is the best place to study.  I have two reasons.  First, my mother teaches me study.  Second, there is very quiet and I can relax. ",
          "opinion": "Living room is the best place to study.",
          "opinion_position": "living room",
          "p0": {
            "EX": "",
            "EX_cor_sent": "",
            "EX_sent": [],
            "R": "First, my mother teaches me study.",
            "R_cor_sent": "First, my mother teaches me to study.",
            "R_sent": [
              {
                "both_err": [],
                "cor_sent": "First, my mother teaches me to study.",
                "cor_tokens": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 37,
                      "start": 0
                    }
                  ],
                  "text": "First, my mother teaches me to study.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 4,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "poss",
                      "end": 9,
                      "head": 3,
                      "id": 2,
                      "pos": "DET",
                      "start": 7,
                      "tag": "PRP$"
                    },
                    {
                      "dep": "nsubj",
                      "end": 16,
                      "head": 4,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 10,
                      "tag": "NN"
                    },
                    {
                      "dep": "ROOT",
                      "end": 24,
                      "head": 4,
                      "id": 4,
                      "pos": "VERB",
                      "start": 17,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "dobj",
                      "end": 27,
                      "head": 4,
                      "id": 5,
                      "pos": "PRON",
                      "start": 25,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 30,
                      "head": 7,
                      "id": 6,
                      "pos": "PART",
                      "start": 28,
                      "tag": "TO"
                    },
                    {
                      "dep": "xcomp",
                      "end": 36,
                      "head": 4,
                      "id": 7,
                      "pos": "VERB",
                      "start": 31,
                      "tag": "VB"
                    },
                    {
                      "dep": "punct",
                      "end": 37,
                      "head": 4,
                      "id": 8,
                      "pos": "PUNCT",
                      "start": 36,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "First, my mother teaches me study.  ",
                "edits": [
                  "Orig: [6, 6, ''], Cor: [6, 7, 'to'], Type: 'M:VERB:FORM'",
                  "Orig: [8, 9, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
                ],
                "err_ids": [
                  8
                ],
                "error_span": 0,
                "error_types": [
                  "Orig: [6, 6, ''], Cor: [6, 7, 'to'], Type: 'M:VERB:FORM'",
                  "Orig: [8, 9, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
                ],
                "gpt_sentence_score": 1,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [
                  "M:VERB:FORM"
                ],
                "grammar_sentence_score": 1,
                "is_grammar_error_critical": 1,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 1,
                "org_sent": "First, my mother teaches me study.  ",
                "org_tokens": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 36,
                      "start": 0
                    }
                  ],
                  "text": "First, my mother teaches me study.  ",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 4,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "poss",
                      "end": 9,
                      "head": 3,
                      "id": 2,
                      "pos": "DET",
                      "start": 7,
                      "tag": "PRP$"
                    },
                    {
                      "dep": "nsubj",
                      "end": 16,
                      "head": 4,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 10,
                      "tag": "NN"
                    },
                    {
                      "dep": "ROOT",
                      "end": 24,
                      "head": 4,
                      "id": 4,
                      "pos": "VERB",
                      "start": 17,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "dative",
                      "end": 27,
                      "head": 4,
                      "id": 5,
                      "pos": "PRON",
                      "start": 25,
                      "tag": "PRP"
                    },
                    {
                      "dep": "dobj",
                      "end": 33,
                      "head": 4,
                      "id": 6,
                      "pos": "VERB",
                      "start": 28,
                      "tag": "VB"
                    },
                    {
                      "dep": "punct",
                      "end": 34,
                      "head": 4,
                      "id": 7,
                      "pos": "PUNCT",
                      "start": 33,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 36,
                      "head": 7,
                      "id": 8,
                      "pos": "SPACE",
                      "start": 35,
                      "tag": "_SP"
                    }
                  ]
                },
                "r_err": 0.16666666666666666,
                "sent_for_SimilarityScorer": {
                  "ents": [
                    {
                      "end": 5,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 36,
                      "start": 0
                    }
                  ],
                  "text": "First, my mother teaches me study.  ",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 5,
                      "head": 4,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 6,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 5,
                      "tag": ","
                    },
                    {
                      "dep": "poss",
                      "end": 9,
                      "head": 3,
                      "id": 2,
                      "pos": "DET",
                      "start": 7,
                      "tag": "PRP$"
                    },
                    {
                      "dep": "nsubj",
                      "end": 16,
                      "head": 4,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 10,
                      "tag": "NN"
                    },
                    {
                      "dep": "ROOT",
                      "end": 24,
                      "head": 4,
                      "id": 4,
                      "pos": "VERB",
                      "start": 17,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "dative",
                      "end": 27,
                      "head": 4,
                      "id": 5,
                      "pos": "PRON",
                      "start": 25,
                      "tag": "PRP"
                    },
                    {
                      "dep": "dobj",
                      "end": 33,
                      "head": 4,
                      "id": 6,
                      "pos": "VERB",
                      "start": 28,
                      "tag": "VB"
                    },
                    {
                      "dep": "punct",
                      "end": 34,
                      "head": 4,
                      "id": 7,
                      "pos": "PUNCT",
                      "start": 33,
                      "tag": "."
                    },
                    {
                      "dep": "",
                      "end": 36,
                      "head": 7,
                      "id": 8,
                      "pos": "SPACE",
                      "start": 35,
                      "tag": "_SP"
                    }
                  ]
                },
                "sentence_score": 1,
                "sentence_w_verr": 1,
                "simple_sent_1": 0,
                "simple_sent_2": 0,
                "tag": "R0",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              }
            ],
            "co_EX": "",
            "co_R": "First, my mother teaches me study.  ",
            "no_conj_EX": "",
            "no_conj_R": "My mother teaches me study."
          },
          "p1": {
            "EX": "",
            "EX_cor_sent": "",
            "EX_sent": [],
            "R": "Second, there is very quiet and I can relax.",
            "R_cor_sent": "Second, it is very quiet and I can relax.",
            "R_sent": [
              {
                "both_err": [],
                "cor_sent": "Second, it is very quiet and I can relax.",
                "cor_tokens": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 41,
                      "start": 0
                    }
                  ],
                  "text": "Second, it is very quiet and I can relax.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 3,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 3,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "nsubj",
                      "end": 10,
                      "head": 3,
                      "id": 2,
                      "pos": "PRON",
                      "start": 8,
                      "tag": "PRP"
                    },
                    {
                      "dep": "ROOT",
                      "end": 13,
                      "head": 3,
                      "id": 3,
                      "pos": "AUX",
                      "start": 11,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "advmod",
                      "end": 18,
                      "head": 5,
                      "id": 4,
                      "pos": "ADV",
                      "start": 14,
                      "tag": "RB"
                    },
                    {
                      "dep": "acomp",
                      "end": 24,
                      "head": 3,
                      "id": 5,
                      "pos": "ADJ",
                      "start": 19,
                      "tag": "JJ"
                    },
                    {
                      "dep": "cc",
                      "end": 28,
                      "head": 3,
                      "id": 6,
                      "pos": "CCONJ",
                      "start": 25,
                      "tag": "CC"
                    },
                    {
                      "dep": "nsubj",
                      "end": 30,
                      "head": 9,
                      "id": 7,
                      "pos": "PRON",
                      "start": 29,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 34,
                      "head": 9,
                      "id": 8,
                      "pos": "VERB",
                      "start": 31,
                      "tag": "MD"
                    },
                    {
                      "dep": "conj",
                      "end": 40,
                      "head": 3,
                      "id": 9,
                      "pos": "VERB",
                      "start": 35,
                      "tag": "VB"
                    },
                    {
                      "dep": "punct",
                      "end": 41,
                      "head": 9,
                      "id": 10,
                      "pos": "PUNCT",
                      "start": 40,
                      "tag": "."
                    }
                  ]
                },
                "coreference_sent": "Second, living room is very quiet and I can relax.",
                "edits": [
                  "Orig: [2, 3, 'there'], Cor: [2, 3, 'it'], Type: 'R:PRON'"
                ],
                "err_ids": [
                  2
                ],
                "error_span": 1,
                "error_types": [
                  "Orig: [2, 3, 'there'], Cor: [2, 3, 'it'], Type: 'R:PRON'"
                ],
                "gpt_sentence_score": 0,
                "gpt_unintelligible_score": -1,
                "grammar_complexity_label": 0,
                "grammar_err": [
                  "R:PRON"
                ],
                "grammar_sentence_score": 0,
                "is_grammar_error_critical": 0,
                "is_incomplete_sentence": 0,
                "is_repeated_sentence": false,
                "is_startwith_because": 0,
                "is_vocabulary_error_critical": 0,
                "n_err": 1,
                "org_sent": "Second, there is very quiet and I can relax.",
                "org_tokens": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 44,
                      "start": 0
                    }
                  ],
                  "text": "Second, there is very quiet and I can relax.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 3,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 3,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "expl",
                      "end": 13,
                      "head": 3,
                      "id": 2,
                      "pos": "PRON",
                      "start": 8,
                      "tag": "EX"
                    },
                    {
                      "dep": "ROOT",
                      "end": 16,
                      "head": 3,
                      "id": 3,
                      "pos": "AUX",
                      "start": 14,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "advmod",
                      "end": 21,
                      "head": 5,
                      "id": 4,
                      "pos": "ADV",
                      "start": 17,
                      "tag": "RB"
                    },
                    {
                      "dep": "acomp",
                      "end": 27,
                      "head": 3,
                      "id": 5,
                      "pos": "ADJ",
                      "start": 22,
                      "tag": "JJ"
                    },
                    {
                      "dep": "cc",
                      "end": 31,
                      "head": 3,
                      "id": 6,
                      "pos": "CCONJ",
                      "start": 28,
                      "tag": "CC"
                    },
                    {
                      "dep": "nsubj",
                      "end": 33,
                      "head": 9,
                      "id": 7,
                      "pos": "PRON",
                      "start": 32,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 37,
                      "head": 9,
                      "id": 8,
                      "pos": "VERB",
                      "start": 34,
                      "tag": "MD"
                    },
                    {
                      "dep": "conj",
                      "end": 43,
                      "head": 3,
                      "id": 9,
                      "pos": "VERB",
                      "start": 38,
                      "tag": "VB"
                    },
                    {
                      "dep": "punct",
                      "end": 44,
                      "head": 9,
                      "id": 10,
                      "pos": "PUNCT",
                      "start": 43,
                      "tag": "."
                    }
                  ]
                },
                "r_err": 0.1111111111111111,
                "sent_for_SimilarityScorer": {
                  "ents": [
                    {
                      "end": 6,
                      "label": "ORDINAL",
                      "start": 0
                    }
                  ],
                  "sents": [
                    {
                      "end": 50,
                      "start": 0
                    }
                  ],
                  "text": "Second, living room is very quiet and I can relax.",
                  "tokens": [
                    {
                      "dep": "advmod",
                      "end": 6,
                      "head": 4,
                      "id": 0,
                      "pos": "ADV",
                      "start": 0,
                      "tag": "RB"
                    },
                    {
                      "dep": "punct",
                      "end": 7,
                      "head": 4,
                      "id": 1,
                      "pos": "PUNCT",
                      "start": 6,
                      "tag": ","
                    },
                    {
                      "dep": "compound",
                      "end": 14,
                      "head": 3,
                      "id": 2,
                      "pos": "NOUN",
                      "start": 8,
                      "tag": "NN"
                    },
                    {
                      "dep": "nsubj",
                      "end": 19,
                      "head": 4,
                      "id": 3,
                      "pos": "NOUN",
                      "start": 15,
                      "tag": "NN"
                    },
                    {
                      "dep": "ROOT",
                      "end": 22,
                      "head": 4,
                      "id": 4,
                      "pos": "AUX",
                      "start": 20,
                      "tag": "VBZ"
                    },
                    {
                      "dep": "advmod",
                      "end": 27,
                      "head": 6,
                      "id": 5,
                      "pos": "ADV",
                      "start": 23,
                      "tag": "RB"
                    },
                    {
                      "dep": "acomp",
                      "end": 33,
                      "head": 4,
                      "id": 6,
                      "pos": "ADJ",
                      "start": 28,
                      "tag": "JJ"
                    },
                    {
                      "dep": "cc",
                      "end": 37,
                      "head": 4,
                      "id": 7,
                      "pos": "CCONJ",
                      "start": 34,
                      "tag": "CC"
                    },
                    {
                      "dep": "nsubj",
                      "end": 39,
                      "head": 10,
                      "id": 8,
                      "pos": "PRON",
                      "start": 38,
                      "tag": "PRP"
                    },
                    {
                      "dep": "aux",
                      "end": 43,
                      "head": 10,
                      "id": 9,
                      "pos": "VERB",
                      "start": 40,
                      "tag": "MD"
                    },
                    {
                      "dep": "conj",
                      "end": 49,
                      "head": 4,
                      "id": 10,
                      "pos": "VERB",
                      "start": 44,
                      "tag": "VB"
                    },
                    {
                      "dep": "punct",
                      "end": 50,
                      "head": 10,
                      "id": 11,
                      "pos": "PUNCT",
                      "start": 49,
                      "tag": "."
                    }
                  ]
                },
                "sentence_score": 0,
                "sentence_w_verr": 0,
                "simple_sent_1": 0,
                "simple_sent_2": 1,
                "tag": "R1",
                "vocabulary_err": [],
                "vocabulary_sentence_score": 0
              }
            ],
            "co_EX": "",
            "co_R": "Second, living room is very quiet and I can relax.",
            "no_conj_EX": "",
            "no_conj_R": "There is very quiet and I can relax."
          },
          "p2": {},
          "question_data": {
            "keywords": [
              ""
            ],
            "question_text": "Where is the best place to study for you?"
          }
        }
      }
    },
    "email": {
      "title": "Concert recommendation",
      "question": {
        "grade": "G30",
        "type": "email",
        "question_text": "concert",
        "e-mail": "Hi.\nThank you for your e-mail.\nI am interested in going to a concert. I know you like music, so I want some advice. What kind of concert should I go to? Also, how much does a ticket usually cost?",
        "wh-question_0": "What kind of concert should I go to?",
        "wh-question_1": "Also, how much does a ticket usually cost?",
        "sender": "James",
        "greeting": "Hi, James!",
        "closing": "Best wishes,",
        "keywords": [
          ""
        ]
      },
      "essay": 'I think you should go to a rock concert. The ticket is usually expensive. It may cost more than ten thousands yen.',
      "feedback": {
        "coherence": null,
        "content": {
          "abnormal_response_score": null,
          "checklist_15": null,
          "checklist_16": null,
          "checklist_17": null,
          "len_filter_score": null,
          "non_english_score": null,
          "offtopic_score": null,
          "opinion_label": null,
          "p0_checklist_results": null,
          "p1_checklist_results": null,
          "s0": {
            "cor_sent": "I think you should go to a rock concert.",
            "gpt_unintelligible_score": 0,
            "incomplete_score": 0,
            "org_sent": "I think you should go to a rock concert.  ",
            "sent_len": 9,
            "sentence": "I think you should go to a rock concert.",
            "sentence_score": 0,
            "task_completion_status": "Yes"
          },
          "s0_subtask_label": 0.0,
          "s1": {
            "cor_sent": "It may cost more than ten thousand yen.",
            "gpt_unintelligible_score": 0,
            "incomplete_score": 0,
            "org_sent": "It may cost more than ten thousands yen.",
            "sent_len": 8,
            "sentence": "It may cost more than ten thousands yen.",
            "sentence_score": 0,
            "task_completion_status": "Yes"
          },
          "s1_subtask_label": 0.0,
          "sys_content": 3
        },
        "grammar_vocabulary": {
          "grammar_checklist_items": {
            "grammar_checklist_30": 0,
            "grammar_checklist_31": 0,
            "grammar_checklist_32": 0,
            "grammar_checklist_33": 0,
            "grammar_checklist_34": 0,
            "grammar_checklist_35": 0,
            "grammar_checklist_36": 0,
            "grammar_checklist_37": 0,
            "grammar_checklist_38": 0
          },
          "grammar_complexity_score": 1,
          "max_sent_len": 11,
          "mean_sent_len": 9.0,
          "n_orth_error": 0,
          "n_spell_error": 0,
          "numwds": 22,
          "sentences": [
            {
              "both_err": [],
              "cor_sent": "I think you should go to a rock concert.",
              "err_ids": [
                10
              ],
              "error_types": [
                "Orig: [10, 11, ' '], Cor: [10, 10, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": 0,
              "grammar_complexity_label": 1,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "I think you should go to a rock concert.  ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "s0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "The ticket is usually expensive.",
              "err_ids": [
                6
              ],
              "error_types": [
                "Orig: [6, 7, ' '], Cor: [6, 6, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": 0,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "The ticket is usually expensive.  ",
              "r_err": 0.0,
              "sentence_score": 0,
              "tag": "ELSE",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "It may cost more than ten thousand yen.",
              "err_ids": [
                6
              ],
              "error_types": [
                "Orig: [6, 7, 'thousands'], Cor: [6, 7, 'thousand'], Type: 'R:MORPH'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": 0,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:MORPH"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "It may cost more than ten thousands yen.",
              "r_err": 0.125,
              "sentence_score": 0,
              "tag": "s1",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "sys_grammar": 3,
          "sys_grammar_max_sentence_score": 0,
          "sys_grammar_mean_sentence_score": 0.0,
          "sys_vocabulary": 3,
          "sys_vocabulary_max_sentence_score": 0,
          "sys_vocabulary_mean_sentence_score": 0.0,
          "vocabulary_checklist_items": {
            "vocabulary_checklist_30": 0,
            "vocabulary_checklist_31": 0,
            "vocabulary_checklist_32": 0,
            "vocabulary_checklist_33": 0,
            "vocabulary_checklist_34": 0,
            "vocabulary_checklist_35": 0,
            "vocabulary_checklist_36": 0,
            "vocabulary_checklist_37": 0,
            "vocabulary_checklist_38": 0
          },
          "vocabulary_complexity_score": 0.08333333333333333
        },
        "input": {
          "input_text": "I think you should go to a rock concert.  The ticket is usually expensive.  It may cost more than ten thousands yen. ",
          "question_data": {
            "closing": "Best wishes,",
            "e-mail": "Hi.\nThank you for your e-mail.\nI am interested in going to a concert. I know you like music, so I want some advice. What kind of concert should I go to? Also, how much does a ticket usually cost?",
            "greeting": "Hi, James!",
            "keywords": [
              ""
            ],
            "question_text": "concert",
            "sender": "James",
            "wh-question_0": "What kind of concert should I go to?",
            "wh-question_1": "Also, how much does a ticket usually cost?"
          }
        }
      }
    }
  },
}

export {
  Statement,
  SampleAssignments,
}
