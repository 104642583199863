import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";

import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeGTM } from './services/gtm';
import * as auth from './Auth/auth';

const getInitialUserId = async () => {
  try {
    const user = await auth.getCurrentUser();
    return user.username;
  } catch {
    return null;
  }
};

getInitialUserId().then(userId => {
  initializeGTM(userId);
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
