import React, { Component } from "react";
import {
  MDBIcon,
  MDBBadge,
  MDBBtn
} from 'mdb-react-ui-kit';

import DiffEx from './DiffEx';
import eventBus from "./EventBus";

import {OverlayTrigger, Popover} from 'react-bootstrap';
import './UguisTextEditor.css'
import { SubtaskData } from "./SubTaskData";
import { isMobile } from './WindowDimensions';

class UguisEditableSpan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: props.action,
      from: props.from,
      to: props.to,
      corrected: false,
    }
  }

  handleAccept = async(e) => {
    e.preventDefault()
    const to = this.state.to
    const from = this.state.from
    if (this.state.action == "added") {
      this.setState(prevState => ({
        ...prevState,
        action: null,
        from: to,
        to: to,
        corrected: true,
      }))
    } else if (this.state.action == 'removed') {
      this.setState(prevState => ({
        ...prevState,
        action: null,
        from: null,
        to: null,
        corrected: true,
      }))
    } else if (this.state.action == "replaced") {
      this.setState(prevState => ({
        ...prevState,
        action: null,
        from: to,
        to: to,
        corrected: true,
      }))
    }
    if (this.props.onCorrect) {
      this.props.onCorrect()
    }
  }

  handleReject = async(e) => {
    e.preventDefault()
    const to = this.state.to
    const from = this.state.from
    if (this.state.action == "added") {
      this.setState(prevState => ({
        ...prevState,
        action: null,
        from: null,
        to: null,
        corrected: true,
      }))
    } else if (this.state.action == 'removed') {
      this.setState(prevState => ({
        ...prevState,
        action: null,
        from: from,
        to: from,
        corrected: true,
      }))
    } else if (this.state.action == "replaced") {
      this.setState(prevState => ({
        ...prevState,
        action: null,
        from: from,
        to: from,
        corrected: true,
      }))
    }
    if (this.props.onCorrect) {
      this.props.onCorrect()
    }
  }

  RenderPopover() {
    return (
      <Popover className="correction-menu" style={{zIndex: 998}}>
        <Popover.Body>
          <MDBBtn color="success" className="me-2" rounded onClick={this.handleAccept}>修正</MDBBtn>
          <MDBBtn color="danger" rounded onClick={this.handleReject}>却下</MDBBtn>
        </Popover.Body>
      </Popover>
    );
  }

  renderText(text, color, style, placeholder) {
    if (!text) {
      // missing tags
      return <span suppressContentEditableWarning={true} className={`texteditor essay ${placeholder ? 'missing' : ''} ${color}`} style={style} data-placeholder={placeholder}/>
    }
    const splitted = text.split('\n')
    return (
      splitted.map((s, i) => {
        const spanElement = <span suppressContentEditableWarning={true} key={`text-${i}`} className='texteditor essay' style={style}>{s}</span>
        let eolElement = null;
        if (i > 0) {
          eolElement = <br key={`breakline-${i}`} className="essay"/>
        }
        return (
          <>
          {eolElement}
          {spanElement}
          </>
        )
      })
    )
  }

  render() {
    if (this.state.action == null) {
      return this.renderText(this.state.from, 'text-dark', {color: 'black'}, this.props.placeholder)
    } else if (this.state.action == 'added') {
      return (
        <OverlayTrigger trigger={this.state.corrected ? 'none' : 'click'} rootClose="true" placement="top" overlay={this.RenderPopover()}>
          <ruby>
            <MDBIcon fas icon="angle-down mx-2 text-success"/>
            <span suppressContentEditableWarning={true} className="texteditor essay"/>
            <rt>
              <span contentEditable="false" className="texteditor noessay text-success">{this.state.to}</span>
            </rt>
          </ruby>
        </OverlayTrigger>
      )
    } else if (this.state.action == 'removed') {
      return (
        <OverlayTrigger trigger={this.state.corrected ? 'none' : 'click'} rootClose="true" placement="top" overlay={this.RenderPopover()}>
          <span className="texteditor essay text-danger" style={{'textDecoration':'line-through'}}>{this.state.from}</span>
        </OverlayTrigger>
      )
    } else if (this.state.action == 'replaced') {
      return (
        <OverlayTrigger trigger={this.state.corrected ? 'none' : 'click'} rootClose="true" placement="top" overlay={this.RenderPopover()}>
          <ruby>
            {this.renderText(this.state.from, 'color-danger', {'textDecoration':'line-through'})}
            <rt>
              <span className="noessay text-success" contentEditable="false" style={{"fontFamily": "monospace"}}>{this.state.to}</span>
            </rt>
          </ruby>
        </OverlayTrigger>
      )
    }
  }

}

class UguisTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupedSentences: null,
      selectedTag: null,
      hoveredTag: null,
      changed: false,
    };
  }

  updateFeedback() {
    this.feedback = this.props.feedback
    if (this.feedback != null){
      const sentences = this.feedback.grammar_vocabulary.sentences

      if (sentences) {
        sentences.forEach((sentence, i) => sentence.id = i)
        const groupedSentences = sentences.reduce(function(prev, curr) {
          if (prev.length && curr.tag === prev[prev.length - 1][0].tag) {
            prev[prev.length - 1].push(curr);
          }
          else {
            prev.push([curr]);
          }
          return prev;
        }, []);

        this.pushMissingTags(groupedSentences, this.feedback)

        this.setState(prevState => ({
          ...prevState,
          groupedSentences: groupedSentences,
        }))
      }
      else {
        const groupedSentences = [[{org_sent: this.props.essay, cor_sent:""}]]
        this.setState(prevState => ({
          ...prevState,
          groupedSentences: groupedSentences,
        }))
      }
    }
  }

  pushMissingTags(groupedSentences, feedback) {
    function findMissingTagPositionFromOrders(tag, tagOrder) {
      let index = tagOrder.indexOf(tag)
      if (index < 0) {
        // error: no tag in order
        return -1
      }
      return findMissingTagPosition(tag, tagOrder.slice(0, index), tagOrder.slice(index+1))
    }
    function findMissingTagPosition(tag, behindTags, infrontTags) {
      var index = groupedSentences.findLastIndex((group) => tag == group[0].tag)
      if (index >= 0) {
        // tag is not missing
        return -1
      }
      // try find the first position after behindTags
      index = groupedSentences.findLastIndex((group) => behindTags.includes(group[0].tag))
      if (index >= 0) {
        // found the position
        return index + 1
      }
      // get the position before infrontTags
      index = groupedSentences.findIndex((group) => infrontTags.includes(group[0].tag))
      if (index >= 0) {
        return index
      }
      // cannot find any, just return the first position
      return 0
    }

    let opinionTagOrder = [
      'Opinion', 'Intro', 'Template',
      'R0', 'missing_p0', 'EX0', 'missing_ex0',
      'R1', 'missing_p1', 'EX1', 'missing_ex1', 'Conclusion']
    let emailTagOrder = [
      'Opinion', 'Intro', 'Template',
      's0', 'missing_s0', 'EX0',
      's1', 'missing_s1', 'EX1',
      's2', 'missing_s2', 's3', 'missing_s3', 'Conclusion']
    function addMissingTag(tagOrder, tag, missingTag) {
      // console.log('addMissingTag', tag, tagOrder, missingTag)
      let index = findMissingTagPositionFromOrders(tag, tagOrder)
      if (index >= 0) {
        groupedSentences.splice(index, 0, [{'tag': missingTag}])
      }
    }

    let missingTagTemplate = [
      [feedback.content.p0_checklist_results?.checklist_4 == 1, opinionTagOrder, 'R0', 'missing_p0'],
      [feedback.content.p0_checklist_results?.checklist_345 == 1, opinionTagOrder, 'EX0', 'missing_ex0'],
      [feedback.content.p1_checklist_results?.checklist_4 == 1, opinionTagOrder, 'R1', 'missing_p1'],
      [feedback.content.p1_checklist_results?.checklist_345 == 1, opinionTagOrder, 'EX1', 'missing_ex1'],
      [feedback.content.s0_subtask_label >= 1, emailTagOrder, 's0', 'missing_s0'],
      [feedback.content.s1_subtask_label >= 1, emailTagOrder, 's1', 'missing_s1'],
      [feedback.content.s2_subtask_label >= 1, emailTagOrder, 's2', 'missing_s2'],
      [feedback.content.s3_subtask_label >= 1, emailTagOrder, 's3', 'missing_s3'],
    ]

    missingTagTemplate.reverse().forEach(template => {
      if (template[0]) {
        addMissingTag(template[1], template[2], template[3])
      }
    })
  }

  getTagView(tag) {
    let config = {
      "Opinion": {"text":"意見", "color":"success"},
      "Intro": {"text":"リード文", "color":"primary"},
      "Template": {"text":"リード文の続き", "color":"primary"},
      "R0": {"text":"１つ目の理由", "color":"success"},
      "R1": {"text":"２つ目の理由", "color":"success"},
      "EX0": {"text":"さらなる説明", "color":"primary"},
      "EX1": {"text":"さらなる説明", "color":"primary"},
      "ELSE": {"text":"その他の説明", "color":"secondary"},
      "Conclusion": {"text":"結論", "color":"success"},
      "missing_p0": {"text":"１つ目の理由", "color":"danger"},
      "missing_p1": {"text":"２つ目の理由", "color":"danger"},
      "missing_ex0": {"text":"１つ目の理由の説明", "color":"danger"},
      "missing_ex1": {"text":"２つ目の理由の説明", "color":"danger"},
    }
    if (this.props.question_data.type == 'email'){
      const subtask_data_config = SubtaskData[this.props.question_data.grade][this.props.question_data.type]['config']
      Object.assign(config, subtask_data_config)
    }
    if (!(tag in config)) {
      return null
    }
    let text = config[tag]["text"]
    let color = config[tag]["color"]
    return (
      <MDBBadge color={color} light className="h5 noessay" style={{fontFamily: "monospace", position:'absolute', opacity:0.75, top:35, left:-5}} contentEditable="false">{text}</MDBBadge>
    )
  }

  tagOnClick = (e) => {
    e.preventDefault();
    const tag = e.currentTarget.getAttribute('tag')
    const card = { type: 'tag', id: tag }
    eventBus.storage.selectedCard = card
    eventBus.dispatch("selectEditor", card);
    this.setState(prevState => ({
      ...prevState,
      selectedCard: card,
    }))
  }

  tagOnMouseOver = (e) => {
    e.preventDefault();
    const tag = e.currentTarget.getAttribute('tag')
    eventBus.dispatch("hoverEditor", { type: 'tag', id: tag });
  }

  tagOnMouseOut = (e) => {
    e.preventDefault();
    const tag = e.currentTarget.getAttribute('tag')
    eventBus.dispatch("unhoverEditor", { type: 'tag', id: tag });
  }

  tagHasError(tag) {
    if (tag == 'Intro' || tag == 'Opinion') {
      return (
        this.feedback.content.checklist_15 ||
        this.feedback.content.opinion_label
      )
    }
    else if (tag == 'R0') {
      return (
        this.feedback.content.p0_checklist_results.checklist_4 ||
        this.feedback.content.p0_checklist_results.checklist_9 ||
        this.feedback.content.p0_checklist_results.checklist_10 ||
        this.feedback.content.p0_checklist_results.checklist_11 ||
        this.feedback.content.p0_checklist_results.checklist_12 ||
        this.feedback.content.p0_checklist_results.task_completion_status ||
        this.feedback.content.p0_checklist_results.incomplete_score == 1 ||
        this.feedback.content.p0_checklist_results.sentence_score == 2
      )
    }
    else if (tag == 'EX0') {
      return (this.feedback.content.p0_checklist_results.checklist_345)
    }
    else if (tag == 'R1') {
      return (
        this.feedback.content.p1_checklist_results.checklist_4 ||
        this.feedback.content.p1_checklist_results.checklist_9 ||
        this.feedback.content.p1_checklist_results.checklist_10 ||
        this.feedback.content.p1_checklist_results.checklist_11 ||
        this.feedback.content.p1_checklist_results.checklist_12 ||
        this.feedback.content.p1_checklist_results.task_completion_status ||
        this.feedback.content.p1_checklist_results.incomplete_score == 1 ||
        this.feedback.content.p1_checklist_results.sentence_score == 2
      )
    }
    else if (tag == 'EX1') {
      return (this.feedback.content.p1_checklist_results.checklist_345)
    }
    else if (tag == "s0") {
      return (
        this.feedback.content.s0_subtask_label >= 0.5
      )
    }
    else if (tag == "s1") {
      return (
        this.feedback.content.s1_subtask_label >= 0.5
      )
    }
    else if (tag == "s2") {
      return (
        this.feedback.content.s2_subtask_label >= 0.5
      )
    }
    else if (tag == "s3") {
      return (
        this.feedback.content.s3_subtask_label >= 0.5
      )
    }
    else if (tag == "Conclusion") {
      return (
        this.feedback.content.checklist_16 ||
        this.feedback.content.checklist_17
      )
    }
    return false
  }

  sentenceOnClick = (e) => {
    e.preventDefault();
    const sentenceId = e.currentTarget.getAttribute('sentence')
    // console.log('onclick sentence', sentenceId)
    const card = { type: 'sentence', id: sentenceId }
    eventBus.storage.selectedCard = card
    eventBus.dispatch("selectEditor", card);
    this.setState(prevState => ({
      ...prevState,
      selectedCard: card,
    }))
  }

  sentenceOnMouseOver = (e) => {
    e.preventDefault();
    const sentenceId = e.currentTarget.getAttribute('sentence')
    eventBus.dispatch("hoverEditor", { type: 'sentence', id: sentenceId });
  }

  sentenceOnMouseOut = (e) => {
    e.preventDefault();
    const sentenceId = e.currentTarget.getAttribute('sentence')
    eventBus.dispatch("unhoverEditor", { type: 'sentence', id: sentenceId });
  }

  sentenceHasError(sentenceId) {
    if (!this.feedback.grammar_vocabulary.sentences) return false
    const sentence = this.feedback.grammar_vocabulary.sentences[sentenceId]
    if (!sentence) return false
    return sentence.grammar_err.length > 0 || sentence.vocabulary_err.length > 0
  }

  sentenceHasImportantError(sentenceId) {
    const sentence = this.feedback.grammar_vocabulary.sentences[sentenceId]
    if (!sentence) return false
    return sentence.is_grammar_error_critical === 1 || sentence.is_vocabulary_error_critical === 1  }

  getSentenceView(sentence) {
    let clickable = ''
    let important = ''
    let sentenceOnClick = null
    let sentenceOnMouseOver = null
    let sentenceOnMouseOut = null
    let selectedStyle = this.isSentenceSelected(sentence.id) ? 'selected' : ''
    let hoverStyle = this.isSentenceHovered(sentence.id) ? 'hovered' : ''
    if (this.sentenceHasError(sentence.id)) {
      if (this.sentenceHasImportantError(sentence.id)) {
        important = 'important'
      }
      clickable = 'clickable'
      sentenceOnClick = this.sentenceOnClick
      sentenceOnMouseOver = this.sentenceOnMouseOver
      sentenceOnMouseOut = this.sentenceOnMouseOut
    }

    let placeholders = {
      'missing_p0': '１つ目の理由がありません',
      'missing_p1': '２つ目の理由がありません',
      'missing_ex0': '１つ目の理由の説明がありません',
      'missing_ex1': '２つ目の理由の説明がありません',
      'missing_s0': '１つ目の質問に答えていません。',
      'missing_s1': '２つ目の質問に答えていません。',
    }
    if (this.props.question_data.type == 'email') {
      const subtask_data_placeholder = SubtaskData[this.props.question_data.grade][this.props.question_data.type]['placeholder']
      Object.assign(placeholders, subtask_data_placeholder)
    }
    if (sentence.tag in placeholders) {
      return (
        <UguisEditableSpan placeholder={placeholders[sentence.tag]}/>
      )
    }
    else {
      let org_txt = sentence.org_sent;
      let cor_txt = sentence.cor_sent;
      const diffs = DiffEx.diff(org_txt, cor_txt);
      const diffElements = diffs.map((part) => {
        return (
          <UguisEditableSpan key={`editable-span-${sentence.id}`} action={part.action} from={part.from} to={part.to} onCorrect={this.handleInputChange}/>
        );
      })
      const gapElement = <UguisEditableSpan key={`gap-${sentence.id}`} from=' '/>
      return (
        <>
          <span id={`sentence-${sentence.id}`} key={`sentence-${sentence.id}`} className={`${important} ${clickable} ${selectedStyle} ${hoverStyle}`} onClick={sentenceOnClick} onMouseOver={sentenceOnMouseOver} onMouseOut={sentenceOnMouseOut} sentence={sentence.id}>
            {diffElements}
          </span>
          {gapElement}
        </>
      )
    }
  }

  onUnload = (e) => {
    if (this.state.changed) {
      e.preventDefault();
      e.returnValue = true;
    }
  }

  handleInputChange = () => {
    this.setState(prevState => ({
      ...prevState,
      changed: true,
    }))
    if (this.props.onEssayChange) {
      this.props.onEssayChange(true)
    }
  }

  scrollEditorIntoView(card) {
    let element;
    if (card.type == 'sentence') {
      element = document.getElementById(`sentence-${card.id}`);
    }
    else if (card.type == 'tag') {
      element = document.getElementById(`text-group-${card.id}`);
    }
    if (element) {
      if (isMobile()) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});
      }
      else {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start'});
      }
    }
  }

  componentDidMount() {
    document.getElementById("texteditor").focus({ preventScroll: true })
    window.addEventListener("beforeunload", this.onUnload);
    eventBus.on("selectCard", (data) =>
      {
        this.setState(prevState => ({
          ...prevState,
          selectedCard: data,
        }))
        this.scrollEditorIntoView(data)
      }
    );
    eventBus.on("hoverCard", (data) =>
      {
        this.setState(prevState => ({
          ...prevState,
          hoveredCard: data,
        }))
        this.scrollEditorIntoView(data)
    }
    );
    eventBus.on("unhoverCard", (data) =>
      {
        // console.log('unhover')
        this.setState(prevState => ({
          ...prevState,
          hoveredCard: null,
        }))
      }
    );
    this.updateFeedback()
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
    eventBus.remove("selectCard");
    eventBus.remove("hoverCard");
    eventBus.remove("unhoverCard");
  }

  componentDidUpdate(prevProps) {
    if (this.props.keyboardOff == false) {
      const editor = document.getElementById("texteditor")
      window.setTimeout(() => editor.focus(), 0);
    }
  }

  isTag(card, tag) {
    return card != null && card.type == 'tag' && card.id == tag
  }

  isTagSelected(tag) {
    return this.isTag(this.state.selectedCard, tag)
  }

  isTagHovered(tag) {
    return this.isTag(this.state.hoveredCard, tag)
  }

  isSentence(card, sentence) {
    return card != null && card.type == 'sentence' && card.id == sentence
  }

  isSentenceSelected(sentence) {
    return this.isSentence(this.state.selectedCard, sentence)
  }

  isSentenceHovered(sentence) {
    return this.isSentence(this.state.hoveredCard, sentence)
  }

  getTextGroupView(sentences) {
    let clickable = ''
    let tagOnClick = null
    let tagOnMouseOver = null
    let tagOnMouseOut = null
    let selectedStyle = this.isTagSelected(sentences[0].tag) ? 'selected' : ''
    let hoverStyle = this.isTagHovered(sentences[0].tag) ? 'hovered' : ''
    if (this.tagHasError(sentences[0].tag)) {
      clickable = 'clickable important'
      tagOnClick = this.tagOnClick
      tagOnMouseOver = this.tagOnMouseOver
      tagOnMouseOut = this.tagOnMouseOut
    }

    return (
      <span id={`text-group-${sentences[0].tag}`} suppressContentEditableWarning={true} key={`text-group-${sentences[0].tag}`} className={`${clickable} ${selectedStyle} ${hoverStyle}`} onClick={tagOnClick} onMouseOver={tagOnMouseOver} onMouseOut={tagOnMouseOut} tag={sentences[0].tag} style={{'position':'relative', 'lineHeight': '3.5em', 'display': 'inline-block'}}>
      {this.getTagView(sentences[0].tag)}
      {sentences.map((sentence, i) => {
        return this.getSentenceView(sentence)
      })}
      </span>
    )
  }

  traverseElement(element, filter, func) {
    if (!filter(element)) {
      return
    }
    func(element)
    element.childNodes?.forEach(node => this.traverseElement(node, filter, func))
  }

  getInputValue() {
    var text = ''
    const editor = document.getElementById("texteditor")
    this.traverseElement(editor, (e) => {
      if (e.classList) {
        return !e.classList.contains('noessay')
      }
      return true
    }, (e) => {
      // console.log('essay tag', e.tagName)
      if (e.tagName == 'DIV' || e.tagName == 'BR') {
        text += '\n'
      }
      else if (e.nodeType == e.TEXT_NODE) {
        text += e.textContent
      }
    })
    return text.trim()
  }

  render() {
    return (
      <div id="texteditor" className="lh-lg texteditor" contentEditable="true" suppressContentEditableWarning={true} spellCheck={false} inputMode={this.props.keyboardOff == true ? 'none' : 'text'} placeholder={this.props.placeholder} onInput={this.handleInputChange} style={{IMEMode: "inactive"}}>
      {this.state.groupedSentences &&
        this.state.groupedSentences.map(group => {
          return this.getTextGroupView(group)
        })}
      </div>
    );
  }
}

export default UguisTextEditor;
