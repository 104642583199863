import { Statement } from './SampleData';
import { MDBIcon } from 'mdb-react-ui-kit';
import { Container, Row, Col, OverlayTrigger, Card, Tooltip, Stack, Alert } from 'react-bootstrap';
import { useRef, useState } from 'react';
import parse from 'html-react-parser';

function EMailAssignment(props) {
  const question = props.assignment.question

  let statement_data = Statement[question.grade]['email']

  let instruction_list = statement_data['statement'].map((statement, i) => {
    return <li key={i}>{parse(statement)}</li>
  })

  let friends_email = question.email
  if (question.topic_char_start_index != null && question.topic_char_end_index != null) {
    friends_email = (
      friends_email.substr(0, question.topic_char_start_index)
      + '<u>'
      + friends_email.substr(question.topic_char_start_index, question.topic_char_end_index - question.topic_char_start_index)
      + '</u>'
      + friends_email.substr(question.topic_char_end_index)
    )
  }
  friends_email = friends_email.split('\n').map((line, i) => {
    return <div>{parse(line)}</div>
  })

  let question_section = null
  if (question.grade == 'G30') {
    question_section = <div>
      質問
      <ul>
        <li>{question.wh_question0}</li>
        <li>{question.wh_question1}</li>
      </ul>
    </div>
  }

  return (
    <Container>
      <Row className="mb-3">
        <Card>
          <Card.Header className="fw-bold fw-bold px-md-4 px-1">
            <MDBIcon fas icon="question-circle me-2 align-middle"/>
            <span className="align-middle fw-bold">設問</span>
          </Card.Header>
          <Card.Body className="p-md-4 px-1 py-3 lh-lg">
            <div>
              <ul>
                {instruction_list}
              </ul>
            </div>
            {question_section}
          </Card.Body>
        </Card>
      </Row>
      <Row className="mt-3">
        <Card>
          <Card.Header className="fw-bold fw-bold px-md-4 px-1">
            <MDBIcon far icon="envelope me-2 align-middle"/>
            <span className="align-middle">{props.assignment.title}</span>
          </Card.Header>
          <Card.Body className="p-md-4 px-1 py-2">
            <Card.Title>
              <img src="male.png" height="30" alt="avatar" loading="lazy" className='rounded-circle me-2'/>
              <span className="h6">{props.assignment.question.sender.name}</span>
            </Card.Title>
            <div className="email ms-1">
              {friends_email}
              <div>Your friend,</div>
              <div>{props.assignment.question.sender.name}</div>
            </div>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  )
}

export default EMailAssignment;
