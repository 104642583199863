import OpinionAssignment from './OpinionAssignment';
import EMailAssignment from './EMailAssignment';

const maxLengths = {
  "G15": {"opinion": 1050},
  "G20": {"opnion": 700},
  "G25": {"opnion": 420},
  "G30": {"opnion": 245},
  "G30": {"email": 245},
}

function Assignment(props) {
  if (props.assignment == null) {
    return null;
  }
  if (props.assignment.question.type == 'opinion') {
    return (
      <OpinionAssignment assignment={props.assignment}
        maxLength={maxLengths[props.assignment.question.grade][props.assignment.question.type]}/>
    )
  } else if (props.assignment.question.type == 'email'){
    return (
      <EMailAssignment assignment={props.assignment}
        maxLength={maxLengths[props.assignment.question.grade][props.assignment.question.type]}
        onScore={props.onScore}/>
    )
  }
}

export default Assignment;
