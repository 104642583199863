import './App.css';

import {
  MDBBtn,
  MDBSpinner,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBRow,
  MDBCol,
  MDBContainer,
}
from 'mdb-react-ui-kit';
import React from "react";
import { AuthContext } from "../Auth/AuthContext"
import { Container, Stack, Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { SampleAssignments } from './SampleData';
import AssignmentView from './Assignment';
import {VerticalFeedback, HorizontalFeedback} from './Feedback'
import { Assignment, Question } from "./model/Assignment";
import EikenLogo from "../EikenLogo"
import * as api from '../api'
import TopicData from "../TopicData"
import EssayView from "./EssayView"
import Dialog from 'react-bootstrap-dialog'
import { withRouter } from './withRouter';
import EikenTrademarkNote from '../EikenTrademarkNote';
import { isMobile } from './WindowDimensions';
import {Helmet} from "react-helmet";
import * as gtm from '../services/gtm'
import dayjs from 'dayjs';

Array.prototype.random = function () {
  return this[Math.floor((Math.random()*this.length))];
}

class AssignmentContent extends React.Component {
  static contextType = AuthContext;
  dialog;

  constructor(props) {
    super(props);

    this.essayRef = React.createRef()

    this.state = {
      assignment: null,
      assignment_id: null,
      loading: true,
      isSample: true,
      feedbackSeed: 1, // seed to refresh feedback view entirely
      essaySeed: 2, // seed to refresh feedback view in email assignment
      scoring: false,
      mode: 'keyboard',
    }
  }

  getTypeText(type) {
    // TODO: resource file
    const type_expression_dict = {
      "opinion": '意見論述問題',
      "email": 'Eメール問題',
    }
    return type_expression_dict[type]
  }

  componentDidMount() {
    const assignment_id = this.props.assignment_id
    const grade = this.props.grade
    const type = this.props.type
    const isSample = this.props.isSample
    this.load(assignment_id, grade, type, isSample)
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }

  async load(assignment_id, grade, type, isSample) {
    this.setState(prevState => ({
      ...prevState,
      assignment_id: assignment_id,
      assignment: null,
      loading: true,
      mode: 'keyboard',
    }))
    let topic = this.props.topic
    // console.log('componentDidMount', 'assignment_id', assignment_id, 'grade', grade, 'type', type)
    if (assignment_id) {
      this.setState(prevState => ({
        ...prevState,
        isSample: false,
      }));
      const assignment = await api.getUserAssignment(assignment_id)
      this.setState(prevState => ({
        ...prevState,
        assignment: assignment.assignment,
        mode: 'feedback',
        loading: false
      }))
    }
    else if (isSample) {
      const assignment = Assignment.fromJSON(SampleAssignments[grade][type])
      this.sampleEssay = assignment.essay
      this.sampleFeedback = assignment.feedback
      assignment.feedback = null
      assignment.essay = null
      // console.log('componentDidMount assignment', assignment)
      this.setState(prevState => ({
        ...prevState,
        assignment: assignment,
        isSample: true,
        mode: 'keyboard',
        loading: false,
      }))
    }
    else if (grade && type) {
      const topics = TopicData[grade][type]
      if (!topic) {
        topic = topics.random().id
      }

      const target_topic = topics.find(candidate => candidate.id === topic);
      const subtopic = target_topic.subTopics.random();
      const startTime = dayjs();
      const generated_question = api.generateQuestion(grade, type, subtopic);
      generated_question.then(qinfo => {
        const endTime = dayjs();
        const timeTaken = endTime.diff(startTime);

        qinfo['grade'] = grade;
        qinfo['type'] = type;
        const question = Question.fromJSON(qinfo);
        const assignment = new Assignment(
          qinfo.question_text,
          question);
        this.setState(prevState => ({
          ...prevState,
          assignment: assignment,
          loading: false,
          isSample: false,
          mode: 'keyboard',
        }));

        gtm.sendGenerationQuestionEvent(grade, type, subtopic, timeTaken);
      });
    }
  }

  handleShowSample = async(e) => {
    await this.setStateAsync(prevState => ({
      ...prevState,
      assignment: {
        ...prevState.assignment,
        essay:this.sampleEssay,
        feedback:this.sampleFeedback,
      }
    }))
    this.forceUpdateFeedback()
    this.setState(prevState => ({
      ...prevState,
    }))

  }


  componentDidUpdate(prevProps) {
    if (prevProps.assignment_id !== this.props.assignment_id) {
      if (this.props.assignment_id) {
        this.load(this.props.assignment_id)
      }
    }
  }

  async forceUpdateFeedback() {
    await this.setStateAsync(prev => ({...prev,
      feedbackSeed: Math.random(),
      essaySeed: Math.random()
    }))
  }

  showSaveDialog(action) {
    if (!this.essayRef.current?.isEssaySaved()) {
      this.dialog.show({
        title: '解答を保存して移動しますか？',
        actions: [
          Dialog.Action(
            'キャンセル',
            () => this.dialog.hide(),
            'btn-secondary'
          ),
          Dialog.Action(
            '保存せずに移動',
            async () => {
              action();
            },
            'btn-danger'
          ),
          Dialog.DefaultAction(
            '保存してから移動',
            async () => {
              await this.score()
              action();
            },
            'btn-success'
          )
        ],
        bsSize: 'small',
        onHide: (dialog) => {
          dialog.hide()
        }
      })
    }
    else {
      action()
    }
  }

  newQuestion() {
    const grade = this.state.assignment.question.grade
    const type = this.state.assignment.question.type
    this.props.navigate(`/english?grade=${grade}&type=${type}`);
    this.load(null, grade, type, false)
  }

  handleNewAssignment = async (e) => {
    if (this.state.isSample && this.state.assignment.feedback != null) {
      this.props.navigate('/signUp');
      return;
    }

    let actions = [
      Dialog.Action(
        'キャンセル',
        () => this.dialog.hide(),
        'btn-secondary'
      )
    ]
    let body;
    if (!this.essayRef.current?.isEssaySaved()) {
      body = '今の解答を保存してから別の問題をAI自動生成します。'
      actions.push(Dialog.Action(
        '保存せずに問題を生成',
        async () => {
          this.newQuestion()
        },
        'btn-danger'
      ),
      Dialog.DefaultAction(
        '保存して問題を生成',
        async () => {
          await this.score()
          this.newQuestion()
        },
        'btn-success'
      ))
    }
    else {
      body = '別の問題をAI自動生成します。'
      actions.push(Dialog.DefaultAction(
        '問題を生成',
        async () => {
          this.newQuestion()
        },
        'btn-success'
      ))
    }

    this.dialog.show({
      title: '別の問題で学習しますか？',
      body: body,
      actions: actions,
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  handleScore = async (e) => {
    await this.score()
  }

  handleHome = (e) => {
    this.showSaveDialog(() => {
      this.props.navigate('/');
    })
  }

  async setStateAsync(func) {
    return new Promise((resolve, reject) => {
      this.setState(func, () => {
        resolve()
        }
      )
    })
  }

  async score() {
    const essay = this.essayRef.current?.getInputValue()
    // console.log('essay', essay)

    // clear feedback
    let assignment = this.state.assignment
    assignment = {...assignment,
      essay: essay,
      feedback:null,
    }
    // show spinner
    this.setState(prevState => ({
      ...prevState,
      scoring: true,
    }));
    // score
    const startTime = dayjs();
    const feedback = await api.scoreAssignment(assignment)
    const endTime = dayjs();
    const timeTaken = endTime.diff(startTime);
    assignment = {...assignment,
      feedback:feedback,
    }

    let coherence_score
    if (assignment.question.type == "opinion") {
      coherence_score = feedback.coherence.sys_coherence
    }
    else if (assignment.question.type == "email") {
      // email has no coherence score
      coherence_score = feedback.content.sys_content
    }

    assignment.score = Math.floor((feedback.content.sys_content + coherence_score + feedback.grammar_vocabulary.sys_grammar + feedback.grammar_vocabulary.sys_vocabulary) / 4) + 1

    gtm.sendScoreEvent(assignment.question.grade, assignment.question.type, assignment.score, timeTaken)

    // if logged in, save feedback
    if (this.context.user != null) {
      // console.log('saving:start', assignment)
      if (!this.state.assignment_id) {
        // new assignment, create
        const assignment_id = await api.createUserAssignment(assignment)
        // this.setState({assignment_id: assignment_id})
        await this.setStateAsync(prevState => ({
          ...prevState,
          assignment_id: assignment_id
        }));
      }
      else {
        // existing assignment, update
        // console.log('saving:upate')
        await api.updateUserAssignment(this.state.assignment_id, assignment)
      }
    }
    else {
      console.log('not logged in. skip saving.')
    }
    // update essay saved status
    this.essayRef.current?.essaySaved()
    // show feedback and hide spinner
    await this.setStateAsync(prevState => ({
      ...prevState,
      assignment: assignment,
      scoring: false,
    }))
    // change key so entire view can be refreshed when feedback is updated
    // this is because contentEditable contents within feedback view cannot be updated via props or states
    this.forceUpdateFeedback()
  }

  // displayResult(result) {
    // TODO: move to score result
    // let grade = this.props.grade;
    // let grammar_complex = null;
    // if (grade == "EK-G15-OPN") {
    //   grammar_complex = result.grammar_vocabulary.grammar_complexity_score >= 4;
    // } else if (['EK-G30-OPN', 'EK-G25-OPN', 'EK-G20-OPN', 'EK-G30-EML'].includes(grade)) {
    //   grammar_complex = result.grammar_vocabulary.grammar_complexity_score >= 1;
    // }
    //
    // let vocabulary_complex = null;
    // if (grade == "EK-G15-OPN") {
    //   vocabulary_complex = result.grammar_vocabulary.vocabulary_complexity_score >= 0.37;
    // } else if (['EK-G30-OPN', 'EK-G25-OPN', 'EK-G20-OPN', 'EK-G30-EML'].includes(grade)) {
    //   vocabulary_complex = result.grammar_vocabulary.vocabulary_complexity_score >= 0.15;
    // }

  // }

  handleFeedbackModeChange = async(mode) => {
    if (isMobile()) {
      this.setState({...this.state,
      mode: mode})
    }
  }

  onEditorFocused = (focused) => {
    // console.log('onEditorFocused')
    this.setState({...this.state,
    editorFocused: focused});
  }

  render() {
    if (this.state.loading) {
      return (
        <Container className="p-5 text-center">
          <MDBSpinner className='me-2' style={{ width: '3rem', height: '3rem' }} color='success'>
            <span className='visually-hidden'>ロードしています...</span>
          </MDBSpinner>
        </Container>
      )
    }
    if (!this.state.assignment) {
      return null
    }

    return (
      <Container>
        <div className="mt-3 d-flex align-items-center justify-content-between">
          <MDBBreadcrumb className="pt-3">
            <MDBBreadcrumbItem>
              <MDBBtn color='secondary' outline rounded onClick={this.handleHome}>
                ホーム
              </MDBBtn>
            </MDBBreadcrumbItem>
            <MDBBreadcrumbItem active className="mt-1">
              <span className="fw-bold">
                {this.state.assignment && this.state.assignment.title}
                { this.state.isSample && ('（サンプル）') }
              </span>
            </MDBBreadcrumbItem>
          </MDBBreadcrumb>
        </div>
        <Row className="py-3 sticky-top assignment-title-pane">
          <Stack direction='horizontal' gap={1}>
            {this.state.assignment &&
              <div>
                <EikenLogo grade={this.state.assignment.question.grade} style={{width:'150'}}/><span className='fw-bold ms-5 h3'>{this.getTypeText(this.state.assignment.question.type)}</span>
              </div>
              }
          </Stack>
        </Row>

        <Row>
          <Col>
            <Row className="mb-3">
              <AssignmentView assignment={this.state.assignment}/>
            </Row>
            <Row className="mt-3">
              <EssayView key={this.state.essaySeed} ref={this.essayRef} assignment={this.state.assignment} keyboardOff={this.state.mode == 'feedback'} onEditorFocused={this.onEditorFocused}/>
            </Row>
            <Row className="mt-3">
              <Stack direction="horizontal">
                <MDBContainer className="mt-auto">
                  { this.state.isSample && this.state.assignment.feedback != null &&
                    <MDBRow center className="text-center green-text lh-sm m-1">
                      <MDBCol>
                        <div>サンプル問題は<br/>いかがでしたか</div>
                      </MDBCol>
                    </MDBRow>
                  }
                  <MDBRow className="text-center">
                    <MDBCol>
                      { this.state.isSample && this.state.assignment.feedback == null &&
                        <MDBBtn size="lg" className="green-button outline lh-sm" onClick={this.handleShowSample}>
                          <div>解答例を見る<br/>
                          （添削付き）</div>
                        </MDBBtn>
                      }
                      { (!this.state.isSample || (this.state.isSample && this.state.assignment.feedback != null)) &&
                        <MDBBtn size="lg" className="orange-button lh-sm strong" style={{width:150, height:60}} onClick={this.handleNewAssignment}>
                          <div>違う問題にも<br/>
                          チャレンジ！</div>
                        </MDBBtn>
                      }
                      </MDBCol>
                    </MDBRow>
                  </MDBContainer>
                <MDBContainer className="ms-auto mt-auto">
                  { this.state.assignment.feedback != null &&
                    <MDBRow center className="text-center green-text lh-sm m-1">
                      <MDBCol>
                        <div>解答欄を修正し<br/>
                        再度採点しよう
                        </div>
                      </MDBCol>
                    </MDBRow>
                  }
                  <MDBRow className="text-center">
                    <MDBCol>
                    <OverlayTrigger placement="top" overlay={
                      <Tooltip>書いた文章をAI評価（保存）する</Tooltip>
                      }>
                      <MDBBtn size="lg" className="green-button"  style={{width:150, height:60}} onClick={this.handleScore}>
                      AI採点
                      {this.state.scoring &&
                        <MDBSpinner role='status' className="ms-1" style={{ width: '1rem', height: '1rem' }} />
                      }
                      </MDBBtn>
                    </OverlayTrigger>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </Stack>
            </Row>
          </Col>
          {this.state.assignment.feedback &&
            <Col className="d-none d-md-block">
              <VerticalFeedback
                key={this.state.feedbackSeed}
                feedback={this.state.assignment.feedback}
                max_score={this.state.assignment.question.get_max_score()}
                type={this.state.assignment.question.type}
                grade={this.state.assignment.question.grade}
              />
            </Col>
          }
        </Row>
        {this.state.assignment.feedback &&
          <Row className="d-block d-md-none">
            <HorizontalFeedback key={this.state.feedbackSeed} feedback={this.state.assignment.feedback} max_score={this.state.assignment.question.get_max_score()} type={this.state.assignment.question.type}
            grade={this.state.assignment.question.grade}
            onModeChange={this.handleFeedbackModeChange}
            keyboardFocused={this.state.editorFocused}/>
          </Row>
        }
        <Dialog ref={(el) => { this.dialog = el }} />
        <div className='spacer'>
          <EikenTrademarkNote/>
        </div>
      </Container>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: 'var(--light-green)'}}>
        <Helmet>
          <title>UGUIS.AI - 英語ライティング練習サービス - 学習{this.props.isSample ? '（サンプル）' : ''}</title>
        </Helmet>
        <AssignmentContent
          grade={this.props.grade}
          type={this.props.type}
          assignment_id={this.props.assignment_id}
          topic={this.props.topic}
          isSample={this.props.isSample}
          navigate={this.props.navigate}
        />
      </div>
    );
  }
}


export default withRouter(App);
