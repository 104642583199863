import React, { Component } from "react";
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import { MDBIcon, MDBBtn, MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import UguisTextEditor from "./UguisTextEditor"
import { Statement } from './SampleData';

export default class EssayView extends React.Component {
  constructor(props) {
    super(props);
    this.viewRef = React.createRef()
  }

  getInputValue() {
    return this.viewRef.current?.getInputValue()
  }

  essaySaved() {
    return this.viewRef.current?.essaySaved()
  }

  isEssaySaved() {
    return this.viewRef.current?.isEssaySaved()
  }

  render() {
    if (this.props.assignment == null) {
      return null;
    }
    if (this.props.assignment.question.type == 'opinion') {
      return (
        <OpinionEssayView
          ref={this.viewRef}
          assignment={this.props.assignment}
          keyboardOff={this.props.keyboardOff}
          onEditorFocused={this.props.onEditorFocused}/>
      )
    }
    else if (this.props.assignment.question.type == 'email') {
      return (
        <EMailEssayView
          ref={this.viewRef}
          assignment={this.props.assignment}
          keyboardOff={this.props.keyboardOff}
          onEditorFocused={this.props.onEditorFocused}/>
        )
    }
  }
}

class AbstractEssayView extends React.Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef()

    this.state = {
      essayChanged: false,
      assignment: props.assignment,
      seed: Math.random()
    }
  }

  handleEssayChange = (value) => {
    // console.log('handleEssayChange')
    this.setState(prevState => ({
      ...prevState,
      essayChanged: true
    }))
  }

  handleClearEssay = async(e) => {
    // console.log('handleClearEssay', this.state)
    this.setState(prevState => ({
      ...prevState,
      assignment: {
        ...prevState.assignment,
        essay: null,
        feedback: null,
      },
      seed: Math.random()
    }))
  }

  essaySaved() {
    this.setState(prevState => ({
      ...prevState,
      essayChanged: false
    }))
  }

  isEssaySaved() {
    return !this.state.essayChanged
  }

  getInputValue() {
    // console.log('get input value')
    return this.editorRef.current?.getInputValue()
  }

  getEssayNotSavedWarning() {
    if (!this.state.essayChanged) {
      return null
    }
    return (
        <Alert key='warning' variant='warning'>
            書きかけの文章は保存されていません。AI採点して文章を保存しましょう。
        </Alert>
      )
  }

}


class OpinionEssayView extends AbstractEssayView {
  render() {
    return (
      <Card>
        <Card.Header className="fw-bold px-md-4 px-1 py-md-2 py-1">
          <div className="d-flex align-items-center justify-content-between">
            <span>
              <MDBIcon fas icon="pen me-2 align-middle" />
              <span className="align-middle fw-bold">あなたの解答</span>
            </span>
            <MDBBtn size="sm" fluid className={`gradient-bubble m-2 outline`} rounded onClick={this.handleClearEssay}>解答をクリア</MDBBtn>
          </div>
        </Card.Header>
        <Card.Body className='p-md-4 p-1'>
          <div className="px-3 py-2 border border-dark border-2">
          <UguisTextEditor
            key={this.state.seed}
            ref={this.editorRef}
            feedback={this.state.assignment.feedback}
            essay={this.state.assignment.essay}
            question_data={this.state.assignment.question}
            placeholder='解答を英文で入力しましょう'
            onEssayChange={this.handleEssayChange}
            keyboardOff={this.props.keyboardOff}
            onFocused={this.props.onEditorFocused}
          />
          </div>
          {this.getEssayNotSavedWarning()}
        </Card.Body>
      </Card>
    )
  }
}

class EMailEssayView extends AbstractEssayView {
  render() {
    let statement_data = Statement[this.state.assignment.question.grade]['email']

    return (
      <Card>
        <Card.Header className="fw-bold px-md-4 px-1 py-md-2 py-1">
          <div className="d-flex align-items-center justify-content-between">
            <span>
              <MDBIcon far icon="envelope me-2 align-middle"/>
              <span className="align-middle">Re: {this.state.assignment.title}</span>
            </span>
            <MDBBtn size="sm" fluid className={`gradient-bubble m-2 outline`} rounded onClick={this.handleClearEssay}>解答をクリア</MDBBtn>
          </div>
        </Card.Header>
        <Card.Body className='p-md-4 p-1'>
          <Card.Title>
            <img src="profile.png" height="30" alt="avatar" loading="lazy" className='rounded-circle me-2'/>
            <span className="h6">me</span>
          </Card.Title>
          <div className="email email-reply">
            <div>{this.state.assignment.question.greeting}</div>
            <div>{statement_data['content_start']}</div>
          </div>
          <Row>
            <div className="px-3 py-1 me-4 border border-dark border-2">
              <UguisTextEditor
                key={this.state.seed}
                ref={this.editorRef}
                feedback={this.state.assignment.feedback}
                essay={this.state.assignment.essay}
                question_data={this.state.assignment.question}
                placeholder='解答を英文で入力しましょう'
                onEssayChange={this.handleEssayChange}
                keyboardOff={this.props.keyboardOff}
                onFocused={this.props.onEditorFocused}
              />
            </div>
          </Row>
          <div className="email email-reply">{this.state.assignment.question.closing}</div>
          {this.getEssayNotSavedWarning()}
        </Card.Body>
      </Card>
    )
  }
}
