import { Statement } from './SampleData';
import { Container, Row, Card } from 'react-bootstrap';
import { useState } from "react";
import { MDBIcon } from 'mdb-react-ui-kit';
import parse from 'html-react-parser';

function OpinionAssignment(props) {
  const assignment = props.assignment;
  let points = [];
  if (assignment.question.keywords != null){
    points = assignment.question.keywords.map(
      (point, index) => {
        if (point == "") {
          return null;
        }
        else {
          return <li>{point}</li>
        }
      }
    ).filter(x => x != null);
  }
  let points_list = null
  if (points.length != 0) {
    points_list = <div className="mt-3">
      <div>POINTS</div>
      <ul>{points}</ul>
    </div>
  }
  const statementData = Statement[assignment.question.grade][assignment.question.type]
  let instruction_list = statementData['statement'].map((statement, i) => {
    return <li key={i}>{parse(statement)}</li>
  })
  return (
    <Container>
      <Row>
        <Card>
          <Card.Header className="fw-bold px-md-4 px-1">
            <MDBIcon fas icon="question-circle me-2 align-middle"/>
            <span className="align-middle fw-bold">設問</span>
          </Card.Header>
          <Card.Body className="p-md-4 px-1 py-3 lh-lg">
            <div>
              <div>
                <ul>
                  {instruction_list}
                </ul>
              </div>
              <div>{statementData['subject_title']}</div>
              <div>{assignment.question.question}</div>
              {points_list}
            </div>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  )
}

export default OpinionAssignment;
