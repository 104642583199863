import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Stack, Alert } from 'react-bootstrap';
import { MDBRipple, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBTabs, MDBTabsItem, MDBTabsLink } from 'mdb-react-ui-kit';
import {getContentCoherenceFeedbacks, ContentCoherenceFeedbackCard, ContentCoherenceFeedback} from './ContentCoherenceFeedback';
import {GrammarVocabularyFeedbackCard, VerticalGrammarVocabularyFeedback} from './GrammarVocabularyFeedback';
import React, { Component } from "react";
import SummaryScore from './SummaryScore';
import HeaderedScore from './HeaderedScore';
import eventBus from "./EventBus";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

class Scores {
  constructor(type, feedback) {
    this.type = type;
    this.feedback = feedback;
  }
  get grammarScore() {
    return this.feedback.grammar_vocabulary.sys_grammar + 1
  }

  get vocabularyScore() {
    return this.feedback.grammar_vocabulary.sys_vocabulary + 1
  }

  get grammarVocabularyScore() {
    return Math.floor((this.grammarScore + this.vocabularyScore) / 2)
  }

  get contentScore() {
    return this.feedback.content.sys_content + 1;
  }

  get coherenceScore() {
    switch (this.type) {
      case "opinion":
        return this.feedback.coherence.sys_coherence + 1;
      case "email":
        return this.feedback.content.sys_content + 1;
      default:
        throw new Error(`unsupported ${this.type}`);
    }
  }

  get contentCoherenceScore() {
    return Math.floor((this.coherenceScore + this.contentScore) / 2)
  }

  get maxScore() {
    return this.type === "opinion" ? 5 : 4;
  }

  get isGrammarFullScore() {
    return this.grammarScore == this.maxScore;
  }

  get isVocabularyFullScore() {
    return this.vocabularyScore == this.maxScore;
  }

  get isContentFullScore() {
    return this.contentScore == this.maxScore;
  }

  get isGrammarVocabularyFullScore() {
    return this.grammarVocabularyScore == this.maxScore
  }

  get isContentCoherenceFullScore() {
    return this.contentCoherenceScore == this.maxScore
  }
}

class Feedback extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'grammar_vocabulary'
    }

    this.scores = new Scores(props.type, props.feedback);
  }

  handleTabClick = (value: string) => {
    if (value === this.state.activeTab) {
      return;
    }
    this.setState(prevState => ({
      ...prevState,
      activeTab: value
    }))
  };

  selectEditorCallback = (data) => {
    if (data.type == 'tag') {
      this.setState(prevState => ({
        ...prevState,
        activeTab: 'content_coherence'
      }))
    } else if (data.type == 'sentence') {
      this.setState(prevState => ({
        ...prevState,
        activeTab: 'grammar_vocabulary'
      }))
    }
  }

  componentDidMount() {
    eventBus.on("selectEditor", this.selectEditorCallback);
  }

  componentWillUnmount() {
    eventBus.remove("selectEditor", this.selectEditorCallback);
  }
}

class FeedbackTab extends Component {
  render() {
    function responsiveText(text) {
      return (
        <>
          <div className="d-none d-md-block h6">{text}</div>
          <div className="d-block d-md-none">{text}</div>
        </>
      )
    }
    return (
      <MDBTabs pills fill justify className='m-0'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => {if (this.props.onTabChanged) this.props.onTabChanged('grammar_vocabulary')}} active={this.props.activeTab === 'grammar_vocabulary'} className="py-1 py-md-3 ms-md-0">
            <Container className="text-center">
              <Row>
                {responsiveText("語彙・文法")}
              </Row>
              <Row>
                <HeaderedScore score={this.props.scores.grammarVocabularyScore} max_score={this.props.scores.maxScore} size={this.props.size}/>
              </Row>
            </Container>
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => {if (this.props.onTabChanged) this.props.onTabChanged('content_coherence')}} active={this.props.activeTab === 'content_coherence'} className="py-1 py-md-3 me-md-0">
            <Container className="text-center">
              <Row>
                { this.props.type === 'opinion' &&
                  responsiveText("内容・構成")
                }
                { this.props.type === 'email' &&
                  responsiveText("内容")
                }
              </Row>
              <Row>
                <HeaderedScore score={this.props.scores.contentCoherenceScore} max_score={this.props.scores.maxScore} size={this.props.size}/>
              </Row>
            </Container>
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
    )
  }
}

class GrammarVocabularySummary extends Component {
  render() {
    return (
      <>
        <div>あなたの解答の語彙は<MDBIcon fas icon="star" style={{color: "orange"}}/>{this.props.scores.vocabularyScore}レベル、文法は<MDBIcon fas icon="star" style={{color: "orange"}}/>{this.props.scores.grammarScore}レベルです。</div>
        { !this.props.scores.isGrammarVocabularyFullScore &&
          <div>いくつか語彙や文法に誤りがありました。指摘された箇所を確認して改善に繋げましょう。</div>
        }
        { this.props.scores.isGrammarVocabularyFullScore &&
          <div>この級では問題なくクリアできています！</div>
        }
      </>
    )
  }
}

class ContentCoherenceSummary extends Component {
  render() {
    return (
      <>
        <div>
          あなたの解答の内容は<MDBIcon fas icon="star" style={{color: "orange"}}/>{this.props.scores.contentScore}レベル
          {this.props.type === 'opinion' &&
          <span>、構成は<MDBIcon fas icon="star" style={{color: "orange"}}/>{this.props.scores.coherenceScore}レベル</span>
          }
          です。
        </div>
        { !this.props.scores.isContentCoherenceFullScore &&
          <div>この問題では２つの理由を明確に書く必要があります。改善ポイントで指摘された箇所をよく確認して見直しましょう</div>
        }
        { this.props.scores.isContentCoherenceFullScore &&
          <div>この級では問題なくクリアできています！</div>
        }
      </>
    )
  }
}

class VerticalFeedback extends Feedback {
  render() {
    return (
      <Container className="feedback-sticky-container">
        <Row>
          <Container className="mb-3">
            <SummaryScore feedback={this.props.feedback} type={this.props.type}/>
          </Container>
        </Row>
        <Row className='feedback-pane'>
          <Container className="mb-3">
            <Card className='feedback-card-pane'>
              <Card.Header>
                <MDBIcon fas icon="info-circle me-2 align-middle" />
                <span className="align-middle fw-bold">観点評価と改善のポイント</span>
              </Card.Header>
              <Card.Body>
                <FeedbackTab type={this.props.type} activeTab={this.state.activeTab} onTabChanged={this.handleTabClick} scores={this.scores}/>
                <Row className="mt-3">
                  <Container className="my-3">
                    {this.state.activeTab === 'grammar_vocabulary' &&
                      <>
                        <span className='me-3'>
                          <HeaderedScore
                            header="語彙："
                            score={this.scores.vocabularyScore}
                            max_score={this.scores.maxScore}
                          />
                        </span>
                        <span>
                          <HeaderedScore
                            header="文法："
                            score={this.scores.grammarScore}
                            max_score={this.scores.maxScore}
                          />
                        </span>
                        <Row className="mt-3">
                          <GrammarVocabularySummary scores={this.scores}/>
                        </Row>
                      </>
                    }
                    {this.state.activeTab === 'content_coherence' &&
                      <>
                        <span className='me-3'>
                        <HeaderedScore
                          header="内容："
                          score={this.scores.contentScore}
                          max_score={this.scores.maxScore}
                        />
                        </span>
                        {this.props.type === 'opinion' &&
                          <span>
                            <HeaderedScore
                              header="構成："
                              score={this.scores.coherenceScore}
                              max_score={this.scores.maxScore}
                            />
                            </span>
                        }
                        <Row className="mt-3">
                          <ContentCoherenceSummary scores={this.scores} type={this.props.type}/>
                        </Row>
                      </>
                    }
                  </Container>
                </Row>
                <Row>
                  {this.state.activeTab === 'content_coherence' &&
                    <ContentCoherenceFeedback feedback={this.props.feedback} type={this.props.type} grade={this.props.grade}/>
                  }
                  {this.state.activeTab === 'grammar_vocabulary' &&
                    <VerticalGrammarVocabularyFeedback feedback={this.props.feedback} type={this.props.type}/>
                  }
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Row>
      </Container>
    );
  }
}

function sentenceHasError(sentence) {
  return sentence.grammar_err.length > 0 || sentence.vocabulary_err.length > 0;
}

function sentencesHaveError(sentences) {
  return sentences != null && sentences.some(sentenceHasError);
}

class HorizontalFeedback extends Feedback {
  constructor(props) {
    super(props);
    this.modes = [
      "keyboard",
      "feedback"
    ]
    this.state = {...this.state,
      mode: this.modes.indexOf("feedback")
    }
  }

  handleModeChange = async(e) => {
    this.setState({...this.state,
      mode: ((this.state.mode + 1) % this.modes.length)},
      () => {
        if (this.props.onModeChange) {
          this.props.onModeChange(this.modes[this.state.mode]);
        }
      });
  }

  componentDidMount() {
    if (this.props.onModeChange) {
      this.props.onModeChange(this.modes[this.state.mode]);
    }
  }

  get modeIcon() {
    return this.modes[this.state.mode] == "keyboard" ? "chevron-up" : "keyboard"
  }

  get modeLabel() {
    return this.modes[this.state.mode] == "keyboard" ? "評価を見る" : "キーボード表示"
  }

  selectCard(swiper, card) {
    if (card != null && swiper.slides) {
      let index = swiper.slides.findIndex(slide => slide.getAttribute("id") == card.id);
      if (index >= 0) {
        swiper.slideTo(index, 500, false);
      }
    }
  }
  selectEditor(swiper, type) {
    let id = swiper.slides[swiper.activeIndex]?.getAttribute("id");
    if (id != null){
      eventBus.dispatch("selectCard", { type: type, id: id });
    }
  }

  selectCardCallbacks = {}

  onSwiperAfterInit(swiper, type) {
    this.selectEditor(swiper, type);
    const selectCardCallback = (data) =>
      {
        this.selectCard(swiper, data);
      }
    this.selectCardCallbacks[type] = selectCardCallback
    eventBus.on("selectEditor", selectCardCallback);
  }
  onSwiperBeforeDestroy(swiper, type) {
    eventBus.remove("selectEditor", this.selectCardCallbacks[type]);
  }
  onSwiperSlideChange(swiper, type) {
    this.selectEditor(swiper, type);
  }


  render() {
    let contentCoherenceFeedbacks = getContentCoherenceFeedbacks(this.props.grade, this.props.type, this.props.feedback)
    return (
      <Container className="fixed-bottom" style={{backgroundColor: "white", zIndex:999}}>
        <div className="d-flex align-items-center justify-content-between p-2">
          <MDBBtn size="sm" className="green-button" onClick={this.handleModeChange}>
            <MDBIcon fas icon={ this.modeIcon } className={ this.modeIcon == 'keyboard' && this.props.keyboardFocused ? 'blink':'' } /> <span>{ this.modeLabel  }</span>
          </MDBBtn>
          <SummaryScore fluid feedback={this.props.feedback} type={this.props.type} size='sm'/>
        </div>
        {this.modes[this.state.mode] == "feedback" && (
          <>
            <FeedbackTab type={this.props.type} activeTab={this.state.activeTab} onTabChanged={this.handleTabClick} scores={this.scores} size='xs'/>
            {this.state.activeTab === 'grammar_vocabulary' && (
              <>
              { sentencesHaveError(this.props.feedback.grammar_vocabulary.sentences) && (
              <Swiper
                autoHeight={true}
                spaceBetween={50}
                slidesPerView={1}
                onAfterInit={(swiper) => {this.onSwiperAfterInit(swiper, 'sentence')}}
                onBeforeDestroy={(swiper) => {this.onSwiperBeforeDestroy(swiper, 'sentence')}}
                onSlideChange={(swiper) => {this.onSwiperSlideChange(swiper, 'sentence')}}
                pagination={true}
                modules={[Pagination]}>
                {
                  this.props.feedback.grammar_vocabulary.sentences.map((sentence, i) => {
                    if (sentenceHasError(sentence)) {
                      return (
                        <SwiperSlide id={i}>
                          <div className="pb-3">
                            <GrammarVocabularyFeedbackCard
                              id={i}
                              sentence={sentence}
                              selected={true}/>
                          </div>
                        </SwiperSlide>
                      )
                    }
                  })
                }
              </Swiper>
              )}
              { !sentencesHaveError(this.props.feedback.grammar_vocabulary.sentences) && (
                <Container className="feedback-card small p-2">
                  <GrammarVocabularySummary scores={this.scores}/>
                </Container>
              )}
              </>
            )}
            {this.state.activeTab === 'content_coherence' && (
              <>
              { contentCoherenceFeedbacks.length > 0 && (
                <>
                <Swiper
                  autoHeight={true}
                  spaceBetween={50}
                  slidesPerView={1}
                  onAfterInit={(swiper) => {this.onSwiperAfterInit(swiper, 'tag')}}
                  onBeforeDestroy={(swiper) => {this.onSwiperBeforeDestroy(swiper, 'tag')}}
                  onSlideChange={(swiper) => {this.onSwiperSlideChange(swiper, 'tag')}}
                  pagination={true}
                  modules={[Pagination]}>
                  {
                    contentCoherenceFeedbacks.map(feedback => {
                      return (
                        <SwiperSlide id={feedback.tag}>
                          <div className="pb-3">
                          <ContentCoherenceFeedbackCard
                            feedback ={feedback}
                            selected = {true}/>
                            </div>
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
                </>
              )}
              {contentCoherenceFeedbacks.length == 0 && (
                <Container className="feedback-card small p-2">
                  <ContentCoherenceSummary scores={this.scores} type={this.props.type}/>
                </Container>
              )}
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}

export { VerticalFeedback, HorizontalFeedback };
