import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBSpinner
}
  from 'mdb-react-ui-kit';

import { useState, useContext } from "react"
import { AuthContext } from "./AuthContext"
import { Navigate, Link } from "react-router-dom";
import * as auth from "./auth"
import GoogleSignInButton from './GoogleSignInButton';

import Alert from 'react-bootstrap/Alert';
import * as device from '../device';

function LoginPane() {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [loggingIn, setLoggingIn] = useState(false)

  const { user, signIn } = useContext(AuthContext)


  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")

    try {
      setLoggingIn(true)
      await signIn(username, password)
      setLoggingIn(false)
      // Redirect to the app's main page or dashboard
    } catch (err) {
      setLoggingIn(false)
      if (err.name == 'NotAuthorizedException') {
        setError('ユーザ名とパスワードは間違っています。')
      }
      else if (err.name == 'LimitExceededException') {
        setError(`試行回数の上限に達しました。しばらくしてから再度お試しください。`)
      }
      else {
        setError(`${err.name}: ${err.message}`)
      }
    }
  }

  const handleGoogleLogin = async (e) => {
    e.preventDefault()
    setError("")

    try {
      setLoggingIn(true)
      await auth.signInWithGoogle()
      setLoggingIn(false)
    } catch (err) {
      setLoggingIn(false)
      setError(`${err.name}: ${err.message}`)
    }
  }

  // If the user is logged in, don't show the login form
  if (user) {
    // Redirect to the profile page
    return <Navigate to="/" />
  }

  return (
    <MDBContainer>
      <section className="text-center">
        <a href="/">
          <img src="/color-logo-nobackground.svg"
            style={{ width: '75vw', maxWidth: '400px' }} alt="logo" />
        </a>
        <h5 className="mt-1 mb-5">
          <div>合格への近道は練習にあり！</div>
          <div>英語ライティング学習サービス</div>
        </h5>
      </section>
      <section className="mt-5">
        <h5 className="text-center mb-3">メールアドレスでログイン</h5>
        <MDBRow center className="text-center mb-3">
          <MDBCol className="col-md-8">
            <MDBInput className="mb-2" size='lg' label='メールアドレス' type='email' value={username} onChange={(e) => setUsername(e.target.value)} />
            <MDBInput className="mb-1" size='lg' label='パスワード' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
            <a className="text-muted" href="/forgot-password">パスワードをお忘れの方</a>
          </MDBCol>
        </MDBRow>
        <MDBRow center className="text-center">
          <MDBCol>
            <MDBBtn size="lg" className="mb-4 green-button" style={{ width: "200px" }} onClick={handleSubmit}>
              ログイン
              {loggingIn &&
                <MDBSpinner role='status' className="ms-1" style={{ width: '1rem', height: '1rem' }} />
              }
            </MDBBtn>
            <div>
              {error &&
                <Alert variant="danger">
                  <Alert.Heading>ログイン失敗しました</Alert.Heading>
                  <div>{error}</div>
                </Alert>
              }
            </div>
          </MDBCol>
        </MDBRow>
      </section>
      {!device.isIOS() && (
        <section className="text-center mt-5">
          <h5 className="mb-3">ソーシャルアカウントでログイン</h5>
          <p className="text-xs text-muted mb-2">
            サービスの利用にあたっては、<a href="/term-of-service" target="_blank">利用規約</a>と<a href="/privacy-policy" target="_blank">プライバシーポリシー</a>が適用されます。同意の上でログインしてください。
          </p>
          <GoogleSignInButton
            onClick={handleGoogleLogin}
            loggingIn={loggingIn}
          />
        </section>
      )}
      <section className="text-center mt-5">
        <div className="mb-1">アカウントをお持ちでない方</div>
        <MDBBtn className='ms-3 green-button outline' href="/signUp">ユーザ登録【無料】</MDBBtn>
      </section>
    </MDBContainer>
  );
}

export default function Login(props) {
  return (
    <MDBContainer className="py-5">
      {LoginPane()}
    </MDBContainer>
  );
}
