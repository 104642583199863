var SubtaskData = {
  'G25': {
    'email': {
      'config': {
        "s0": { "text": "質問への解答", "color": "success" },
        "s1": { "text": "質問への解答の理由", "color": "success" },
        "s2": { "text": "１つ目の追加の質問", "color": "success" },
        "s3": { "text": "２つ目の追加の質問", "color": "success" },
        "missing_s0": {"text":"質問への解答", "color":"danger"},
        "missing_s1": {"text":"質問への解答の理由", "color":"danger"},  
        "missing_s2": {"text":"１つ目の追加の質問", "color":"danger"},
        "missing_s3": {"text":"２つ目の追加の質問", "color":"danger"},
      },
      'placeholder': {
        'missing_s0': '質問への解答がありません',
        'missing_s1': '質問への解答の理由がありません',
        'missing_s2': '１つ目の追加の質問がありません',
        'missing_s3': '２つ目の追加の質問がありません',
      },
      'feedback_templates': {
        'content': [
          ['s0', '質問に解答できていません。', '質問への解答が不十分です。'],
          ['s1', '質問への解答の理由を説明できていません', '質問への解答の理由が不十分です。'],
          ['s2', '1つ目の追加の質問をできていません。', '1つ目の追加の質問が不十分です。'],
          ['s3', '2つ目の追加の質問をできていません。', '2つ目の追加の質問が不十分です。'],
        ]
      }
    }
  },
  'G30': {
    'email': {
      'config': {
        "s0": { "text": "１つ目の質問への解答", "color": "success" },
        "s1": { "text": "２つ目の質問への解答", "color": "success" },
        "missing_s0": {"text":"１つ目の質問への解答", "color":"danger"},
        "missing_s1": {"text":"２つ目の質問への解答", "color":"danger"},  
      },
      'placeholder': {
        'missing_s0': '１つ目の質問に答えていません。',
        'missing_s1': '２つ目の質問に答えていません。',
      },
      'feedback_templates': {
        'content': [
          ['s0', '1つ目の質問に答えていません。', '質問の解答が不十分です。'],
          ['s1', '2つ目の質問に答えていません。', '質問の解答が不十分です。']
        ]
      }
    }
  }
}

export {
  SubtaskData
}