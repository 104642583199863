import React, { useState, useContext, useEffect } from 'react';

import {
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBRipple,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdb-react-ui-kit';

import { MenuDialog, MenuContent } from './Menu'
import DetailedMenu from './DetailedMenu'
import { useNavigate, Link, Navigate } from "react-router-dom";
import { AuthContext } from "./Auth/AuthContext"
import { Card, CardGroup, Fade } from 'react-bootstrap';
import Dialog from 'react-bootstrap-dialog'
import EikenTrademarkNote from './EikenTrademarkNote';

import EikenLogo from "./EikenLogo"
import * as api from './api'

import Star from './english/Star';

import Login from "./Auth/Login"

Date.prototype.getDateTimeString = function() {
  if (this.isToday()) {
    return  `今日 ${this.toLocaleString("ja-JP", { hour: 'numeric', minute: '2-digit' })}`
  }
  else if (this.isYesterday()) {
    return  `昨日 ${this.toLocaleString("ja-JP", { hour: 'numeric', minute: '2-digit' })}`
  }
  else {
    return this.toLocaleString("ja-JP", { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit' });
  }
}
Date.prototype.isSameDate = function(d) {
  return new Date(this).setHours(0,0,0,0) == new Date(d).setHours(0,0,0,0);
}

Date.prototype.isToday = function() {
  const today = new Date();
  return this.isSameDate(today);
}

Date.prototype.previousDay = function() {
  return new Date(this.setDate(this.getDate() - 1));
}

Date.prototype.isYesterday = function() {
  var yesterday = new Date().previousDay();
  return this.isSameDate(yesterday);
}

export default function Top() {
  const navigate = useNavigate();
  const [ assignments, setAssignments ] = useState(null)
  const { user, isLoading, setShowNavBar } = useContext(AuthContext)
  const [menuModalShow, setMenuModalShow] = React.useState(false);
  const [grade, setGrade] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [detailedMenuModalShow, setDetailedMenuModalShow] = React.useState(false);
  var dialog;

  const getUserAssignments = async () => {
    if (user) {
      const assignments = await api.getUserAssignments()
      // console.log('assignments', assignments)
      setAssignments(assignments)
    }
  }

  useEffect(() => {
    getUserAssignments()
  }, [user])

  useEffect(() => {
    document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
  }, []);

  const handleSelectMenu = async (grade, type) => {

    setMenuModalShow(false)
    setGrade(grade)
    setType(type)
    if (navigator.userAgent == 'uguis.ios' && user == null) {
      navigate(`/english?grade=${grade}&type=${type}&isSample=true`);
    }
    else {
      setDetailedMenuModalShow(true)
    }
  }

  const handleSelectDetailedMenu = async (selection) => {
    setDetailedMenuModalShow(false)
    switch (selection) {
      case "sample":
        navigate(`/english?grade=${grade}&type=${type}&isSample=true`);
        break;
      case "topic":
        // use topic menu
        // setTopicMenuModalShow(true)

        // do not use topic menu
        navigate(`/english?grade=${grade}&type=${type}`);
        break;
      case "custom":
        break;
    }
  }

  function EmptyPlaceHolder() {
    return (
      <MDBContainer className="jumbotron d-flex align-items-center empty-placeholder">
        <MDBContainer>
          <MDBRow className="text-center">
            <MDBCol>
              <img src="/uguis-bird-gray.svg"
                      style={{width: '150px'}} />
            </MDBCol>
          </MDBRow>
          <MDBRow className="text-center mt-5">
            <MDBCol>
              <h5 style={{color: "#AAAAAA"}}>保存されている問題はありません。</h5>
            </MDBCol>
          </MDBRow>
          <MDBRow className="text-center mt-1">
            <MDBCol>
            <MDBBtn className="orange-button" onClick={() => setMenuModalShow(true)}>
                <div className="mt-1 h6 fw-bold"><MDBIcon fas icon='add' className="me-1"/>新しい問題を学習する</div>
            </MDBBtn>
            </MDBCol>
          </MDBRow>
          </MDBContainer>
      </MDBContainer>
    )
  }

  function headline0() {
    return (
      <MDBRow className="text-center">
        <div className="h6 text-center fw-bold">
          誰でも簡単に繰り返し使える
        </div>
        <div className="lh-sm strong title text-center">
          <div>英語ライティング<br className="d-block d-md-none"/>学習サービス</div>
        </div>
        <div>
          <img src="/color-logo-nobackground.svg"
                  style={{width: '75vw', maxWidth: '400px'}} alt="logo" />
        </div>
      </MDBRow>
    )
  }

  function appstore() {
    return (
      <MDBRow className="justify-content-center text-center">
        <MDBCol>
          <div className="h3 text-center fw-bold">
            すきま時間に、英語ライティングスキルを高めよう
          </div>
          <div className="text-center mt-3">
            <a href="https://apps.apple.com/jp/app/%E8%8B%B1%E6%A4%9C-%E3%83%A9%E3%82%A4%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0%E5%AF%BE%E7%AD%96-uguis-ai/id6498982523" title="App Storeからダウンロード">
              <img src="/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg"
                    style={{width: '150px'}} alt="appstore" />
            </a>
          </div>
          <div className="text-center mt-3">
            <img src="/qrcode.svg"
                    style={{width: '150px', background: 'white'}} alt="qrcode" />
          </div>
        </MDBCol>
      </MDBRow>
    )
  }

  function catchcopy0() {
    return (
      <>
        <MDBRow className="text-center white-text">
          <div className="h5 fw-bold">
            2024年度英検<sup>®</sup><br className="d-block d-md-none"/>問題形式リニューアルに対応<sup>※1</sup>
          </div>
          <div className="h4 fw-bold">
            繰り返し練習して合格点を取ろう！
          </div>
        </MDBRow>
        <MDBRow className="my-3 justify-content-center lh-sm striking strong flex-nowrap">
          <div className="catchcopy me-2 me-md-3">
            <MDBRow className="mt-2">
              <div><span className="subtitle">無料</span><span className="subscript">で</span></div>
              <div className="subtitle">AI学習<sup className="subscript">※2</sup></div>
            </MDBRow>
          </div>
          <div className="catchcopy me-2 me-md-3">
            <MDBRow className="mt-2">
              <div className="subtitle">即時<span className="subscript">に</span></div>
              <div className="subscript">採点結果が</div>
              <div className="subscript">わかる</div>
            </MDBRow>
          </div>
          <div className="catchcopy">
            <MDBRow className="mt-2">
              <div className="subscript">具体的な</div>
              <div className="subtitle">添削</div>
              <div className="subscript">アドバイス</div>
            </MDBRow>
          </div>
        </MDBRow>
        <MDBRow className="mt-5">
          <div className="h6 fw-bold text-center">
            ＼合格への近道は練習にあり！／
          </div>
        </MDBRow>
        <div className='text-center mt-1 mb-3'>
          <MDBBtn size='lg' className="orange-button" style={{width:"300px"}} onClick={() => setMenuModalShow(true)}>
              <div className="lh-sm strong">
                <span className="subscript">
                  今すぐ無料で
                </span>
                <br/>
                <span className="subtitle ms-1">
                  学習スタート！
                </span>
              </div>
          </MDBBtn>
        </div>
      </>
    )
  }

  function screenshot0() {
    return (
      <>
        <MDBRow className="mt-3">
          <div className="h6 fw-bold text-center">
            ＼すきま時間にライティング力を強化／
          </div>
        </MDBRow>
        <MDBRow>
          <img src="/uguis-write.png" className='img-fluid'/>
        </MDBRow>
      </>
    )
  }

  function headline1() {
    return (
      <MDBRow center>
        <div className="h1 lh-sm strong title text-center">
          英検<sup>®</sup>ライティング<br className="d-block d-md-none"/>学習AI
        </div>
        <div className="h6 mt-1">
          AIがライティング問題を自動生成、学習改善に役立つフィードバックを即時表示します。練習と修正を繰り返すことで、確実に改善を実感しながらライティング力を向上させられます。
        </div>
      </MDBRow>
    )
  }

  function catchcopy1() {
    return (
      <MDBRow>
        <div className="lh-sm">
          <div className="h1 striking strong wide">
            01
          </div>
          <div className="h5 fw-bold green-text">
            <div>すぐに採点結果と改善ポイントがわかる！</div>
            <div>確認したら再チャレンジ！</div>
          </div>
        </div>
        <div className="h6 mt-2">
          <div>
          あなたの解答レベルが<MDBIcon fas icon="star" style={{color: "orange"}}/>の数ですぐわかります。
          </div>
          <div>
          アドバイスを読んで修正して、次は<MDBIcon fas icon="star" style={{color: "orange"}}/>レベルUPを目指しましょう！
          </div>
        </div>
      </MDBRow>
    )
  }

  function screenshot1() {
    return (
        <MDBRow>
          <img src="/feedback.png" className='img-fluid fade-img'/>
        </MDBRow>
    )
  }

  function catchcopy2() {
    return (
      <MDBRow>
        <div>
          <div className="h1 striking strong wide">
            02
          </div>
          <div className="h5 fw-bold green-text">
            <div>まるで英語の先生！</div>
            <div>あなたの英作文に改善点を具体的にアドバイス</div>
          </div>
        </div>
        <div className="h6 mt-2">
          <div>模範解答と見比べてセルチェックする…なんて手間は、もう必要ありません。</div>
          <div className="mt-2">あなたが解答した英文のどこを改善すべきか、具体的に指摘をしてもらえます。</div>
        </div>
      </MDBRow>
    )
  }

  function screenshot2() {
    return (
        <MDBRow>
          <img src="/correction.png" className='img-fluid fade-img'/>
        </MDBRow>
    )
  }

  function catchcopy3() {
    return (
      <MDBRow>
        <div>
          <div className="h1 striking strong wide">
            03
          </div>
          <div className="h5 fw-bold green-text">
            <div>英検<sup>®</sup>ライティング問題の練習ができる！</div>
            <div>新問題形式<sup>※1</sup>にも対応！</div>
          </div>
        </div>
        <div className="h6 mt-2">
          <div>英検<sup>®</sup>準1級～3級のライティング問題（意見論述問題）に対応。準2級、3級は、2024年度新問題形式（Eメール問題）の練習もしっかりできます！！
          </div>
          <div className="mt-2">AIがあなたの英検®級に合わせた問題を無限に出題<sup>※2</sup>してくれます。</div>
        </div>
      </MDBRow>
    )
  }

  function screenshot3() {
    return (
        <MDBRow>
          <img src="/menu.png" className='img-fluid fade-img'/>
        </MDBRow>
    )
  }

  function catchcopy4() {
    return (
      <MDBRow>
        <div className="lh-sm">
          <div className="h1 striking strong wide">
            04
          </div>
          <div className="h5 fw-bold green-text">
            使い方はカンタン！まずはサンプル問題で体験してみよう！
          </div>
        </div>
      </MDBRow>
    )
  }

  function screenshot4() {
    return (
      <MDBRow>
          <MDBContainer className="usage">
              <MDBRow className="m-md-3">
                <MDBCol className="fw-bold me-3" size={3}><span className="bubble">STEP 1</span></MDBCol>
                <MDBCol>
                  <div className="fw-bold">AIが出題</div>
                  <div>英検<sup>®</sup>級と問題形式を選びます</div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="m-md-3">
                <MDBCol className="fw-bold me-3" size={3}><span className="bubble">STEP 2</span></MDBCol>
                <MDBCol>
                  <div className="fw-bold">問題に解答する</div>
                  <div>英語で解答しましょう</div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="m-md-3">
                <MDBCol className="fw-bold me-3" size={3}><span className="bubble">STEP 3</span></MDBCol>
                <MDBCol>
                  <div className="fw-bold">AIが添削＆採点！</div>
                  <div>すぐに今のレベルが分かります</div>
                  <div className="striking fw-bold">具体的な添削とアドバイス付き</div>
                </MDBCol>
              </MDBRow>
          </MDBContainer>
      </MDBRow>
    )
  }

  function Landing() {
    return (
      <MDBContainer fluid>
        <MDBRow center className='green-page py-3 p-md-5 white-text'>
          <MDBCol>
            <MDBRow>
              {headline0()}
            </MDBRow>
            <MDBRow className="mt-3">
              <MDBCol md='6'>
                {catchcopy0()}
              </MDBCol>
              <MDBCol md='6'>
                {screenshot0()}
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>

        <MDBRow center className="mt-5 px-md-5">
          <MDBCol>
            <MDBRow center>
              {headline1()}
            </MDBRow>
            <MDBRow className="mt-5">
              <MDBCol md='6'>
                {catchcopy1()}
              </MDBCol>
              <MDBCol md='6'>
                {screenshot1()}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5">
              <MDBCol md='6'>
                {catchcopy2()}
              </MDBCol>
              <MDBCol md='6' className="order-md-first">
                {screenshot2()}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5">
              <MDBCol md='6'>
                {catchcopy3()}
              </MDBCol>
              <MDBCol md='6'>
                {screenshot3()}
              </MDBCol>
            </MDBRow>
            <MDBRow className="mt-5">
              <MDBCol md='6'>
                {catchcopy4()}
              </MDBCol>
              <MDBCol md='6' className="order-md-first">
                {screenshot4()}
              </MDBCol>
            </MDBRow>
            </MDBCol>
        </MDBRow>

        <MDBRow center className="mt-5 blockquote-footer pt-3 px-md-5">
          <MDBCol>
            <div>※1 2024年度リニューアル問題形式は現在準２級、３級のみのご提供、他の級も順次対応予定です</div>
            <div>※2 ユーザ登録いただくと、英検<sup>®</sup>級にあわせたライティング問題をAIがいくつでも出題してくれます</div>
          </MDBCol>
        </MDBRow>
        <MDBRow className="mt-5 py-3 p-md-5 mid-green-page justify-content-center">
          <MDBCol>
            <div className="mt-3 h5 fw-bold text-center green-text">
              ＼合格への近道は練習にあり！／
            </div>
            <div className='text-center mt-1'>
              <MDBBtn size='lg' className="orange-button lh-sm strong" style={{width:"300px"}} onClick={() => setMenuModalShow(true)}>
                  <div>
                    <span className="subscript">
                      今すぐ無料で
                    </span>
                    <br/>
                    <span className="subtitle ms-1">
                      学習スタート！
                    </span>
                  </div>
              </MDBBtn>
            </div>
            <div className="text-center mt-5">
            どの級でも、何問でも、何回でも今なら完全無料です！
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow className="justify-content-center white-page py-3 p-md-5">
          <MDBContainer className="compact-section">
            <h2 className="text-center fw-bold">AI英語添削</h2>
            <div className="mt-3" >
              ・英作文してみたけど、あっているかな？<br/>
              ・私の英文、ビジネスでも通用するかな？<br/>
              ・外国の友達との会話、この伝え方でいいかな？
            </div>
            <div className="text-center mt-3">
              そんな時はAI添削<br/>
              シーン別にあなたの英作文を添削します！
            </div>
            <MDBRow className='text-center my-3'>
              <Link to="/correction">
                <MDBBtn size='sm' className="green-button outline">
                    <span className="h5 fw-bold mx-3">AI英語添削はこちら</span>
                </MDBBtn>
              </Link>
            </MDBRow>
          </MDBContainer>
        </MDBRow>

        <MDBRow center className='green-page py-3 p-md-5 white-text'>
          <MDBCol>
            <MDBRow center>
              {appstore()}
            </MDBRow>
          </MDBCol>
        </MDBRow>

        <MDBRow center　className='mt-5 px-md-5'>
          <MDBCol>
            <EikenTrademarkNote/>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }

  function Menus() {
    return (
      <>
        <MenuDialog show={menuModalShow} onHide={() => setMenuModalShow(false)} onSelectMenu={handleSelectMenu}/>
        <DetailedMenu show={detailedMenuModalShow} grade={grade} type={type} onHide={() => setDetailedMenuModalShow(false)} onSelectMenu={handleSelectDetailedMenu}/>
      </>
    )
  }

  function NewButton() {
    return (
      <>
        <MDBContainer className="floating-button-pane fixed-bottom pt-4 pb-4 d-block d-md-none">
          <MDBRow className="text-center">
            <MDBCol>
            <MDBBtn className="orange-button" onClick={() => setMenuModalShow(true)}>
                <div className="mt-1 h6 fw-bold"><MDBIcon fas icon='add' className="me-1"/>新しい問題を学習する</div>
            </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Card className='new-tile d-none d-md-block'>
          <MDBRipple role='button' rippleColor='#6dd6c2' onClick={() => setMenuModalShow(true)}>
            <Card.Body>
              <Card.Title>
                <MDBContainer className="text-center mt-2">
                  <MDBRow center>
                    <MDBIcon far icon="add" size='3x'/>
                  </MDBRow>
                  <MDBRow className="fw-bold mt-md-3 mt-1">
                    新しい問題を学習する
                  </MDBRow>
                </MDBContainer>
              </Card.Title>
            </Card.Body>
          </MDBRipple>
        </Card>
      </>
    )
  }

  function AssignmentIcon(question_type) {
    if (question_type == 'email') {
      return (
        <MDBIcon far icon="envelope me-3"/>
      )
    }
    else {
      return (
        <MDBIcon far icon="file-alt me-3"/>
      )
    }
  }

  const handleSelectAssignment = (id) => {
    navigate(`/english?id=${id}`);
  }

  async function deleteAssignment(id) {
    const result = await api.deleteUserAssignment(id)
    if (true) {
      setAssignments(assignments.map(assignment => {
        if (assignment.id == id) {
          return { ...assignment, deleted: true };
        } else {
          // No changes
          return assignment;
        }
      }));
    }
    else {
      // TODO: error handling
    }
  }

  const handleDeleteAssignment = (id) => {
    // console.log('delete', id)
    dialog.show({
      title: '問題と解答の削除をしますか？',
      body: '削除すると書いた解答を取り戻せません。',
      actions: [
        Dialog.Action(
          'キャンセル',
          () => dialog.hide(),
          'btn-secondary'
        ),
        Dialog.DefaultAction(
          '削除',
          () => {
            deleteAssignment(id)
          },
          'btn-danger'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  function ScoreStars(props){
    let stars
    const score = props.assignment.score;
    if(score){
      const max_score = props.assignment.type === "opinion" ? 5 : 4
      stars = Array.from({length: max_score}).map((_, i) => {
        return <Star key={i} score={score > i ? 1 : 0} size='sm'/>
      })
    }
    return (
      <span class='assignment-score'>
        {stars}
      </span>
    )
  }

  function Assignments() {
    if (assignments == null || typeof assignments == 'undefined') {
      return null;
    }

    return assignments.map((assignment, i) => {
      return (
        <Fade in={assignment.deleted!=true} onExited={() => getUserAssignments()}>
          <Card className='tile'>
            <MDBRipple role='button' rippleColor='#6dd6c2' onClick={() =>  handleSelectAssignment(assignment.id)} className="h-100">
              <Card.Body className='d-flex pt-3 px-3 pb-0 flex-column h-100'>
                <Card.Title>
                  {AssignmentIcon(assignment.type)}
                  <EikenLogo grade={assignment.grade} size='sm'/>
                </Card.Title>
                <Card.Text className="h-100 d-flex flex-column">
                  <div className="fw-bold mt-md-2 mt-0 lh-sm fade-text">
                    {assignment.title}
                  </div>
                  <div className="mt-auto pt-1">
                    <div className="d-flex justify-content-end" style={{fontSize:"0.75em"}}>
                      <span>学習日: {new Date(assignment.modified_at).getDateTimeString()}</span>
                    </div>
                  </div>
                </Card.Text>
              </Card.Body>
            </MDBRipple>
            <Card.Footer className='px-3 py-1 tile-footer'>
              <MDBRow>
                <MDBCol className="d-flex justify-content-between">
                  <ScoreStars assignment={assignment} />
                  <MDBDropdown dropdown>
                    <MDBDropdownToggle className="caret-off nav-link" tag='a' role='button'>
                      <MDBIcon fas icon="ellipsis-h" role="button"/>
                    </MDBDropdownToggle>
                    <MDBDropdownMenu responsive='end' className="option">
                      <MDBDropdownItem link onClick={() =>  handleDeleteAssignment(assignment.id)}>
                        <div className="red-text fw-bold">
                          <MDBIcon far icon="trash-alt me-2" role="button"/>削除
                        </div>
                      </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBCol>
              </MDBRow>
            </Card.Footer>
          </Card>
        </Fade>
      );
    })
  }

  function Home() {
    return (
      <MDBRow className="m-1">
        {assignments != null && assignments.length > 0 &&
          (
            <>
              <MDBRow className="h5 m-1">
                あなたが学習した問題
              </MDBRow>
              {NewButton()}
            </>
          )
        }
        {Assignments()}
        {assignments != null && assignments.length == 0 && EmptyPlaceHolder()}
        <Dialog ref={(el) => { dialog = el }} />
        {assignments != null && assignments.length > 0 &&
          <div className='my-5'>
            <EikenTrademarkNote />
          </div>
        }
      </MDBRow>
    )
  }

  // if mobile app, we will not show landing page, but show login right away
  // if (navigator.userAgent == 'uguis.ios' && user == null) {
  //   setShowNavBar(false)
  // }
  // else {
  //   setShowNavBar(true)
  // }

  return (
    <div className="light-green-page">
      {navigator.userAgent == 'uguis.ios' && user == null && !isLoading &&
        <MDBContainer className="my-3">
          {headline0()}
          <div className="text-center lh-sm strong my-1">
            <span className="subscript">
              今すぐ無料で
            </span>
            <br/>
            <span className="subtitle ms-1">
              学習スタート！
            </span>
          </div>
          <MenuContent onSelectMenu={handleSelectMenu}/>
        </MDBContainer>
      }
      {navigator.userAgent != 'uguis.ios' && user == null && !isLoading && Landing()}
      {user != null && !isLoading && Home()}
      {Menus()}
    </div>
  )
}
